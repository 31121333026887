<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="fill-current"
  >
    <path d="M13.7598 11.2812L11.0176 8.53906C10.4551 8 9.6582 7.88281 9.00195 8.21094L6.51758 5.72656V4.25L3.51758 2L2.01758 3.5L4.26758 6.5H5.7207L8.20508 9.00781C7.90039 9.66406 7.99414 10.4609 8.5332 11L11.2754 13.7656C11.627 14.0938 12.166 14.0938 12.5176 13.7656L13.7598 12.5234C14.0879 12.1719 14.0879 11.6328 13.7598 11.2812ZM9.77539 7.27344C10.4551 7.27344 11.0645 7.53125 11.5332 8L12.002 8.46875C12.3535 8.30469 12.7051 8.07031 13.0098 7.76562C13.877 6.89844 14.1816 5.67969 13.9004 4.57812C13.8535 4.36719 13.5957 4.29688 13.4316 4.4375L11.6973 6.19531L10.1035 5.91406L9.8457 4.32031L11.5801 2.58594C11.7441 2.42188 11.6504 2.16406 11.4395 2.11719C10.3379 1.83594 9.11914 2.14062 8.25195 3.00781C7.57227 3.66406 7.26758 4.55469 7.26758 5.42188L9.21289 7.34375C9.40039 7.29688 9.58789 7.27344 9.77539 7.27344ZM7.33789 9.19531L6.02539 7.88281L2.43945 11.4453C1.85352 12.0312 1.85352 12.9922 2.43945 13.5781C3.02539 14.1641 3.98633 14.1641 4.57227 13.5781L7.45508 10.6719C7.29102 10.2031 7.2207 9.71094 7.33789 9.19531ZM3.51758 13.0625C3.18945 13.0625 2.95508 12.8281 2.95508 12.5C2.95508 12.1953 3.18945 11.9375 3.51758 11.9375C3.82227 11.9375 4.08008 12.1953 4.08008 12.5C4.08008 12.8281 3.82227 13.0625 3.51758 13.0625Z" />
  </svg>
</template>

<script>
export default {
}
</script>
