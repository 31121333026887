export type HelpLinkKey = 'customerSuggestHelp'
| 'accountTransferNotificationHelp'
| 'transactionCSVDefaultHelp'
| 'transactionCSVDetailHelp'
| 'transactionCSVMFCHelp'
| 'transactionCancelHelp'
| 'destinationChangeHelp'
| 'customerCSVHelp'
| 'transactionCSVTypeHelp'
| 'teamNameHelp'
| 'invoiceIssueDateHelp'
| 'permissionHelp'
| 'topPage'
| 'earlyBankTransferHelp'
| 'customerExamintionHelp'
| 'signinTwoFactorUnknownCodeHelp'
| 'invoiceModeChangesHelp'
| 'claimableAmountHelp'
| 'unguaranteeBillingHelp'
| 'transactionRegisterValidationHelp'

const assigned: {[key in HelpLinkKey]: string} = {
  customerSuggestHelp: 'https://support.mfkessai.co.jp/hc/ja/articles/900002055066',
  accountTransferNotificationHelp: 'https://support.mfkessai.co.jp/hc/ja/articles/900002320163',
  transactionCSVDefaultHelp: 'https://support.mfkessai.co.jp/hc/ja/articles/900002395223',
  transactionCSVDetailHelp: 'https://support.mfkessai.co.jp/hc/ja/articles/900002431506',
  transactionCSVMFCHelp: 'https://support.mfkessai.co.jp/hc/ja/articles/900002398843',
  transactionCancelHelp: 'https://support.mfkessai.co.jp/hc/ja/articles/900002235166',
  destinationChangeHelp: 'https://support.mfkessai.co.jp/hc/ja/articles/900002449526',
  customerCSVHelp: 'https://support.mfkessai.co.jp/hc/ja/articles/900001590463',
  transactionCSVTypeHelp: 'https://support.mfkessai.co.jp/hc/ja/articles/900002419846',
  teamNameHelp: 'https://support.mfkessai.co.jp/hc/ja/articles/900005207166',
  invoiceIssueDateHelp: 'https://support.mfkessai.co.jp/hc/ja/articles/900002297303',
  permissionHelp: 'https://support.mfkessai.co.jp/hc/ja/articles/900006459526',
  topPage: 'https://support.mfkessai.co.jp/hc/ja',
  earlyBankTransferHelp: 'https://support.mfkessai.co.jp/hc/ja/articles/900002384703',
  customerExamintionHelp: 'https://support.mfkessai.co.jp/hc/ja/articles/900002208083',
  signinTwoFactorUnknownCodeHelp: 'https://support.mfkessai.co.jp/hc/ja/articles/4414464706841#unknown_code',
  invoiceModeChangesHelp: 'https://support.mfkessai.co.jp/hc/ja/sections/13646066869657',
  claimableAmountHelp: 'https://support.mfkessai.co.jp/hc/ja/articles/13646844185369',
  unguaranteeBillingHelp: 'https://support.mfkessai.co.jp/hc/ja/articles/900002238863',
  transactionRegisterValidationHelp: 'https://support.mfkessai.co.jp/hc/ja/articles/21916709179801',
}

const unassigned: {[key in HelpLinkKey]: string} = {
  customerSuggestHelp: 'https://support.mfkessai.co.jp/hc/ja/articles/900004682466',
  accountTransferNotificationHelp: 'https://support.mfkessai.co.jp/hc/ja/articles/900004689766',
  transactionCSVDefaultHelp: 'https://support.mfkessai.co.jp/hc/ja/articles/900005632743',
  transactionCSVDetailHelp: 'https://support.mfkessai.co.jp/hc/ja/articles/900005632963',
  transactionCSVMFCHelp: 'https://support.mfkessai.co.jp/hc/ja/articles/900004715106',
  transactionCancelHelp: 'https://support.mfkessai.co.jp/hc/ja/articles/900004686646',
  destinationChangeHelp: 'https://support.mfkessai.co.jp/hc/ja/articles/900005634563',
  customerCSVHelp: 'https://support.mfkessai.co.jp/hc/ja/articles/900004682126',
  transactionCSVTypeHelp: 'https://support.mfkessai.co.jp/hc/ja/articles/900004686746',
  teamNameHelp: 'https://support.mfkessai.co.jp/hc/ja/articles/900005312706',
  invoiceIssueDateHelp: 'https://support.mfkessai.co.jp/hc/ja/articles/900005634143',
  permissionHelp: 'https://support.mfkessai.co.jp/hc/ja/articles/900006462126',
  topPage: 'https://support.mfkessai.co.jp/hc/ja',
  earlyBankTransferHelp: 'https://support.mfkessai.co.jp/hc/ja/articles/900002384703',
  customerExamintionHelp: 'https://support.mfkessai.co.jp/hc/ja/articles/900002208083',
  signinTwoFactorUnknownCodeHelp: 'https://support.mfkessai.co.jp/hc/ja/articles/4414464706841#unknown_code',
  invoiceModeChangesHelp: 'https://support.mfkessai.co.jp/hc/ja/sections/13698571198617',
  claimableAmountHelp: 'https://support.mfkessai.co.jp/hc/ja/articles/13698677178137',
  unguaranteeBillingHelp: '', // 譲渡なしでは存在しない
  transactionRegisterValidationHelp: 'https://support.mfkessai.co.jp/hc/ja/articles/21916740981273',
}

const mfk = {
  assigned,
  unassigned,
}

const bfwAssigned: {[key in HelpLinkKey]: string} = {
  customerSuggestHelp: 'https://support.bizforward.co.jp/hc/ja/articles/4407841672857',
  accountTransferNotificationHelp: 'https://support.bizforward.co.jp/hc/ja/articles/4407847957273',
  transactionCSVDefaultHelp: 'https://support.bizforward.co.jp/hc/ja/articles/4407841697817',
  transactionCSVDetailHelp: 'https://support.bizforward.co.jp/hc/ja/articles/4407841701401',
  transactionCSVMFCHelp: 'https://support.bizforward.co.jp/hc/ja/articles/4407841703833',
  transactionCancelHelp: 'https://support.bizforward.co.jp/hc/ja/articles/4407841684505',
  destinationChangeHelp: 'https://support.bizforward.co.jp/hc/ja/articles/4407847951769',
  customerCSVHelp: 'https://support.bizforward.co.jp/hc/ja/articles/4407841650073',
  transactionCSVTypeHelp: 'https://support.bizforward.co.jp/hc/ja/articles/4407841693209',
  teamNameHelp: 'https://support.bizforward.co.jp/hc/ja/articles/4407847936409',
  invoiceIssueDateHelp: 'https://support.bizforward.co.jp/hc/ja/articles/4407841649049',
  permissionHelp: 'https://support.bizforward.co.jp/hc/ja/articles/4407841673881',
  topPage: 'https://support.bizforward.co.jp/hc/ja',
  earlyBankTransferHelp: 'https://support.bizforward.co.jp/hc/ja/articles/4407841552409',
  customerExamintionHelp: 'https://support.bizforward.co.jp/hc/ja/articles/4407841637401',
  signinTwoFactorUnknownCodeHelp: 'https://support.bizforward.co.jp/hc/ja/articles/4414507515545#unknown_code',
  invoiceModeChangesHelp: 'https://support.bizforward.co.jp/hc/ja/sections/13699712472217',
  claimableAmountHelp: 'https://support.bizforward.co.jp/hc/ja/articles/13699879051545',
  unguaranteeBillingHelp: 'https://support.bizforward.co.jp/hc/ja/articles/4407847962905',
  transactionRegisterValidationHelp: 'https://support.bizforward.co.jp/hc/ja/articles/21916950876697',
}

const bfwUnassigned: {[key in HelpLinkKey]: string} = {
  customerSuggestHelp: 'https://support.bizforward.co.jp/hc/ja/articles/4407841653273',
  accountTransferNotificationHelp: 'https://support.bizforward.co.jp/hc/ja/articles/4407847950745',
  transactionCSVDefaultHelp: 'https://support.bizforward.co.jp/hc/ja/articles/4407847942425',
  transactionCSVDetailHelp: 'https://support.bizforward.co.jp/hc/ja/articles/4407841675673',
  transactionCSVMFCHelp: 'https://support.bizforward.co.jp/hc/ja/articles/4407847905433',
  transactionCancelHelp: 'https://support.bizforward.co.jp/hc/ja/articles/4407841652377',
  destinationChangeHelp: 'https://support.bizforward.co.jp/hc/ja/articles/4407841674777',
  customerCSVHelp: 'https://support.bizforward.co.jp/hc/ja/articles/4407841629081',
  transactionCSVTypeHelp: 'https://support.bizforward.co.jp/hc/ja/articles/4407847937433',
  teamNameHelp: 'https://support.bizforward.co.jp/hc/ja/articles/4407841626393',
  invoiceIssueDateHelp: 'https://support.bizforward.co.jp/hc/ja/articles/4407847920409',
  permissionHelp: 'https://support.bizforward.co.jp/hc/ja/articles/4407841651225',
  topPage: 'https://support.bizforward.co.jp/hc/ja',
  earlyBankTransferHelp: 'https://support.bizforward.co.jp/hc/ja/articles/4407841552409',
  customerExamintionHelp: 'https://support.bizforward.co.jp/hc/ja/articles/4407841637401',
  signinTwoFactorUnknownCodeHelp: 'https://support.bizforward.co.jp/hc/ja/articles/4414507515545#unknown_code',
  invoiceModeChangesHelp: 'https://support.bizforward.co.jp/hc/ja/sections/13699764684441',
  claimableAmountHelp: 'https://support.bizforward.co.jp/hc/ja/articles/13700520405017',
  unguaranteeBillingHelp: '', // 譲渡なしでは存在しない
  transactionRegisterValidationHelp: 'https://support.bizforward.co.jp/hc/ja/articles/21916972013721',
}

const bfw = {
  assigned: bfwAssigned,
  unassigned: bfwUnassigned,
}

export const links = {
  mfk,
  bfw,
}
