<template>
  <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg" class="fill-current">
    <path d="M11.992 3l9.643 3.857v1.286H20.35a.59.59 0 01-.211.452.676.676 0 01-.482.19H4.328a.71.71 0 01-.492-.19.603.603 0 01-.2-.452H2.35V6.857L11.993 3zM4.921 9.429h2.571v7.714h1.286V9.429h2.572v7.714h1.285V9.429h2.572v7.714h1.285V9.429h2.572v7.714h.593c.187 0 .348.063.482.19a.59.59 0 01.21.453v.643H3.636v-.643c0-.174.067-.325.201-.452a.71.71 0 01.492-.191h.593V9.429zm16.021 9.642c.188 0 .349.064.482.191a.59.59 0 01.211.452V21H2.35v-1.286c0-.174.067-.324.2-.452a.71.71 0 01.493-.19h17.9z" />
  </svg>
</template>

<script>
export default {
}
</script>
