
import { Component, Emit, Prop, Vue } from 'nuxt-property-decorator'
import { createPopper, Instance, Placement, Rect } from '@popperjs/core'
import Status from '../status.vue'
import { isHTMLObject, isVNode } from '~/utils/vue-helper'

@Component({
  components: { Status },
  props: {
    type: String,
    isPrimary: {
      type: Boolean,
      default: false,
    },
    isAccept: {
      type: Boolean,
      default: false,
    },
  },
})
export default class extends Vue {
  @Prop({ default: '' })
  private readonly prefix!: string

  @Prop({ default: false })
  private readonly disabled!: boolean

  @Prop({ default: false })
  private readonly loading!: boolean

  @Prop({ type: String, default: '' })
  private readonly href!: string

  private isActive = false
  private instance: Instance | null = null

  private openPopper (): void {
    this.isActive = true
    this.$nextTick(() => {
      const refs = this.$slots.default
      const ref = Array.isArray(refs) ? refs[0] : refs
      const pops = this.$refs[`${this.prefix}-popover`]
      const pop = Array.isArray(pops) ? pops[0] : pops
      let target
      if (isVNode(ref)) {
        target = ref.elm
      }
      if (target && isHTMLObject(target) && pop && isHTMLObject(pop)) {
        this.instance = createPopper(target, pop, {
          placement: 'bottom-end',
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: ({ placement, reference, popper }: {placement: Placement, reference: Rect, popper: Rect}): number[] => {
                  const offset = popper.width * 0.12
                  if (placement === 'bottom-end' || placement === 'top-end' || placement === 'auto-end') {
                    return [(reference.width / 2) * -1 + offset, 15]
                  }
                  return [(reference.width / 2) - offset, 15]
                },
              },
            },
            {
              name: 'flip',
              options: {
                flipVariations: false,
              },
            },
          ],
        })
      }
    })
  }

  private closePopper (): void {
    this.isActive = false
    if (this.instance) {
      this.instance.destroy()
      this.instance = null
    }
  }

  @Emit('click')
  private onClick (e: Event): Event {
    return e
  }

  get isLink (): boolean {
    return !!this.href
  }
}
