
import { Action, Component, Mutation, State, mixins, namespace } from 'nuxt-property-decorator'
import Common from './common'
import BankTransferIcon from '~/components/element/icon/bank-transfer-icon.vue'
import ClaimIcon from '~/components/element/icon/claim-icon.vue'
import CustomerIcon from '~/components/element/icon/customer-icon.vue'
import HomeIcon from '~/components/element/icon/home.vue'
import HorizontalCloseIcon from '~/components/element/icon/horizontal-close.vue'
import HorizontalOpenIcon from '~/components/element/icon/horizontal-open.vue'
import OpinionFormIcon from '~/components/element/icon/opinion-form-icon.vue'
import QuestionCircleIcon from '~/components/element/icon/question-circle.vue'
import Logo from '~/components/element/logo.vue'
import AppHeader from '~/components/layout/header/app-header.vue'
import LogoAlert from '~/components/layout/logo-alert.vue'
import CompanyMenu from '~/components/layout/menu/company-menu.vue'
import ExternalLink from '~/components/layout/menu/external-link.vue'
import OshiraseMenu from '~/components/layout/menu/oshirase-menu.vue'
import TransactionMenu from '~/components/layout/menu/transaction-menu.vue'
import Notifications from '~/components/layout/notifications.vue'
import { ServiceProvider } from '~/types'
import { emitError } from '~/utils/nuxt-helper'

const sellerStore = namespace('seller')

@Component({
  components: {
    Notifications,
    AppHeader,
    BankTransferIcon,
    CustomerIcon,
    Logo,
    HomeIcon,
    ExternalLink,
    QuestionCircleIcon,
    ClaimIcon,
    TransactionMenu,
    HorizontalCloseIcon,
    HorizontalOpenIcon,
    LogoAlert,
    CompanyMenu,
    OpinionFormIcon,
    OshiraseMenu,
  },
  middleware: ['check-auth', 'check-bank-account'],
})
export default class DefaultLayout extends mixins(Common) {
  @State
  private readonly isMenuOpened!: boolean

  @State
  private readonly isMenuCollapsed!: boolean

  @State
  private readonly isForceMenuCollapsed!: boolean

  @State
  private readonly serviceProvider!: ServiceProvider

  @Mutation
  private readonly setMenuCollapsed!: (b: boolean) => void

  @Action
  private readonly closeMenu!: () => void

  @Action
  private readonly setCookie!: (kv: {k: string, v: string}) => void

  @sellerStore.State
  private readonly sellerId!: string

  @sellerStore.State
  private readonly logoUploaded!: boolean

  private defaultPage = ''

  get showAlert (): boolean {
    return !this.logoUploaded
  }

  get supportCenterUrl (): string {
    return this.$supportLink('topPage')
  }

  get isMFK (): boolean {
    return this.serviceProvider.type === 'mfk'
  }

  get inquiryUrl (): string {
    const mfk = 'https://docs.google.com/forms/d/1UY5aJnySGZj0YlX5IAFs6is6ixfnl8QF_kMf-JZS53A/viewform?edit_requested=true&edit_requested=true'
    const bfw = 'https://docs.google.com/forms/d/e/1FAIpQLSezgYZY0Ff0Tr1LHYXjekbYdoPvBbIDU_U2d86tu4McVlRO4Q/viewform'
    return this.isMFK ? mfk : bfw
  }

  protected async mounted (): Promise<void> {
    if (!this.isForceMenuCollapsed) {
      this.resizeMenu()
      window.addEventListener('resize', this.resizeMenu)
    }
    try {
      this.defaultPage = await this.$store.dispatch('seller/getDefaultPage', null, { root: true })
    } catch (e) {
      emitError(this.$nuxt, e as Error)
    }
  }

  private resizeMenu (): void {
    const isSmallWindow = document.documentElement.clientWidth < 1024
    if (this.isMenuCollapsed !== isSmallWindow) {
      this.setMenuCollapsed(isSmallWindow)
    }
  }

  private toggle (): void {
    if (!this.isForceMenuCollapsed) {
      window.removeEventListener('resize', this.resizeMenu) // 一度操作したら画面サイズ変更に追従しないようにする
    }
    this.setMenuCollapsed(!this.isMenuCollapsed)
    this.setCookie({ k: 'menu-collapsed', v: this.isMenuCollapsed ? 'collapsed' : 'expanded' })
  }

  private close (): void {
    this.closeMenu()
  }
}
