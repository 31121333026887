
import { Component, Vue, Action } from 'nuxt-property-decorator'

@Component
export default class extends Vue {
  @Action
  private toggleMenu!: () => void

  get isActive (): boolean {
    return this.$store.state.isMenuOpened
  }

  private onClick (): void {
    this.toggleMenu()
  }
}
