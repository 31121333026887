
import { Component, Emit, Prop, Vue } from 'nuxt-property-decorator'

@Component({
  props: {
    type: String,
  },
})
export default class extends Vue {
  @Prop({ default: false })
  private readonly disabled!: boolean

  @Prop({ default: false })
  private readonly loading!: boolean

  @Prop({ default: '' })
  private readonly styles!: string

  get bgColor (): string {
    const classes: string[] = ['bg-gray-1']
    if (!this.disabled) {
      classes.push('hover:bg-gray-2')
    }
    return classes.join(' ')
  }

  @Emit('click')
  private onClick (e: Event): Event {
    return e
  }
}
