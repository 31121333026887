<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="fill-current"
  >
    <path fill-rule="evenodd" clip-rule="evenodd" d="M7.9987 1.33301C4.3187 1.33301 1.33203 4.31967 1.33203 7.99967C1.33203 11.6797 4.3187 14.6663 7.9987 14.6663C11.6787 14.6663 14.6654 11.6797 14.6654 7.99967C14.6654 4.31967 11.6787 1.33301 7.9987 1.33301ZM7.9987 3.33301C9.10536 3.33301 9.9987 4.22634 9.9987 5.33301C9.9987 6.43967 9.10536 7.33301 7.9987 7.33301C6.89203 7.33301 5.9987 6.43967 5.9987 5.33301C5.9987 4.22634 6.89203 3.33301 7.9987 3.33301ZM3.9987 10.653C4.8587 11.9463 6.33203 12.7997 7.9987 12.7997C9.66536 12.7997 11.1387 11.9463 11.9987 10.653C11.9787 9.32634 9.32536 8.59967 7.9987 8.59967C6.66536 8.59967 4.0187 9.32634 3.9987 10.653Z" />
  </svg>
</template>

<script>
export default {
}
</script>
