import { GetterTree, MutationTree, ActionTree, ActionContext } from 'vuex'
import { DestinationRegisterState, Cc, SubContact } from '~/types/registers'
import { Destination, DestinationInput } from '~/types/eldamar'

export const state = (): DestinationRegisterState => ({
  name: '',
  userDefinedId: '',
  zipcode: '',
  prefecture: '',
  address1: '',
  tel: '',
  subContacts: [{ name: '', tel: '' }],
  email: '',
  ccs: [{ cc: '' }],
  oldDestination: null,
})

export const getters: GetterTree<DestinationRegisterState, DestinationRegisterState> = {
  inputData (state: DestinationRegisterState): DestinationInput {
    return {
      zipCode: state.zipcode,
      prefecture: state.prefecture,
      address1: state.address1,
      address2: state.address2,
      tel: state.tel,
      department: state.department,
      name: state.contactName,
      nameKana: state.contactNameKana,
      title: state.title,
      email: state.email,
      ccEmails: state.ccs.map(cc => cc.cc).filter(cc => cc),
      subContacts: state.subContacts.map(sc => ({ name: sc.name, tel: sc.tel })),
    }
  },
}

export const mutations: MutationTree<DestinationRegisterState> = {
  setName (state: DestinationRegisterState, name: string): void {
    state.name = name
  },
  setUserDefinedId (state: DestinationRegisterState, userDefinedId: string): void {
    state.userDefinedId = userDefinedId
  },
  setZipCode (state: DestinationRegisterState, zipcode: string): void {
    state.zipcode = zipcode
  },
  setPrefecture (state: DestinationRegisterState, prefecture: string): void {
    state.prefecture = prefecture
  },
  setAddress1 (state: DestinationRegisterState, address1: string): void {
    state.address1 = address1
  },
  setAddress2 (state: DestinationRegisterState, address2: string): void {
    state.address2 = address2
  },
  setTel (state: DestinationRegisterState, tel: string): void {
    state.tel = tel
  },
  setSubContacts (state: DestinationRegisterState, scs: SubContact[]): void {
    // NOTE:
    // そのままsetしてしまうとscsに入れたobjectを更新できなくなるため詰め直している（更新するとmutation外からのstate操作で怒られる）
    // filed数が少ないのでJSON.pars,stringifyでDeepCopyしていない
    state.subContacts = scs.map((sc) => { return { name: sc.name, tel: sc.tel } })
  },
  setDepartment (state: DestinationRegisterState, department: string): void {
    state.department = department
  },
  setContactName (state: DestinationRegisterState, contactName: string): void {
    state.contactName = contactName
  },
  setContactNameKana (state: DestinationRegisterState, contactNameKana: string): void {
    state.contactNameKana = contactNameKana
  },
  setTitle (state: DestinationRegisterState, title: string): void {
    state.title = title
  },
  setEmail (state: DestinationRegisterState, email: string): void {
    state.email = email
  },
  setCc (state: DestinationRegisterState, cc: Cc): void {
    state.ccs.push(cc)
  },
  setCcs (state: DestinationRegisterState, ccs: Cc[]): void {
    // NOTE:
    // そのままsetしてしまうとccsに入れたobjectを更新できなくなるため詰め直している（更新するとmutation外からのstate操作で怒られる）
    // filed数が少ないのでJSON.pars,stringifyでDeepCopyしていない
    state.ccs = ccs.map((c) => { return { cc: c.cc } })
  },
  setError (state: DestinationRegisterState, error: string): void {
    state.error = error
  },
  setReferrerUrl (state: DestinationRegisterState, url?: string): void {
    state.referrerUrl = url
  },
  setOldDestination (state: DestinationRegisterState, d: Destination | null): void {
    state.oldDestination = d
  },
}

export const actions: ActionTree<DestinationRegisterState, DestinationRegisterState> = {
  reset (ctx: ActionContext<DestinationRegisterState, DestinationRegisterState>): void {
    ctx.commit('setName', '')
    ctx.commit('setUserDefinedId', '')
    ctx.commit('setZipCode', '')
    ctx.commit('setPrefecture', '')
    ctx.commit('setAddress1', '')
    ctx.commit('setAddress2', '')
    ctx.commit('setTel', '')
    ctx.commit('setSubContacts', [{ name: '', tel: '' }])
    ctx.commit('setDepartment', '')
    ctx.commit('setContactName', '')
    ctx.commit('setContactNameKana', '')
    ctx.commit('setTitle', '')
    ctx.commit('setEmail', '')
    ctx.commit('setCcs', [{ cc: '' }])
    ctx.commit('setError', '')
    ctx.commit('setReferrerUrl', '')
    ctx.commit('setOldDestination', null)
  },
}
