import { MutationTree, ActionTree, ActionContext } from 'vuex'
import { CustomersCSVRegisterState, CustomerParseResult, CustomerParseInvalidResponse } from '~/types/registers'

export const state = (): CustomersCSVRegisterState => ({
  hasBasicCreditFacility: false,
})

export const mutations: MutationTree<CustomersCSVRegisterState> = {
  setParseResult (state: CustomersCSVRegisterState, result?: CustomerParseResult): void {
    state.parseResult = result
  },
  setParseValidationError (state: CustomersCSVRegisterState, resp?: CustomerParseInvalidResponse[]): void {
    state.parseValidationError = resp
  },
  setHasBasicCreditFacility (state: CustomersCSVRegisterState, bool: boolean): void {
    state.hasBasicCreditFacility = bool
  },
}

export const actions: ActionTree<CustomersCSVRegisterState, CustomersCSVRegisterState> = {
  reset (ctx: ActionContext<CustomersCSVRegisterState, CustomersCSVRegisterState>): void {
    ctx.commit('setParseResult', undefined)
    ctx.commit('setParseValidationError', undefined)
  },
}
