
import { Component, Vue, State } from 'nuxt-property-decorator'
import Hamburger from '~/components/layout/header/hamburger.vue'
import Logo from '~/components/element/logo.vue'

@Component({
  components: {
    Hamburger,
    Logo,
  },
})
export default class extends Vue {
  @State
  private readonly isMenuOpened!: boolean
}
