import { Context } from '@nuxt/types'
import cookie from 'cookie'
import { emitError } from '~/utils/nuxt-helper'

const COOKIE_KEY = '__Host-SESS'

export default async function (context: Context): Promise<void> {
  if (process.client) {
    return
  }

  let token
  if (context.req.headers.cookie) {
    token = cookie.parse(context.req.headers.cookie)[COOKIE_KEY]
  }

  if (token) {
    try {
      const defaultPage = await context.store.dispatch('seller/getDefaultPage', null, { root: true })
      context.redirect(defaultPage)
    } catch (e) {
      emitError(context, e)
    }
  }
}
