import { render, staticRenderFns } from "./oshirase.vue?vue&type=template&id=4f6ffc17&scoped=true&"
import script from "./oshirase.vue?vue&type=script&lang=ts&"
export * from "./oshirase.vue?vue&type=script&lang=ts&"
import style0 from "./oshirase.vue?vue&type=style&index=0&id=4f6ffc17&prod&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4f6ffc17",
  null
  
)

export default component.exports