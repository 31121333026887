import { Context } from '@nuxt/types'
import { emitError } from '~/utils/nuxt-helper'

export default async function (context: Context): Promise<void> {
  try {
    const ok = await context.store.dispatch('bank-account/check')
    if (!ok) {
      context.redirect('/tour/bank')
    }
  } catch (e) {
    emitError(context, e)
  }
}
