import { MutationTree, ActionTree, ActionContext } from 'vuex'
import { TransactionInput } from '~/types/eldamar'
import { MfcInvoiceCSVRegisterState, MfcInvoiceParseInvalidResponse, MfcInvoiceCustomerParseInvalidResponse, IssueDateSetting } from '~/types/registers'

export const state = (): MfcInvoiceCSVRegisterState => ({
  mfcInvoiceCSV: null,
  mfcInvoiceCustomerCSV: null,
  mfcInvoiceError: [],
  mfcInvoiceCustomerError: [],
  issueDateSetting: null,
})

export const mutations: MutationTree<MfcInvoiceCSVRegisterState> = {
  setMfcInvoiceError (state: MfcInvoiceCSVRegisterState, result: MfcInvoiceParseInvalidResponse[]): void {
    state.mfcInvoiceError = result
  },
  setMfcInvoiceCustomerError (state: MfcInvoiceCSVRegisterState, result: MfcInvoiceCustomerParseInvalidResponse[]): void {
    state.mfcInvoiceCustomerError = result
  },
  setMfcInvoiceCSV (state: MfcInvoiceCSVRegisterState, file: File | null): void {
    state.mfcInvoiceCSV = file
  },
  setMfcInvoiceCustomerCSV (state: MfcInvoiceCSVRegisterState, file: File | null): void {
    state.mfcInvoiceCustomerCSV = file
  },
  setJoinedMfcInvoices (state: MfcInvoiceCSVRegisterState, invoices?: TransactionInput[]): void {
    state.joinedMfcInvoices = invoices
  },
  setIssueDateSetting (state: MfcInvoiceCSVRegisterState, setting?: IssueDateSetting): void {
    state.issueDateSetting = setting
  },
  setPostingFlag (state: MfcInvoiceCSVRegisterState, posting?: boolean): void {
    state.postingFlag = posting
  },
  setEmailFlag (state: MfcInvoiceCSVRegisterState, email?: boolean): void {
    state.emailFlag = email
  },
}

export const actions: ActionTree<MfcInvoiceCSVRegisterState, MfcInvoiceCSVRegisterState> = {
  reset (ctx: ActionContext<MfcInvoiceCSVRegisterState, MfcInvoiceCSVRegisterState>): void {
    ctx.commit('setMfcInvoiceCSV', null)
    ctx.commit('setMfcInvoiceCustomerCSV', null)
    ctx.commit('setMfcInvoiceError', [])
    ctx.commit('setMfcInvoiceCustomerError', [])
    ctx.commit('setJoinedMfcInvoices', undefined)
    ctx.commit('setIssueDateSetting', null)
    ctx.commit('setPostingFlag', undefined)
    ctx.commit('setEmailFlag', undefined)
  },
}
