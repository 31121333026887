
import { Component, Vue } from 'nuxt-property-decorator'
import { SignoutMutation, SignoutMutationVariables } from '~/types/eldamar'
import signoutQuery from '~/queries/signout.gql'
import { emitError } from '~/utils/nuxt-helper'
import LogoutIcon from '~/components/element/icon/logout-icon.vue'

@Component({
  components: {
    LogoutIcon,
  },
})
export default class extends Vue {
  private async signout (): Promise<void> {
    try {
      await this.$apollo.mutate<SignoutMutation, SignoutMutationVariables>({
        mutation: signoutQuery,
        variables: {
          tenantId: this.$store.state.tenantId,
        },
      })
      location.href = '/signin'
    } catch (e) {
      emitError(this.$nuxt, e)
    }
  }
}
