<template>
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="fill-current">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M17 6.00002C18.1046 6.00002 19 6.89564 19 8.00021V15.9998C19 17.1044 18.1046 18 17 18H7C5.89543 18 5 17.1046 5 16V8C5 6.89543 5.89543 6 7 6L17 6.00002ZM8.5 12.75H7V11.25H8.5V12.75ZM17 12.75H10V11.25H17V12.75ZM7 10H8.5V8.5H7V10ZM10 10H17V8.5H10V10ZM8.5 15.5H7V14H8.5V15.5ZM14 15.5H10V14H14V15.5Z" />
  </svg>
</template>

<script>
export default {
}
</script>
