
import { Component, Vue } from 'nuxt-property-decorator'

@Component
export default class extends Vue {
  private percent = 0
  private throttle = 0
  private duration = 3000
  private loading = false
  private isFailed = false
  private timerId = 0
  private timeoutId = 0
  private degree = 0

  start (): void {
    this.loading = true
    this.percent = 0
    if (this.throttle) {
      this.timeoutId = window.setTimeout(() => this.startTimer(), this.throttle)
    } else {
      this.startTimer()
    }
  }

  finish (): void {
    this.percent = 100
    setTimeout(() => {
      this.clear()
    }, 500)
    setTimeout(() => {
      this.loading = false
      this.$nextTick(() => {
        this.percent = 0
        this.isFailed = false
      })
    }, 1000)
  }

  fail (): void {
    this.isFailed = true
  }

  clear (): void {
    window.clearInterval(this.timerId)
    window.clearTimeout(this.timeoutId)
    this.timerId = 0
    this.timeoutId = 0
  }

  startTimer (): void {
    if (!this.degree) {
      this.degree = 10000 / Math.floor(this.duration)
    }
    this.timerId = window.setInterval(() => {
      this.percent = Math.min(100, Math.floor(this.percent + this.degree))
    }, 100)
  }
}
