import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _7de29244 = () => interopDefault(import('../pages/a/index.vue' /* webpackChunkName: "pages/a/index" */))
const _5b8794e9 = () => interopDefault(import('../pages/banktransfers/index.vue' /* webpackChunkName: "pages/banktransfers/index" */))
const _895a56d2 = () => interopDefault(import('../pages/confirm_email.vue' /* webpackChunkName: "pages/confirm_email" */))
const _3d17e05c = () => interopDefault(import('../pages/customers/index.vue' /* webpackChunkName: "pages/customers/index" */))
const _77f67816 = () => interopDefault(import('../pages/members/index.vue' /* webpackChunkName: "pages/members/index" */))
const _6a0f1dc1 = () => interopDefault(import('../pages/reset_password.vue' /* webpackChunkName: "pages/reset_password" */))
const _6611b602 = () => interopDefault(import('../pages/send_password_reset/index.vue' /* webpackChunkName: "pages/send_password_reset/index" */))
const _29795f50 = () => interopDefault(import('../pages/signin.vue' /* webpackChunkName: "pages/signin" */))
const _004cb264 = () => interopDefault(import('../pages/signup.vue' /* webpackChunkName: "pages/signup" */))
const _76c1508c = () => interopDefault(import('../pages/verify_email.vue' /* webpackChunkName: "pages/verify_email" */))
const _75a2f2c1 = () => interopDefault(import('../pages/a/authorizations/index.vue' /* webpackChunkName: "pages/a/authorizations/index" */))
const _ead799cc = () => interopDefault(import('../pages/a/claimunits/index.vue' /* webpackChunkName: "pages/a/claimunits/index" */))
const _40ee5b88 = () => interopDefault(import('../pages/a/transactions/index.vue' /* webpackChunkName: "pages/a/transactions/index" */))
const _b08498c0 = () => interopDefault(import('../pages/customers/new.vue' /* webpackChunkName: "pages/customers/new" */))
const _754d0ed1 = () => interopDefault(import('../pages/customers/new/confirm.vue' /* webpackChunkName: "pages/customers/new/confirm" */))
const _628d7494 = () => interopDefault(import('../pages/customers/new/confirm/index.vue' /* webpackChunkName: "pages/customers/new/confirm/index" */))
const _fbcfb632 = () => interopDefault(import('../pages/customers/new/confirm/complete.vue' /* webpackChunkName: "pages/customers/new/confirm/complete" */))
const _501069c2 = () => interopDefault(import('../pages/customers/new2.vue' /* webpackChunkName: "pages/customers/new2" */))
const _c6b9d276 = () => interopDefault(import('../pages/customers/new2/index.vue' /* webpackChunkName: "pages/customers/new2/index" */))
const _03a082f3 = () => interopDefault(import('../pages/customers/new2/confirm.vue' /* webpackChunkName: "pages/customers/new2/confirm" */))
const _3afdb425 = () => interopDefault(import('../pages/developers/apikey.vue' /* webpackChunkName: "pages/developers/apikey" */))
const _273445ca = () => interopDefault(import('../pages/developers/webhook/index.vue' /* webpackChunkName: "pages/developers/webhook/index" */))
const _1ff417a8 = () => interopDefault(import('../pages/earlytransfers/new.vue' /* webpackChunkName: "pages/earlytransfers/new" */))
const _50812d20 = () => interopDefault(import('../pages/earlytransfers/select.vue' /* webpackChunkName: "pages/earlytransfers/select" */))
const _54bf5ba3 = () => interopDefault(import('../pages/earlytransfers/select/index.vue' /* webpackChunkName: "pages/earlytransfers/select/index" */))
const _25bf0851 = () => interopDefault(import('../pages/earlytransfers/select/confirm.vue' /* webpackChunkName: "pages/earlytransfers/select/confirm" */))
const _41bdca3f = () => interopDefault(import('../pages/members/interfaces.ts' /* webpackChunkName: "pages/members/interfaces" */))
const _b74cd616 = () => interopDefault(import('../pages/members/invite.vue' /* webpackChunkName: "pages/members/invite" */))
const _0f09ea70 = () => interopDefault(import('../pages/send_password_reset/success.vue' /* webpackChunkName: "pages/send_password_reset/success" */))
const _3a1f9f06 = () => interopDefault(import('../pages/settings/claim.vue' /* webpackChunkName: "pages/settings/claim" */))
const _6b08db34 = () => interopDefault(import('../pages/settings/email.vue' /* webpackChunkName: "pages/settings/email" */))
const _5e24c762 = () => interopDefault(import('../pages/settings/email_notifications.vue' /* webpackChunkName: "pages/settings/email_notifications" */))
const _b639876c = () => interopDefault(import('../pages/settings/invoice_mode.ts' /* webpackChunkName: "pages/settings/invoice_mode" */))
const _1824da51 = () => interopDefault(import('../pages/settings/logo.vue' /* webpackChunkName: "pages/settings/logo" */))
const _51396041 = () => interopDefault(import('../pages/settings/password.vue' /* webpackChunkName: "pages/settings/password" */))
const _e9ce12ba = () => interopDefault(import('../pages/settings/show.vue' /* webpackChunkName: "pages/settings/show" */))
const _07796916 = () => interopDefault(import('../pages/settings/tax.vue' /* webpackChunkName: "pages/settings/tax" */))
const _3b0289b0 = () => interopDefault(import('../pages/settings/transaction.vue' /* webpackChunkName: "pages/settings/transaction" */))
const _fec4b570 = () => interopDefault(import('../pages/settings/two_factor.vue' /* webpackChunkName: "pages/settings/two_factor" */))
const _212ec190 = () => interopDefault(import('../pages/tour/bank/index.vue' /* webpackChunkName: "pages/tour/bank/index" */))
const _07c088ca = () => interopDefault(import('../pages/tour/complete.vue' /* webpackChunkName: "pages/tour/complete" */))
const _64378e88 = () => interopDefault(import('../pages/tour/logo.vue' /* webpackChunkName: "pages/tour/logo" */))
const _c252f362 = () => interopDefault(import('../pages/a/authorizations/new.vue' /* webpackChunkName: "pages/a/authorizations/new" */))
const _523bc280 = () => interopDefault(import('../pages/a/authorizations/new/confirm.vue' /* webpackChunkName: "pages/a/authorizations/new/confirm" */))
const _70c3ee30 = () => interopDefault(import('../pages/a/claimunits/new.vue' /* webpackChunkName: "pages/a/claimunits/new" */))
const _5e7a8d80 = () => interopDefault(import('../pages/a/claimunits/new/steps.vue' /* webpackChunkName: "pages/a/claimunits/new/steps" */))
const _298c7150 = () => interopDefault(import('../pages/a/claimunits/new/steps/complete.vue' /* webpackChunkName: "pages/a/claimunits/new/steps/complete" */))
const _6b7b171e = () => interopDefault(import('../pages/a/claimunits/new/steps/confirm.vue' /* webpackChunkName: "pages/a/claimunits/new/steps/confirm" */))
const _019b55f4 = () => interopDefault(import('../pages/a/claimunits/new/steps/transactions.vue' /* webpackChunkName: "pages/a/claimunits/new/steps/transactions" */))
const _f7715a86 = () => interopDefault(import('../pages/a/claimunits/transactions.vue' /* webpackChunkName: "pages/a/claimunits/transactions" */))
const _d8caacc2 = () => interopDefault(import('../pages/a/transactions/canceled.vue' /* webpackChunkName: "pages/a/transactions/canceled" */))
const _3bd7eeec = () => interopDefault(import('../pages/a/transactions/new.vue' /* webpackChunkName: "pages/a/transactions/new" */))
const _16c64c8a = () => interopDefault(import('../pages/a/transactions/new/confirm.vue' /* webpackChunkName: "pages/a/transactions/new/confirm" */))
const _20603004 = () => interopDefault(import('../pages/a/transactions/new/confirm/index.vue' /* webpackChunkName: "pages/a/transactions/new/confirm/index" */))
const _0a0d013d = () => interopDefault(import('../pages/a/transactions/new/confirm/complete.vue' /* webpackChunkName: "pages/a/transactions/new/confirm/complete" */))
const _047d7e37 = () => interopDefault(import('../pages/customers/csv/confirm.vue' /* webpackChunkName: "pages/customers/csv/confirm" */))
const _a07f4894 = () => interopDefault(import('../pages/customers/csv/customer-inputs.ts' /* webpackChunkName: "pages/customers/csv/customer-inputs" */))
const _2014313f = () => interopDefault(import('../pages/customers/csv/error.vue' /* webpackChunkName: "pages/customers/csv/error" */))
const _57f78673 = () => interopDefault(import('../pages/customers/csv/fields.ts' /* webpackChunkName: "pages/customers/csv/fields" */))
const _6cdd1897 = () => interopDefault(import('../pages/customers/csv/new.vue' /* webpackChunkName: "pages/customers/csv/new" */))
const _20d82cd5 = () => interopDefault(import('../pages/customers/csv/select.vue' /* webpackChunkName: "pages/customers/csv/select" */))
const _7edfb7f4 = () => interopDefault(import('../pages/developers/webhook/interface.ts' /* webpackChunkName: "pages/developers/webhook/interface" */))
const _49830798 = () => interopDefault(import('../pages/developers/webhook/new.vue' /* webpackChunkName: "pages/developers/webhook/new" */))
const _d826b204 = () => interopDefault(import('../pages/tour/bank/confirm.vue' /* webpackChunkName: "pages/tour/bank/confirm" */))
const _630988a8 = () => interopDefault(import('../pages/a/transactions/csv/complete.vue' /* webpackChunkName: "pages/a/transactions/csv/complete" */))
const _f8656dbe = () => interopDefault(import('../pages/a/transactions/csv/confirm.vue' /* webpackChunkName: "pages/a/transactions/csv/confirm" */))
const _6428f57a = () => interopDefault(import('../pages/a/transactions/csv/default-error-functions.ts' /* webpackChunkName: "pages/a/transactions/csv/default-error-functions" */))
const _88885f48 = () => interopDefault(import('../pages/a/transactions/csv/detail-error-functions.ts' /* webpackChunkName: "pages/a/transactions/csv/detail-error-functions" */))
const _b064a2ae = () => interopDefault(import('../pages/a/transactions/csv/error.vue' /* webpackChunkName: "pages/a/transactions/csv/error" */))
const _409df846 = () => interopDefault(import('../pages/a/transactions/csv/fields.ts' /* webpackChunkName: "pages/a/transactions/csv/fields" */))
const _38cae881 = () => interopDefault(import('../pages/a/transactions/csv/new.vue' /* webpackChunkName: "pages/a/transactions/csv/new" */))
const _df6346aa = () => interopDefault(import('../pages/a/transactions/csv/select.vue' /* webpackChunkName: "pages/a/transactions/csv/select" */))
const _43ea44fe = () => interopDefault(import('../pages/a/transactions/csv/transaction-inputs.ts' /* webpackChunkName: "pages/a/transactions/csv/transaction-inputs" */))
const _51c508b1 = () => interopDefault(import('../pages/a/transactions/csv/detail/confirm.vue' /* webpackChunkName: "pages/a/transactions/csv/detail/confirm" */))
const _30f926fe = () => interopDefault(import('../pages/a/transactions/csv/detail/transaction-detail-inputs.ts' /* webpackChunkName: "pages/a/transactions/csv/detail/transaction-detail-inputs" */))
const _509213e3 = () => interopDefault(import('../pages/a/transactions/csv/mfcinvoice/confirm.vue' /* webpackChunkName: "pages/a/transactions/csv/mfcinvoice/confirm" */))
const _3959994f = () => interopDefault(import('../pages/a/transactions/csv/mfcinvoice/mfc-invoice-error-functions.ts' /* webpackChunkName: "pages/a/transactions/csv/mfcinvoice/mfc-invoice-error-functions" */))
const _7b976443 = () => interopDefault(import('../pages/a/transactions/csv/mfcinvoice/new.vue' /* webpackChunkName: "pages/a/transactions/csv/mfcinvoice/new" */))
const _e9be9d2e = () => interopDefault(import('../pages/a/authorizations/_id.vue' /* webpackChunkName: "pages/a/authorizations/_id" */))
const _678a3105 = () => interopDefault(import('../pages/a/claimunits/_id/index.vue' /* webpackChunkName: "pages/a/claimunits/_id/index" */))
const _361321b2 = () => interopDefault(import('../pages/a/transactions/_id/index.vue' /* webpackChunkName: "pages/a/transactions/_id/index" */))
const _88fd2896 = () => interopDefault(import('../pages/developers/webhook/_id/index.vue' /* webpackChunkName: "pages/developers/webhook/_id/index" */))
const _475ff62e = () => interopDefault(import('../pages/members/invitations/_id.vue' /* webpackChunkName: "pages/members/invitations/_id" */))
const _4b80ae01 = () => interopDefault(import('../pages/a/claimunits/_id/destination.vue' /* webpackChunkName: "pages/a/claimunits/_id/destination" */))
const _39641832 = () => interopDefault(import('../pages/a/claimunits/_id/destination/confirm.vue' /* webpackChunkName: "pages/a/claimunits/_id/destination/confirm" */))
const _c6939d96 = () => interopDefault(import('../pages/a/claimunits/_id/destination/confirm/index.vue' /* webpackChunkName: "pages/a/claimunits/_id/destination/confirm/index" */))
const _0c9fb78c = () => interopDefault(import('../pages/a/claimunits/_id/issuedate.vue' /* webpackChunkName: "pages/a/claimunits/_id/issuedate" */))
const _1cb3ecf7 = () => interopDefault(import('../pages/developers/webhook/_id/edit.vue' /* webpackChunkName: "pages/developers/webhook/_id/edit" */))
const _876bad26 = () => interopDefault(import('../pages/a/claimunits/_id/accounttransfernotification/reissue.vue' /* webpackChunkName: "pages/a/claimunits/_id/accounttransfernotification/reissue" */))
const _a15582b6 = () => interopDefault(import('../pages/a/transactions/_id/number/edit.vue' /* webpackChunkName: "pages/a/transactions/_id/number/edit" */))
const _41a52514 = () => interopDefault(import('../pages/a/claimunits/_id/invoice/_invoice_id/reissue.vue' /* webpackChunkName: "pages/a/claimunits/_id/invoice/_invoice_id/reissue" */))
const _7297bd91 = () => interopDefault(import('../pages/banktransfers/_id.vue' /* webpackChunkName: "pages/banktransfers/_id" */))
const _5d3cf1bd = () => interopDefault(import('../pages/customers/_id/index.vue' /* webpackChunkName: "pages/customers/_id/index" */))
const _7dcf87fe = () => interopDefault(import('../pages/members/_id.vue' /* webpackChunkName: "pages/members/_id" */))
const _659e04b9 = () => interopDefault(import('../pages/customers/_id/destination.vue' /* webpackChunkName: "pages/customers/_id/destination" */))
const _74fb36ea = () => interopDefault(import('../pages/customers/_id/destination/confirm.vue' /* webpackChunkName: "pages/customers/_id/destination/confirm" */))
const _e90d3426 = () => interopDefault(import('../pages/customers/_id/destination/confirm/index.vue' /* webpackChunkName: "pages/customers/_id/destination/confirm/index" */))
const _c9f9ee24 = () => interopDefault(import('../pages/customers/_id/destination/confirm/complete.vue' /* webpackChunkName: "pages/customers/_id/destination/confirm/complete" */))
const _0df8580c = () => interopDefault(import('../pages/customers/_id/examine.vue' /* webpackChunkName: "pages/customers/_id/examine" */))
const _6582873d = () => interopDefault(import('../pages/customers/_id/examine/confirm.vue' /* webpackChunkName: "pages/customers/_id/examine/confirm" */))
const _582c2800 = () => interopDefault(import('../pages/customers/_id/examine/confirm/index.vue' /* webpackChunkName: "pages/customers/_id/examine/confirm/index" */))
const _798565fb = () => interopDefault(import('../pages/customers/_id/examine/confirm/complete.vue' /* webpackChunkName: "pages/customers/_id/examine/confirm/complete" */))
const _0ff55ce6 = () => interopDefault(import('../pages/customers/_id/nameupdaterequest.vue' /* webpackChunkName: "pages/customers/_id/nameupdaterequest" */))
const _6e821217 = () => interopDefault(import('../pages/customers/_id/nameupdaterequest/confirm.vue' /* webpackChunkName: "pages/customers/_id/nameupdaterequest/confirm" */))
const _378b811b = () => interopDefault(import('../pages/customers/_id/destinations/new.vue' /* webpackChunkName: "pages/customers/_id/destinations/new" */))
const _6188f144 = () => interopDefault(import('../pages/customers/_id/destinations/new/index.vue' /* webpackChunkName: "pages/customers/_id/destinations/new/index" */))
const _9196b446 = () => interopDefault(import('../pages/customers/_id/destinations/new/complete.vue' /* webpackChunkName: "pages/customers/_id/destinations/new/complete" */))
const _1c419d68 = () => interopDefault(import('../pages/customers/_id/destinations/new/confirm.vue' /* webpackChunkName: "pages/customers/_id/destinations/new/confirm" */))
const _4418f8bb = () => interopDefault(import('../pages/customers/_id/number/edit.vue' /* webpackChunkName: "pages/customers/_id/number/edit" */))
const _1033a55f = () => interopDefault(import('../pages/customers/_id/requestaccounttransfer/new.vue' /* webpackChunkName: "pages/customers/_id/requestaccounttransfer/new" */))
const _d97ae9ac = () => interopDefault(import('../pages/customers/_id/destinations/_destinationId/edit.vue' /* webpackChunkName: "pages/customers/_id/destinations/_destinationId/edit" */))
const _02eebc5b = () => interopDefault(import('../pages/customers/_id/destinations/_destinationId/edit/confirm.vue' /* webpackChunkName: "pages/customers/_id/destinations/_destinationId/edit/confirm" */))
const _e4d55ac4 = () => interopDefault(import('../pages/customers/_id/destinations/_destinationId/edit/confirm/index.vue' /* webpackChunkName: "pages/customers/_id/destinations/_destinationId/edit/confirm/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/a",
    component: _7de29244,
    name: "a___ja"
  }, {
    path: "/banktransfers",
    component: _5b8794e9,
    name: "banktransfers___ja"
  }, {
    path: "/confirm_email",
    component: _895a56d2,
    name: "confirm_email___ja"
  }, {
    path: "/customers",
    component: _3d17e05c,
    name: "customers___ja"
  }, {
    path: "/members",
    component: _77f67816,
    name: "members___ja"
  }, {
    path: "/reset_password",
    component: _6a0f1dc1,
    name: "reset_password___ja"
  }, {
    path: "/send_password_reset",
    component: _6611b602,
    name: "send_password_reset___ja"
  }, {
    path: "/signin",
    component: _29795f50,
    name: "signin___ja"
  }, {
    path: "/signup",
    component: _004cb264,
    name: "signup___ja"
  }, {
    path: "/verify_email",
    component: _76c1508c,
    name: "verify_email___ja"
  }, {
    path: "/a/authorizations",
    component: _75a2f2c1,
    name: "a-authorizations___ja"
  }, {
    path: "/a/claimunits",
    component: _ead799cc,
    name: "a-claimunits___ja"
  }, {
    path: "/a/transactions",
    component: _40ee5b88,
    name: "a-transactions___ja"
  }, {
    path: "/customers/new",
    component: _b08498c0,
    name: "customers-new___ja",
    children: [{
      path: "confirm",
      component: _754d0ed1,
      children: [{
        path: "",
        component: _628d7494,
        name: "customers-new-confirm___ja"
      }, {
        path: "complete",
        component: _fbcfb632,
        name: "customers-new-confirm-complete___ja"
      }]
    }]
  }, {
    path: "/customers/new2",
    component: _501069c2,
    children: [{
      path: "",
      component: _c6b9d276,
      name: "customers-new2___ja"
    }, {
      path: "confirm",
      component: _03a082f3,
      name: "customers-new2-confirm___ja"
    }]
  }, {
    path: "/developers/apikey",
    component: _3afdb425,
    name: "developers-apikey___ja"
  }, {
    path: "/developers/webhook",
    component: _273445ca,
    name: "developers-webhook___ja"
  }, {
    path: "/earlytransfers/new",
    component: _1ff417a8,
    name: "earlytransfers-new___ja"
  }, {
    path: "/earlytransfers/select",
    component: _50812d20,
    children: [{
      path: "",
      component: _54bf5ba3,
      name: "earlytransfers-select___ja"
    }, {
      path: "confirm",
      component: _25bf0851,
      name: "earlytransfers-select-confirm___ja"
    }]
  }, {
    path: "/members/interfaces",
    component: _41bdca3f,
    name: "members-interfaces___ja"
  }, {
    path: "/members/invite",
    component: _b74cd616,
    name: "members-invite___ja"
  }, {
    path: "/send_password_reset/success",
    component: _0f09ea70,
    name: "send_password_reset-success___ja"
  }, {
    path: "/settings/claim",
    component: _3a1f9f06,
    name: "settings-claim___ja"
  }, {
    path: "/settings/email",
    component: _6b08db34,
    name: "settings-email___ja"
  }, {
    path: "/settings/email_notifications",
    component: _5e24c762,
    name: "settings-email_notifications___ja"
  }, {
    path: "/settings/invoice_mode",
    component: _b639876c,
    name: "settings-invoice_mode___ja"
  }, {
    path: "/settings/logo",
    component: _1824da51,
    name: "settings-logo___ja"
  }, {
    path: "/settings/password",
    component: _51396041,
    name: "settings-password___ja"
  }, {
    path: "/settings/show",
    component: _e9ce12ba,
    name: "settings-show___ja"
  }, {
    path: "/settings/tax",
    component: _07796916,
    name: "settings-tax___ja"
  }, {
    path: "/settings/transaction",
    component: _3b0289b0,
    name: "settings-transaction___ja"
  }, {
    path: "/settings/two_factor",
    component: _fec4b570,
    name: "settings-two_factor___ja"
  }, {
    path: "/tour/bank",
    component: _212ec190,
    name: "tour-bank___ja"
  }, {
    path: "/tour/complete",
    component: _07c088ca,
    name: "tour-complete___ja"
  }, {
    path: "/tour/logo",
    component: _64378e88,
    name: "tour-logo___ja"
  }, {
    path: "/a/authorizations/new",
    component: _c252f362,
    name: "a-authorizations-new___ja",
    children: [{
      path: "confirm",
      component: _523bc280,
      name: "a-authorizations-new-confirm___ja"
    }]
  }, {
    path: "/a/claimunits/new",
    component: _70c3ee30,
    name: "a-claimunits-new___ja",
    children: [{
      path: "steps",
      component: _5e7a8d80,
      name: "a-claimunits-new-steps___ja",
      children: [{
        path: "complete",
        component: _298c7150,
        name: "a-claimunits-new-steps-complete___ja"
      }, {
        path: "confirm",
        component: _6b7b171e,
        name: "a-claimunits-new-steps-confirm___ja"
      }, {
        path: "transactions",
        component: _019b55f4,
        name: "a-claimunits-new-steps-transactions___ja"
      }]
    }]
  }, {
    path: "/a/claimunits/transactions",
    component: _f7715a86,
    name: "a-claimunits-transactions___ja"
  }, {
    path: "/a/transactions/canceled",
    component: _d8caacc2,
    name: "a-transactions-canceled___ja"
  }, {
    path: "/a/transactions/new",
    component: _3bd7eeec,
    name: "a-transactions-new___ja",
    children: [{
      path: "confirm",
      component: _16c64c8a,
      children: [{
        path: "",
        component: _20603004,
        name: "a-transactions-new-confirm___ja"
      }, {
        path: "complete",
        component: _0a0d013d,
        name: "a-transactions-new-confirm-complete___ja"
      }]
    }]
  }, {
    path: "/customers/csv/confirm",
    component: _047d7e37,
    name: "customers-csv-confirm___ja"
  }, {
    path: "/customers/csv/customer-inputs",
    component: _a07f4894,
    name: "customers-csv-customer-inputs___ja"
  }, {
    path: "/customers/csv/error",
    component: _2014313f,
    name: "customers-csv-error___ja"
  }, {
    path: "/customers/csv/fields",
    component: _57f78673,
    name: "customers-csv-fields___ja"
  }, {
    path: "/customers/csv/new",
    component: _6cdd1897,
    name: "customers-csv-new___ja"
  }, {
    path: "/customers/csv/select",
    component: _20d82cd5,
    name: "customers-csv-select___ja"
  }, {
    path: "/developers/webhook/interface",
    component: _7edfb7f4,
    name: "developers-webhook-interface___ja"
  }, {
    path: "/developers/webhook/new",
    component: _49830798,
    name: "developers-webhook-new___ja"
  }, {
    path: "/tour/bank/confirm",
    component: _d826b204,
    name: "tour-bank-confirm___ja"
  }, {
    path: "/a/transactions/csv/complete",
    component: _630988a8,
    name: "a-transactions-csv-complete___ja"
  }, {
    path: "/a/transactions/csv/confirm",
    component: _f8656dbe,
    name: "a-transactions-csv-confirm___ja"
  }, {
    path: "/a/transactions/csv/default-error-functions",
    component: _6428f57a,
    name: "a-transactions-csv-default-error-functions___ja"
  }, {
    path: "/a/transactions/csv/detail-error-functions",
    component: _88885f48,
    name: "a-transactions-csv-detail-error-functions___ja"
  }, {
    path: "/a/transactions/csv/error",
    component: _b064a2ae,
    name: "a-transactions-csv-error___ja"
  }, {
    path: "/a/transactions/csv/fields",
    component: _409df846,
    name: "a-transactions-csv-fields___ja"
  }, {
    path: "/a/transactions/csv/new",
    component: _38cae881,
    name: "a-transactions-csv-new___ja"
  }, {
    path: "/a/transactions/csv/select",
    component: _df6346aa,
    name: "a-transactions-csv-select___ja"
  }, {
    path: "/a/transactions/csv/transaction-inputs",
    component: _43ea44fe,
    name: "a-transactions-csv-transaction-inputs___ja"
  }, {
    path: "/a/transactions/csv/detail/confirm",
    component: _51c508b1,
    name: "a-transactions-csv-detail-confirm___ja"
  }, {
    path: "/a/transactions/csv/detail/transaction-detail-inputs",
    component: _30f926fe,
    name: "a-transactions-csv-detail-transaction-detail-inputs___ja"
  }, {
    path: "/a/transactions/csv/mfcinvoice/confirm",
    component: _509213e3,
    name: "a-transactions-csv-mfcinvoice-confirm___ja"
  }, {
    path: "/a/transactions/csv/mfcinvoice/mfc-invoice-error-functions",
    component: _3959994f,
    name: "a-transactions-csv-mfcinvoice-mfc-invoice-error-functions___ja"
  }, {
    path: "/a/transactions/csv/mfcinvoice/new",
    component: _7b976443,
    name: "a-transactions-csv-mfcinvoice-new___ja"
  }, {
    path: "/a/authorizations/:id",
    component: _e9be9d2e,
    name: "a-authorizations-id___ja"
  }, {
    path: "/a/claimunits/:id",
    component: _678a3105,
    name: "a-claimunits-id___ja"
  }, {
    path: "/a/transactions/:id",
    component: _361321b2,
    name: "a-transactions-id___ja"
  }, {
    path: "/developers/webhook/:id",
    component: _88fd2896,
    name: "developers-webhook-id___ja"
  }, {
    path: "/members/invitations/:id?",
    component: _475ff62e,
    name: "members-invitations-id___ja"
  }, {
    path: "/a/claimunits/:id/destination",
    component: _4b80ae01,
    name: "a-claimunits-id-destination___ja",
    children: [{
      path: "confirm",
      component: _39641832,
      children: [{
        path: "",
        component: _c6939d96,
        name: "a-claimunits-id-destination-confirm___ja"
      }]
    }]
  }, {
    path: "/a/claimunits/:id/issuedate",
    component: _0c9fb78c,
    name: "a-claimunits-id-issuedate___ja"
  }, {
    path: "/developers/webhook/:id/edit",
    component: _1cb3ecf7,
    name: "developers-webhook-id-edit___ja"
  }, {
    path: "/a/claimunits/:id/accounttransfernotification/reissue",
    component: _876bad26,
    name: "a-claimunits-id-accounttransfernotification-reissue___ja"
  }, {
    path: "/a/transactions/:id/number/edit",
    component: _a15582b6,
    name: "a-transactions-id-number-edit___ja"
  }, {
    path: "/a/claimunits/:id/invoice/:invoice_id?/reissue",
    component: _41a52514,
    name: "a-claimunits-id-invoice-invoice_id-reissue___ja"
  }, {
    path: "/banktransfers/:id",
    component: _7297bd91,
    name: "banktransfers-id___ja"
  }, {
    path: "/customers/:id",
    component: _5d3cf1bd,
    name: "customers-id___ja"
  }, {
    path: "/members/:id",
    component: _7dcf87fe,
    name: "members-id___ja"
  }, {
    path: "/customers/:id/destination",
    component: _659e04b9,
    name: "customers-id-destination___ja",
    children: [{
      path: "confirm",
      component: _74fb36ea,
      children: [{
        path: "",
        component: _e90d3426,
        name: "customers-id-destination-confirm___ja"
      }, {
        path: "complete",
        component: _c9f9ee24,
        name: "customers-id-destination-confirm-complete___ja"
      }]
    }]
  }, {
    path: "/customers/:id/examine",
    component: _0df8580c,
    name: "customers-id-examine___ja",
    children: [{
      path: "confirm",
      component: _6582873d,
      children: [{
        path: "",
        component: _582c2800,
        name: "customers-id-examine-confirm___ja"
      }, {
        path: "complete",
        component: _798565fb,
        name: "customers-id-examine-confirm-complete___ja"
      }]
    }]
  }, {
    path: "/customers/:id/nameupdaterequest",
    component: _0ff55ce6,
    name: "customers-id-nameupdaterequest___ja",
    children: [{
      path: "confirm",
      component: _6e821217,
      name: "customers-id-nameupdaterequest-confirm___ja"
    }]
  }, {
    path: "/customers/:id/destinations/new",
    component: _378b811b,
    children: [{
      path: "",
      component: _6188f144,
      name: "customers-id-destinations-new___ja"
    }, {
      path: "complete",
      component: _9196b446,
      name: "customers-id-destinations-new-complete___ja"
    }, {
      path: "confirm",
      component: _1c419d68,
      name: "customers-id-destinations-new-confirm___ja"
    }]
  }, {
    path: "/customers/:id/number/edit",
    component: _4418f8bb,
    name: "customers-id-number-edit___ja"
  }, {
    path: "/customers/:id/requestaccounttransfer/new",
    component: _1033a55f,
    name: "customers-id-requestaccounttransfer-new___ja"
  }, {
    path: "/customers/:id/destinations/:destinationId?/edit",
    component: _d97ae9ac,
    name: "customers-id-destinations-destinationId-edit___ja",
    children: [{
      path: "confirm",
      component: _02eebc5b,
      children: [{
        path: "",
        component: _e4d55ac4,
        name: "customers-id-destinations-destinationId-edit-confirm___ja"
      }]
    }]
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
