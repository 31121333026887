<template>
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="fill-current">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M17.0007 11.1666V15.3333L18.6673 16.9999V17.8333H5.33398V16.9999L7.00065 15.3333V11.1666C7.00065 8.59992 8.35898 6.46659 10.7507 5.89992V5.33325C10.7507 4.64159 11.309 4.08325 12.0007 4.08325C12.6923 4.08325 13.2507 4.64159 13.2507 5.33325V5.89992C15.634 6.46659 17.0007 8.60825 17.0007 11.1666ZM13.6673 18.6666C13.6673 19.5833 12.9173 20.3333 12.0007 20.3333C11.0757 20.3333 10.334 19.5833 10.334 18.6666H13.6673Z" />
  </svg>
</template>

<script>
export default {
}
</script>
