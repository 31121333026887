<template>
  <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg" class="fill-current">
    <path d="M13.976 9.469a2.49 2.49 0 01-.773 1.838 2.506 2.506 0 01-1.838.763 2.54 2.54 0 01-1.849-.763 2.506 2.506 0 01-.763-1.838c0-.723.255-1.34.763-1.848a2.54 2.54 0 011.849-.764c.716 0 1.329.255 1.838.764a2.5 2.5 0 01.773 1.848zm-.482 2.21c.308 0 .583.056.824.17.248.114.449.275.603.482.16.201.294.426.401.674.107.24.188.512.241.813.054.295.09.57.11.824.028.254.041.519.041.793 0 .449-.134.847-.402 1.196a1.259 1.259 0 01-1.054.512H8.472c-.442 0-.797-.17-1.065-.512a1.94 1.94 0 01-.392-1.196c0-.321.014-.633.04-.934.034-.308.098-.64.191-.994.094-.355.215-.66.362-.914.154-.261.365-.48.633-.653.274-.174.59-.261.944-.261h.05c.047.026.154.093.322.2l.351.211c.074.034.184.09.332.171.154.074.278.127.371.16.1.028.218.058.352.091a1.994 1.994 0 00.793 0c.141-.033.258-.063.352-.09.1-.034.224-.087.372-.16l.331-.172a11.7 11.7 0 00.683-.411zm6.87-2.572a.317.317 0 01-.1.231.3.3 0 01-.22.09h-.965v1.286h.964c.087 0 .161.034.221.1.067.061.1.135.1.222v1.928a.318.318 0 01-.1.231.3.3 0 01-.22.09h-.965v1.286h.964c.087 0 .161.034.221.1.067.061.1.135.1.222v1.928a.318.318 0 01-.1.231.3.3 0 01-.22.09h-.965v2.25c0 .443-.157.821-.472 1.136a1.548 1.548 0 01-1.135.472H5.258c-.442 0-.82-.157-1.136-.472a1.548 1.548 0 01-.472-1.135V4.607c0-.442.158-.82.472-1.135A1.548 1.548 0 015.258 3h12.214c.442 0 .82.157 1.135.472.315.315.472.693.472 1.135v2.25h.964c.087 0 .161.034.221.1.067.06.1.135.1.222v1.928zm-2.57 10.286V4.607a.286.286 0 00-.101-.22.286.286 0 00-.221-.101H5.258a.318.318 0 00-.231.1.3.3 0 00-.09.221v14.786a.3.3 0 00.09.22c.066.068.143.101.23.101h12.215c.087 0 .16-.033.22-.1.068-.06.101-.134.101-.221z" />
  </svg>
</template>

<script>
export default {
}
</script>
