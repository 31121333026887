import { MutationTree, ActionTree, ActionContext } from 'vuex'
import { BankAccountState } from '~/types/registers'
import { SellerBankQuery, SellerBankQueryVariables } from '~/types/eldamar'
import query from '~/queries/sellerBank.gql'

export const state = (): BankAccountState => ({
  bankAccount: null,
})

export const mutations: MutationTree<BankAccountState> = {
  setBankAccount (state: BankAccountState, bankAccount: BankAccountState['bankAccount']): void {
    state.bankAccount = bankAccount
  },
}

export const actions: ActionTree<BankAccountState, BankAccountState> = {
  reset (ctx: ActionContext<BankAccountState, BankAccountState>): void {
    ctx.commit('setBankAccount', null)
  },

  async check (ctx: ActionContext<BankAccountState, BankAccountState>): Promise<boolean> {
    let bankAccount = ctx.state.bankAccount
    if (!bankAccount) {
      const result = await this.app.apolloProvider?.defaultClient?.query<SellerBankQuery, SellerBankQueryVariables>({
        query,
      })
      const seller = result?.data.seller
      if (!seller) {
        return false
      }
      bankAccount = seller
      ctx.commit('setBankAccount', bankAccount)
    }

    return !!(bankAccount.bankCode && bankAccount.bankBranchCode && bankAccount.accountType && bankAccount.accountNumber && bankAccount.holderName)
  },
}
