import { Plugin } from '@nuxt/types'
import { AuthQuery } from '~/types/eldamar'
import authQuery from '~/queries/auth.gql'

interface MyWindow extends Window {
  hj?: (s: 'identify', sid: string, opt?: { tenant_id: string }) => void; // eslint-disable-line camelcase
}
declare let window: MyWindow

const p: Plugin = async function (ctx) {
  if (process.client && window.hj) {
    try {
      const res = await ctx.app.apolloProvider?.defaultClient.query<AuthQuery>({
        query: authQuery,
      })

      if (!res?.data.seller?.id || !res?.data.user?.id) {
        return
      }

      window.hj('identify', `seller:${res.data.seller.id}`, {
        tenant_id: ctx.store.state.tenantId,
      })
    } catch (e) {
    }
  }
}

export default p
