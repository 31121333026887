import { MutationTree, ActionTree, ActionContext } from 'vuex'
import { CustomerExaminationRequestRegisterState } from '~/types/registers'
import { BusinessType } from '~/types/eldamar'

export const state = (): CustomerExaminationRequestRegisterState => ({
  name: '',
  userDefinedId: '',
  zipcode: '',
  prefecture: '',
  address1: '',
  tel: '',
  email: '',
  requestName: '',
  deadline: null,
})

export const mutations: MutationTree<CustomerExaminationRequestRegisterState> = {
  setName (state: CustomerExaminationRequestRegisterState, name: string): void {
    state.name = name
  },
  setUserDefinedId (state: CustomerExaminationRequestRegisterState, userDefinedId: string): void {
    state.userDefinedId = userDefinedId
  },
  setExpectedAmount (state: CustomerExaminationRequestRegisterState, expectedAmount?: number): void {
    state.expectedAmount = expectedAmount
  },
  setExpectedMonth (state: CustomerExaminationRequestRegisterState, expectedMonth?: string): void {
    state.expectedMonth = expectedMonth
  },
  setZipCode (state: CustomerExaminationRequestRegisterState, zipcode: string): void {
    state.zipcode = zipcode
  },
  setPrefecture (state: CustomerExaminationRequestRegisterState, prefecture: string): void {
    state.prefecture = prefecture
  },
  setAddress1 (state: CustomerExaminationRequestRegisterState, address1: string): void {
    state.address1 = address1
  },
  setAddress2 (state: CustomerExaminationRequestRegisterState, address2: string): void {
    state.address2 = address2
  },
  setTel (state: CustomerExaminationRequestRegisterState, tel: string): void {
    state.tel = tel
  },
  setEmail (state: CustomerExaminationRequestRegisterState, email: string): void {
    state.email = email
  },
  setBusinessType (state: CustomerExaminationRequestRegisterState, businessType?: BusinessType | ''): void {
    state.businessType = businessType
  },
  setCorporateNumber (state: CustomerExaminationRequestRegisterState, corporateNumber: string): void {
    state.corporateNumber = corporateNumber
  },
  setRepresentativeName (state: CustomerExaminationRequestRegisterState, representativeName: string): void {
    state.representativeName = representativeName
  },
  setBusinessDescription (state: CustomerExaminationRequestRegisterState, businessDescription: string): void {
    state.businessDescription = businessDescription
  },
  setUrl (state: CustomerExaminationRequestRegisterState, url: string): void {
    state.url = url
  },
  setRemark (state: CustomerExaminationRequestRegisterState, remark: string): void {
    state.remark = remark
  },
  setDeadLine (state: CustomerExaminationRequestRegisterState, deadline: Date | null): void {
    state.deadline = deadline
  },
  setRequestName (state: CustomerExaminationRequestRegisterState, requestName: string): void {
    state.requestName = requestName
  },
}

export const actions: ActionTree<CustomerExaminationRequestRegisterState, CustomerExaminationRequestRegisterState> = {
  reset (ctx: ActionContext<CustomerExaminationRequestRegisterState, CustomerExaminationRequestRegisterState>): void {
    ctx.commit('setName', '')
    ctx.commit('setUserDefinedId', '')
    ctx.commit('setExpectedAmount', undefined)
    ctx.commit('setExpectedMonth', undefined)
    ctx.commit('setZipCode', '')
    ctx.commit('setPrefecture', '')
    ctx.commit('setAddress1', '')
    ctx.commit('setAddress2', '')
    ctx.commit('setTel', '')
    ctx.commit('setEmail', '')
    ctx.commit('setBusinessType', undefined)
    ctx.commit('setCorporateNumber', '')
    ctx.commit('setRepresentativeName', '')
    ctx.commit('setBusinessDescription', '')
    ctx.commit('setUrl', '')
    ctx.commit('setRemark', '')
    ctx.commit('setDeadLine', null)
    ctx.commit('setRequestName', '')
  },
}
