
import { Component, namespace, Vue } from 'nuxt-property-decorator'
import HoverMenu from '~/components/layout/menu/hover-menu.vue'
import DeveloperIcon from '~/components/element/icon/developer-icon.vue'
import { Acl } from '~/types/eldamar'

const sellerStore = namespace('seller')

@Component({
  components: {
    DeveloperIcon,
    HoverMenu,
  },
})
export default class extends Vue {
  @sellerStore.State
  private readonly acl!: Acl | null

  get isAccept (): boolean {
    return this.acl?.developerEdit ? this.acl.developerEdit : false
  }
}
