var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('span',{staticClass:"flex justify-center items-center"},[_c('span',{staticClass:"flex items-center justify-center rounded-full",class:{
      'bg-success text-white': _vm.isSuccess,
      'bg-warning text-white': _vm.isWarning,
      'bg-progress text-white': _vm.isProgress,
      'bg-gray-2 text-text': _vm.isNeutral,
      'w-6 h-6': !_vm.small,
      'w-4 h-4': _vm.small,
    }},[(_vm.isSuccess)?_c('check-icon',{staticClass:"inline-block fill-current",class:{'iconsmall': _vm.small}}):(_vm.isWarning || _vm.isProgress || _vm.isNeutral)?_c('exclamation-icon',{staticClass:"inline-block w-10px h-10px",class:{'iconsmall': _vm.small}}):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }