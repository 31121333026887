
import { Component, Vue, namespace, Watch } from 'nuxt-property-decorator'
import Alert from '~/components/element/alert.vue'
import { Msg } from '~/types/notifications'

const noticeStore = namespace('notifications')

@Component({
  components: {
    Alert,
  },
})
export default class extends Vue {
  @noticeStore.State
  private messages!: Msg[]

  @noticeStore.State
  private instants!: Msg[]

  @noticeStore.State
  private toasts!: Msg[]

  @noticeStore.State
  private page!: boolean

  @noticeStore.State
  private forceReset!: boolean

  @noticeStore.Mutation
  private readonly setPage!: (p: boolean) => void

  @noticeStore.Mutation
  private readonly refreshMessages!: () => void

  @noticeStore.Mutation
  private readonly setForceReset!: (p: boolean) => void

  private msges: Msg[] = []

  protected mounted (): void {
    this.setAndRefresh()
  }

  private setAndRefresh (): void {
    this.msges = []
    if (this.messages && this.messages.length > 0) {
      this.messages.forEach((msg) => {
        this.msges.push(msg)
      })
      this.refreshMessages()
    }
    this.setPage(false)
  }

  @Watch('page')
  private onPageShown (): void {
    if (this.page) {
      this.setAndRefresh()
    }
  }

  @Watch('forceReset')
  private onForceReset (): void {
    if (this.forceReset) {
      this.msges = []
      this.refreshMessages()
      this.setForceReset(false)
    }
  }
}
