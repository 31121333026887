import { TaxRateTypeOption, TransactionCSVKey, CustomerCSVKey } from '~/types/registers'

export const TaxRateTypes: TaxRateTypeOption[] = [
  {
    id: 1,
    type: 'TaxRateType10',
  },
  {
    id: 2,
    type: 'TaxRateTypeReduced8',
  },
  {
    id: 3,
    type: 'TaxRateTypeTransitionalMeasures8',
  },
  {
    id: 4,
    type: 'TaxRateType8',
  },
  {
    id: 5,
    type: 'TaxRateTypeFree',
  },
  {
    id: 6,
    type: 'TaxRateTypeNotApplicable',
  },
]

export const TransactionCSVHeaders: {[key in TransactionCSVKey]: string} = {
  number: '取引番号',
  date: '取引日',
  dueDate: '支払期限',
  customerNumber: '顧客番号',
  customerName: '顧客企業名',
  tel: '顧客電話番号',
  zipCode: '送付先郵便番号',
  prefecture: '送付先都道府県',
  address1: '送付先住所1',
  address2: '送付先住所2',
  department: '送付先部署名',
  title: '送付先担当者肩書',
  contactName: '送付先担当者名',
  contactNameKana: '送付先担当者名（カナ）',
  issueDate: '請求書発行日',
  sending: 'メール送付',
  posting: '郵送',
  email: '送付先メールアドレス1[To]',
  ccEmail1: '送付先メールアドレス2[Cc]',
  ccEmail2: '送付先メールアドレス3[Cc]',
  ccEmail3: '送付先メールアドレス4[Cc]',
  ccEmail4: '送付先メールアドレス5[Cc]',
  description: '明細',
  unitPrice: '単価',
  quantity: '数量',
  amount: '金額',
  taxRateType: '消費税率',
}

export const CSVPreviewHeaders = {
  number: {
    name: TransactionCSVHeaders.number,
    required: true,
    size: 'w-40',
  },
  date: {
    name: TransactionCSVHeaders.date,
    required: true,
    size: 'w-28',
  },
  dueDate: {
    name: TransactionCSVHeaders.dueDate,
    required: true,
    size: 'w-28',
  },
  customerNumber: {
    name: TransactionCSVHeaders.customerNumber,
    required: true,
    size: 'w-40',
  },
  customerName: {
    name: TransactionCSVHeaders.customerName,
    required: true,
    size: 'w-40',
  },
  tel: {
    name: TransactionCSVHeaders.tel,
    required: true,
    size: 'w-32',
  },
  zipCode: {
    name: TransactionCSVHeaders.zipCode,
    required: true,
    size: 'w-40',
  },
  prefecture: {
    name: TransactionCSVHeaders.prefecture,
    required: true,
    size: 'w-40',
  },
  address1: {
    name: TransactionCSVHeaders.address1,
    required: true,
    size: 'w-64',
  },
  address2: {
    name: TransactionCSVHeaders.address2,
    required: false,
    size: 'w-64',
  },
  department: {
    name: TransactionCSVHeaders.department,
    required: false,
    size: 'w-32',
  },
  title: {
    name: TransactionCSVHeaders.title,
    required: false,
    size: 'w-32',
  },
  contactName: {
    name: TransactionCSVHeaders.contactName,
    required: false,
    size: 'w-40',
  },
  contactNameKana: {
    name: TransactionCSVHeaders.contactNameKana,
    required: false,
    size: 'w-48',
  },
  issueDate: {
    name: TransactionCSVHeaders.issueDate,
    required: true,
    size: 'w-32',
  },
  sending: {
    name: TransactionCSVHeaders.sending,
    required: true,
    size: 'w-32',
  },
  posting: {
    name: TransactionCSVHeaders.posting,
    required: true,
    size: 'w-20',
  },
  email: {
    name: TransactionCSVHeaders.email,
    required: true,
    size: 'w-56',
  },
  ccEmail1: {
    name: TransactionCSVHeaders.ccEmail1,
    required: false,
    size: 'w-56',
  },
  ccEmail2: {
    name: TransactionCSVHeaders.ccEmail2,
    required: false,
    size: 'w-56',
  },
  ccEmail3: {
    name: TransactionCSVHeaders.ccEmail3,
    required: false,
    size: 'w-56',
  },
  ccEmail4: {
    name: TransactionCSVHeaders.ccEmail4,
    required: false,
    size: 'w-56',
  },
  description: {
    name: TransactionCSVHeaders.description,
    required: true,
    size: 'w-40',
  },
  unitPrice: {
    name: TransactionCSVHeaders.unitPrice,
    required: true,
    size: 'w-28',
  },
  quantity: {
    name: TransactionCSVHeaders.quantity,
    required: true,
    size: 'w-28',
  },
  amount: {
    name: TransactionCSVHeaders.amount,
    required: true,
    size: 'w-40',
  },
  taxRateType: {
    name: TransactionCSVHeaders.taxRateType,
    required: true,
    size: 'w-28',
  },
} as const

export const CustomerCSVHeaders: {[key in CustomerCSVKey]: string} = {
  number: '顧客番号',
  name: '顧客名',
  tel: '電話番号',
  zipCode: '郵便番号',
  prefecture: '都道府県',
  address1: '住所1',
  address2: '住所2',
  department: '部署名',
  title: '担当者肩書',
  contactName: '担当者名',
  contactNameKana: '担当者名（カナ）',
  email: 'メールアドレス',
  ccEmail1: 'CCメールアドレス1',
  ccEmail2: 'CCメールアドレス2',
  ccEmail3: 'CCメールアドレス3',
  ccEmail4: 'CCメールアドレス4',
  businessType: '事業所区分',
  corporateNumber: '法人番号',
  representativeName: '代表者名',
  businessDescription: '顧客の主なサービス、商材',
  url: '顧客Webサイト',
  expectedAmount: '希望与信額',
  initialExpectedAmount: '初月希望与信額',
  remark: 'その他情報',
  applyAccountTransferApplication: '口振依頼書送付',
}

export const CustomerCSVPreviewHeaders = {
  number: {
    name: CustomerCSVHeaders.number,
    required: true,
    size: 'w-40',
  },
  name: {
    name: CustomerCSVHeaders.name,
    required: true,
    size: 'w-40',
  },
  tel: {
    name: CustomerCSVHeaders.tel,
    required: true,
    size: 'w-32',
  },
  zipCode: {
    name: CustomerCSVHeaders.zipCode,
    required: true,
    size: 'w-40',
  },
  prefecture: {
    name: CustomerCSVHeaders.prefecture,
    required: true,
    size: 'w-40',
  },
  address1: {
    name: CustomerCSVHeaders.address1,
    required: true,
    size: 'w-64',
  },
  address2: {
    name: CustomerCSVHeaders.address2,
    required: false,
    size: 'w-64',
  },
  department: {
    name: CustomerCSVHeaders.department,
    required: false,
    size: 'w-32',
  },
  title: {
    name: CustomerCSVHeaders.title,
    required: false,
    size: 'w-32',
  },
  contactName: {
    name: CustomerCSVHeaders.contactName,
    required: false,
    size: 'w-40',
  },
  contactNameKana: {
    name: CustomerCSVHeaders.contactNameKana,
    required: false,
    size: 'w-48',
  },
  email: {
    name: CustomerCSVHeaders.email,
    required: true,
    size: 'w-56',
  },
  ccEmail1: {
    name: CustomerCSVHeaders.ccEmail1,
    required: false,
    size: 'w-56',
  },
  ccEmail2: {
    name: CustomerCSVHeaders.ccEmail2,
    required: false,
    size: 'w-56',
  },
  ccEmail3: {
    name: CustomerCSVHeaders.ccEmail3,
    required: false,
    size: 'w-56',
  },
  ccEmail4: {
    name: CustomerCSVHeaders.ccEmail4,
    required: false,
    size: 'w-56',
  },
  businessType: {
    name: CustomerCSVHeaders.businessType,
    required: false,
    size: 'w-32',
  },
  corporateNumber: {
    name: CustomerCSVHeaders.corporateNumber,
    required: false,
    size: 'w-40',
  },
  representativeName: {
    name: CustomerCSVHeaders.representativeName,
    required: false,
    size: 'w-40',
  },
  businessDescription: {
    name: CustomerCSVHeaders.businessDescription,
    required: false,
    size: 'w-56',
  },
  url: {
    name: CustomerCSVHeaders.url,
    required: false,
    size: 'w-40',
  },
  expectedAmount: {
    name: CustomerCSVHeaders.expectedAmount,
    required: false,
    size: 'w-40',
  },
  initialExpectedAmount: {
    name: CustomerCSVHeaders.initialExpectedAmount,
    required: false,
    size: 'w-32',
  },
  remark: {
    name: CustomerCSVHeaders.remark,
    required: false,
    size: 'w-56',
  },
  applyAccountTransferApplication: {
    name: CustomerCSVHeaders.applyAccountTransferApplication,
    required: false,
    size: 'w-32',
  },
}
