
import { Component, Emit, Prop, Vue } from 'nuxt-property-decorator'

@Component({
  props: {
    type: String,
  },
})
export default class extends Vue {
  @Prop({ default: false })
  private readonly disabled!: boolean

  @Prop({ default: false })
  private readonly permanentDisabled!: boolean

  @Prop({ default: false })
  private readonly loading!: boolean

  @Prop({ default: 'min-w-primary-btn' })
  private readonly styles!: string

  get bgColor (): string {
    const classes: string[] = ['primary-btn-color']
    if (this.permanentDisabled) {
      classes.pop()
      classes.push('bg-gray-3')
    }
    if (!this.disabled) {
      classes.push('hover:primary-btn-color')
    }
    return classes.join(' ')
  }

  @Emit('click')
  private onClick (e: Event): Event {
    return e
  }
}
