
import { Component, Prop, Vue } from 'nuxt-property-decorator'
import { NuxtError } from '@nuxt/types/app'

@Component
export default class extends Vue {
  @Prop()
  private readonly error?: NuxtError

  get supportCenterUrl (): string {
    return this.$supportLink('topPage')
  }

  get errorCode (): number {
    if (this.error) {
      return this.error.statusCode ? this.error.statusCode : 500
    }
    return 500
  }

  get title (): string {
    if (this.is404) {
      return 'お探しのページは見つかりませんでした'
    }
    if (this.isNetworkError) {
      return 'ネットワークに問題があります'
    }
    return 'サーバエラーが発生しました'
  }

  get is404 (): boolean {
    return this.errorCode === 404
  }

  get isNetworkError (): boolean {
    return this.errorCode === -1
  }

  get isDefault (): boolean {
    return this.$nuxt.$data.layoutName === 'default'
  }
}
