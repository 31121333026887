import Vue from 'vue'
import merge from '~lodash.mergewith'
import * as Sentry from '~@sentry/vue'

import { ExtraErrorData, ReportingObserver, RewriteFrames } from '~@sentry/integrations'
import getCustomIntegrations from '/workspace/plugins/sentry-client-integrations'

export default async function (ctx, inject) {
  /* eslint-disable object-curly-spacing, quote-props, quotes, key-spacing, comma-spacing */
  const config = {
    Vue,
    release:undefined,
    dsn:"https:\u002F\u002F7cfb2fa9cace42b09650f201ae6e17ed@sentry.io\u002F5114515",
    environment:"production",
    ignoreErrors:[new RegExp("Non-Error promise rejection captured with value:*", ""),"FirebaseError: Firebase: Error (auth\u002Fnetwork-request-failed).","Firebase: Error (auth\u002Fargument-error).","Firebase: Error (auth\u002Finternal-error)."],
    replaysOnErrorSampleRate:1,
  }

  const runtimeConfigKey = "sentry"
  if (ctx.$config && runtimeConfigKey && ctx.$config[runtimeConfigKey]) {
    merge(config, ctx.$config[runtimeConfigKey].config, ctx.$config[runtimeConfigKey].clientConfig)
  }

  config.integrations = [
    new ExtraErrorData(),
    new ReportingObserver({ types:["crash"] }),
    new RewriteFrames(),
  ]

  const customIntegrations = await getCustomIntegrations(ctx)
  if (Array.isArray(customIntegrations)) {
    config.integrations.push(...customIntegrations)
  } else {
    console.error(`[@nuxtjs/sentry] Invalid value returned from customClientIntegrations plugin. Expected an array, got "${typeof customIntegrations}".`)
  }

  /* eslint-enable object-curly-spacing, quote-props, quotes, key-spacing, comma-spacing */
  Sentry.init(config)

  inject('sentry', Sentry)
  ctx.$sentry = Sentry
}
