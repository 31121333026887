import { render, staticRenderFns } from "./developers-menu.vue?vue&type=template&id=90faff0a&scoped=true&"
import script from "./developers-menu.vue?vue&type=script&lang=ts&"
export * from "./developers-menu.vue?vue&type=script&lang=ts&"
import style0 from "./developers-menu.vue?vue&type=style&index=0&id=90faff0a&prod&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "90faff0a",
  null
  
)

export default component.exports