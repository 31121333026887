import { Context } from '@nuxt/types'
import { MutationTree, ActionTree, ActionContext } from 'vuex'
import { isApolloError } from 'apollo-client'
import { FactorId, getAuth, multiFactor, reload, User } from 'firebase/auth'
import cookie from 'cookie'
import { addMonths } from 'date-fns'
import { RootState } from '~/types'
import { BrandQuery, BrandQueryVariables, ServiceProvider } from '~/types/eldamar'

import brandQuery from '~/queries/brand.gql'
import { emitError } from '~/utils/nuxt-helper'

export const state = (): RootState => ({
  isMenuOpened: false,
  isMenuCollapsed: false,
  isForceMenuCollapsed: false,
  logo: '/logo_mfk_v.svg',
  redirectFrom: '',
  domain: '',
  serviceProvider: {
    type: 'mfk',
    companyName: '',
    sellerSupportEmail: '',
  },
  fireUser: null,
  userEmail: '',
})

export const mutations: MutationTree<RootState> = {
  setSubdomain (state: RootState, subdomain: string): void {
    state.subdomain = subdomain
  },
  setCss (state: RootState, cssPath: string): void {
    state.css = cssPath
  },
  setFavicon (state: RootState, fav: string): void {
    state.favicon = fav
  },
  setLogo (state: RootState, logo: string): void {
    state.logo = logo
  },
  setMenuOpened (state: RootState, isOpened: boolean): void {
    state.isMenuOpened = isOpened
  },
  setMenuCollapsed (state: RootState, collapsed: boolean): void {
    state.isMenuCollapsed = collapsed
  },
  setForceMenuCollapsed (state: RootState, forced: boolean): void {
    state.isForceMenuCollapsed = forced
  },
  setBrandId (state: RootState, brandId: string): void {
    state.brandId = brandId
  },
  setTenantId (state: RootState, tenantId: string): void {
    state.tenantId = tenantId
  },
  setServiceName (state: RootState, serviceName: string): void {
    state.serviceName = serviceName
  },
  setServiceNameWithKana (state: RootState, serviceNameWithKana: string): void {
    state.serviceNameWithKana = serviceNameWithKana
  },
  setRedirectFrom (state: RootState, path: string): void {
    state.redirectFrom = path
  },
  setDomain (state: RootState, domain: string): void {
    state.domain = domain
  },
  setServiceProvider (state: RootState, sp: ServiceProvider): void {
    state.serviceProvider.type = sp.domain.match('mfk') ? 'mfk' : 'bfw'
    state.serviceProvider.companyName = sp.companyName
    state.serviceProvider.sellerSupportEmail = sp.sellerSupportEmail
  },
  setFireUser (state: RootState, fu: User | null): void {
    if (!fu) {
      state.fireUser = null
      return
    }
    const mu = multiFactor(fu)
    state.fireUser = {
      email: fu.email ? fu.email : '',
      emailVerified: fu.emailVerified,
      enabledMultiFactor: !!mu.enrolledFactors.find(v => v.factorId === FactorId.PHONE),
    }
  },
  setUserEmail (state: RootState, email: string): void {
    state.userEmail = email
  },
}

export const actions: ActionTree<RootState, RootState> = {
  async nuxtServerInit (actionContext: ActionContext<RootState, RootState>, nuxtContext: Context) {
    const c = nuxtContext.req.headers.cookie
    if (c) {
      const cookies = cookie.parse(c)
      const menuCollapsedStatus = cookies['menu-collapsed']
      if (menuCollapsedStatus) {
        actionContext.commit('setMenuCollapsed', menuCollapsedStatus === 'collapsed')
        actionContext.commit('setForceMenuCollapsed', !!menuCollapsedStatus)

        // 以下cookie期限更新
        const resCookie = nuxtContext.res.getHeader('Set-Cookie') as string[]
        const d = addMonths(new Date(), 1)
        resCookie.push(cookie.serialize('menu-collapsed', menuCollapsedStatus, { path: '/', expires: d, secure: true }))
        nuxtContext.res.setHeader('Set-Cookie', resCookie)
      }
    }

    const host = nuxtContext.req.headers.host
    if (!host) {
      console.error('no host')
      return
    }
    const [fqdn] = host.split(':') // strip the port number

    try {
      const result = await nuxtContext.app.apolloProvider?.defaultClient.query<BrandQuery, BrandQueryVariables>({
        query: brandQuery,
        variables: { fqdn },
      })
      if (!result || result.errors) {
        console.error(result)
        // FIXME sentry
        return
      }

      if (!result.data.brand) {
        console.error('brand not found')
        return
      }

      if (result.data.brand.subdomain) { // 以下のフィールドはMFKブランドでは初期値のままで動かすので設定不要
        actionContext.commit('setSubdomain', result.data.brand.subdomain)
        actionContext.commit('setFavicon', `https://${nuxtContext.app.$env.STATIC_URL}/brands/${result.data.brand.hashId}/favicon.ico`)
        actionContext.commit('setLogo', `https://${nuxtContext.app.$env.STATIC_URL}/brands/${result.data.brand.hashId}/logo.svg`)
        actionContext.commit('setCss', `/${result.data.brand.subdomain}/${result.data.brand.subdomain}.css`)
      }

      if (result.data.brand.serviceProvider?.domain) {
        actionContext.commit('setServiceProvider', result.data.brand.serviceProvider)
      }

      actionContext.commit('setDomain', host)
      actionContext.commit('setBrandId', result.data.brand.hashId)
      actionContext.commit('setTenantId', result.data.brand.tenantId)
      actionContext.commit('setServiceName', result.data.brand.serviceName)
      actionContext.commit('setServiceNameWithKana', result.data.brand.serviceName)
    } catch (e) {
      const err = e as Error
      if (isApolloError(err) && err.graphQLErrors) {
        err.graphQLErrors.forEach((ge) => {
          if (!ge.extensions || !ge.extensions.code) {
            emitError(nuxtContext, err)
            return
          }
          switch (ge.extensions.code) {
            case 'not_found':
              // 不正なsubdomainの場合は404画面に遷移させる
              nuxtContext.error({ statusCode: 404, message: 'subdomain not found' })
              return
            default:
              emitError(nuxtContext, err)
          }
        })
      } else {
        emitError(nuxtContext, err)
      }
    }
  },
  toggleMenu ({ state, commit }) {
    commit('setMenuOpened', !state.isMenuOpened)
  },
  closeMenu ({ commit }) {
    commit('setMenuOpened', false)
  },
  setCookie (_, kv: {k: string, v: string}) {
    const d = addMonths(new Date(), 1)
    document.cookie = cookie.serialize(kv.k, kv.v, { path: '/', expires: d, secure: true })
  },
  async reloadFireUser ({ commit }) {
    const auth = getAuth()
    if (auth.currentUser) {
      await reload(auth.currentUser)
      commit('setFireUser', auth.currentUser)
    }
  },
} as ActionTree<RootState, RootState>
