import { MutationTree, ActionTree, ActionContext, GetterTree } from 'vuex'
import { TransactionState, RootState } from '~/types'
import { TaxDetail } from '~/types/getters'
import { Transaction, TaxRateType, TaxIncludedTransactionAmount } from '~/types/eldamar'

export const state = (): TransactionState => ({
  transaction: null,
})

export const getters: GetterTree<TransactionState, RootState> = {
  taxDetails (state: TransactionState): TaxDetail[] {
    if (!state.transaction?.taxIncludedTransactionAmounts) {
      return []
    }
    return state.transaction.taxIncludedTransactionAmounts
      .filter((tita): tita is TaxIncludedTransactionAmount & {taxRateType: TaxRateType } => {
        return tita.taxRateType !== null && tita.taxRateType !== undefined
      })
      .map<TaxDetail>((tita) => {
        const ta = state.transaction?.transactionTaxAmountPerTaxRates?.find(ta => ta.taxRateType === tita.taxRateType)
        return {
          taxRateType: tita.taxRateType,
          taxIncludedAmount: tita.taxIncludedAmount,
          taxAmount: ta ? ta.taxAmount : null,
        }
      })
  },
}

export const mutations: MutationTree<TransactionState> = {
  setTransaction (state: TransactionState, transaction: Transaction | null): void {
    state.transaction = transaction
  },
}

export const actions: ActionTree<TransactionState, TransactionState> = {
  reset (ctx: ActionContext<TransactionState, TransactionState>): void {
    ctx.commit('setTransaction', null)
  },
}
