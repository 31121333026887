<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="fill-current"
  >
    <path fill-rule="evenodd" clip-rule="evenodd" d="M13.8067 3.75305C14.0667 4.01305 14.0667 4.43305 13.8067 4.69305L12.5867 5.91305L10.0867 3.41305L11.3067 2.19305C11.5667 1.93305 11.9867 1.93305 12.2467 2.19305L13.8067 3.75305ZM2 13.9997V11.4997L9.37333 4.12638L11.8733 6.62638L4.5 13.9997H2Z" />
  </svg>
</template>

<script>
export default {
}
</script>
