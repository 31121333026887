import Vue from 'vue'
import { MutationTree, ActionTree, ActionContext } from 'vuex'
import { CSVRegisterState, ColumnSample, CSVEncodingType } from '~/types/registers'

export const state = (): CSVRegisterState => ({
  csv: null,
  encoding: 'shift_jis',
})

export const mutations: MutationTree<CSVRegisterState> = {
  setFilename (state: CSVRegisterState, filename?: string): void {
    state.filename = filename
  },
  setCSV (state: CSVRegisterState, file: File | null): void {
    state.csv = file
  },
  setColumnSamples (state: CSVRegisterState, samples?: ColumnSample[]): void {
    Vue.set(state, 'columnSamples', samples)
  },
  setEncoding (state: CSVRegisterState, encoding: CSVEncodingType): void {
    state.encoding = encoding
  },
  setLines (state: CSVRegisterState, lines?: number): void {
    state.lines = lines
  },
}

export const actions: ActionTree<CSVRegisterState, CSVRegisterState> = {
  reset (ctx: ActionContext<CSVRegisterState, CSVRegisterState>): void {
    ctx.commit('setFilename', undefined)
    ctx.commit('setCSV', null)
    ctx.commit('setColumnSamples', undefined)
    ctx.commit('setLines', undefined)
  },
}
