
import { createPopper, Instance } from '@popperjs/core'
import { Component, Emit, Prop, Vue } from 'nuxt-property-decorator'
import { isHTMLObject } from '~/utils/vue-helper'

@Component({
  props: {
    to: {
      type: String,
      required: true,
    },
    exact: {
      type: Boolean,
      default: false,
    },
    append: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    isAnchor: {
      type: Boolean,
      default: false,
    },
  },
})
export default class extends Vue {
  @Prop({ default: '' })
  private readonly prefix!: string

  @Prop({ default: false })
  private readonly collapse!: boolean

  @Prop({ default: true })
  private readonly canActive!: boolean

  @Prop({ default: false })
  private readonly isAnchor!: boolean

  private isActive = false
  private instance: Instance | null = null

  get activeClass (): string {
    if (!this.canActive) {
      return ''
    }

    const classes = 'text-white font-medium bg-menu-hover border-l-4 border-l-secondary'
    if (this.collapse) {
      return `${classes} active-side-space-collapsed`
    }
    return `${classes} active-side-space`
  }

  private openPopper (): void {
    if (!this.collapse) {
      return
    }

    this.isActive = true
    this.$nextTick(() => {
      const refs = this.$refs[`${this.prefix}-target`]
      const ref = Array.isArray(refs) ? refs[0] : refs
      const pops = this.$refs[`${this.prefix}-popover`]
      const pop = Array.isArray(pops) ? pops[0] : pops
      if (ref && isHTMLObject(ref) && pop && isHTMLObject(pop)) {
        this.instance = createPopper(ref, pop, {
          placement: 'right',
        })
      }
    })
  }

  private closePopper (): void {
    this.isActive = false
    if (this.instance) {
      this.instance.destroy()
      this.instance = null
    }
  }

  @Emit('click-native')
  private onClickNative (e: Event): Event {
    return e
  }
}
