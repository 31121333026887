import { initializeApp, getApps } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { Plugin } from '@nuxt/types'
import { SignoutMutation, SignoutMutationVariables, VerifySessionMutation, VerifySessionMutationVariables } from '~/types/eldamar'
import verifySession from '~/queries/verifySession.gql'
import signout from '~/queries/signout.gql'
import { emitError, isFirebaseError } from '~/utils/nuxt-helper'

const func: Plugin = (ctx, inject) => {
  const app = ctx.app
  let fireapp
  if (!getApps.length) {
    // noinspection SpellCheckingInspection
    fireapp = initializeApp({
      apiKey: app.$env.FB_API_KEY,
      authDomain: `${app.$env.FB_PROJECT_ID}.firebaseapp.com`,
      storageBucket: `${app.$env.FB_PROJECT_ID}.appspot.com`,
    })
  }
  const auth = getAuth()
  if (ctx.store.state.tenantId) {
    auth.tenantId = ctx.store.state.tenantId
  }
  auth.useDeviceLanguage()
  auth.onAuthStateChanged(
    async (user) => {
      // firebase authentication上で未認証でも一般的な操作は許容、firebaseの認証情報が必要な場面では利用側で都度確認する
      if (!user) {
        return
      }
      app.store?.commit('setFireUser', user)

      try {
        const idToken = await user.getIdToken()
        const res = await ctx.app.apolloProvider?.defaultClient.mutate<VerifySessionMutation, VerifySessionMutationVariables>({
          mutation: verifySession,
          variables: {
            idToken,
          },
        })
        const result = res?.data?.verifySession
        if (!result) {
          throw new Error('verifySession query failed')
        }

        switch (result?.type) {
          case 'Valid':
          case 'NoSession':
            // セッションがない場合は操作時にサインアウト画面にリダイレクトされるのでここでは何もしない
            return
          case 'Inconsistent':
          case 'InvalidIdToken':
            // eldamarのセッションと整合しないIDTokenだった場合はサインアウトさせる
            await ctx.app.apolloProvider?.defaultClient.mutate<SignoutMutation, SignoutMutationVariables>({
              mutation: signout,
              variables: {
                tenantId: result.tenantId,
              },
            })
            return
          default:
            throw new Error(`unknown verify result ${result}`)
        }
      } catch (e) {
        emitError(ctx, e)
      }
    },

    (err) => {
      if (isFirebaseError(err)) {
        emitError(ctx, new Error(`Firebase Authentication error ${err.code}: ${err.message}`))
      }
    },
  )

  inject('firebase', fireapp)
}

export default func
