import { render, staticRenderFns } from "./btn.vue?vue&type=template&id=4cc72884&scoped=true&"
import script from "./btn.vue?vue&type=script&lang=ts&"
export * from "./btn.vue?vue&type=script&lang=ts&"
import style0 from "./btn.vue?vue&type=style&index=0&id=4cc72884&prod&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4cc72884",
  null
  
)

export default component.exports