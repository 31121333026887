
import { Component, Vue } from 'nuxt-property-decorator'

@Component({
  props: {
    isRounded: {
      type: Boolean,
      default: false,
    },
  },
})
export default class SectionTitle extends Vue {
}
