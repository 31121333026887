import { MutationTree, ActionTree, ActionContext } from 'vuex'
import { Customer } from '~/types/eldamar'
import { AuthorizationRegisterState } from '~/types/registers'

export const state = (): AuthorizationRegisterState => ({
  customer: null,
  authorizationNumber: '',
  amount: '',
  expirationDate: '',
})

export const mutations: MutationTree<AuthorizationRegisterState> = {
  setCustomer (state: AuthorizationRegisterState, c: Customer | null): void {
    state.customer = c
  },
  setAuthorizationNumber (state: AuthorizationRegisterState, n:string): void {
    state.authorizationNumber = n
  },
  setAmount (state: AuthorizationRegisterState, a: string): void {
    state.amount = a
  },
  setExpirationDate (state: AuthorizationRegisterState, e: string): void {
    state.expirationDate = e
  },
}

export const actions: ActionTree<AuthorizationRegisterState, AuthorizationRegisterState> = {
  reset (ctx: ActionContext<AuthorizationRegisterState, AuthorizationRegisterState>): void {
    ctx.commit('setCustomer', null)
    ctx.commit('setAuthorizationNumber', '')
    ctx.commit('setAmount', '')
    ctx.commit('setExpirationDate', '')
  },
}
