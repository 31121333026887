import Vue from 'vue'
import { Validation } from 'vuelidate'

export function isHTMLInputObject (ref: EventTarget | Vue | Element | (Vue |Element)[] | undefined): ref is HTMLInputElement {
  if (!ref) {
    return false
  }
  return (ref as HTMLInputElement).value !== undefined
}

export function isVueObject (ref: Vue | Element | (Vue | Element)[] | undefined): ref is Vue {
  if (!ref) {
    return false
  }
  return (ref as Vue).$emit !== undefined
}

export function isVNode (ref: Vue.VNode | undefined): ref is Vue.VNode {
  if (!ref) {
    return false
  }
  return (ref as Vue.VNode).elm !== undefined
}

export function isHTMLObject (ref: Vue | Element | (Vue | Element)[] | Node | undefined): ref is HTMLElement {
  if (!ref) {
    return false
  }
  return (ref as HTMLElement).clientHeight !== undefined
}

interface ValidationEvaluation {
  [ruleName: string]: boolean;
}

export interface ValidationGroups {
  [groupName: string]: Validation & ValidationEvaluation;
}
