import { MutationTree, ActionTree, ActionContext } from 'vuex'
import { NotificationState, Msg } from '~/types/notifications'

export const state = (): NotificationState => ({
  messages: [],
  instants: [],
  toasts: [],
  page: false,
  forceReset: false,
})

export const mutations: MutationTree<NotificationState> = {
  setMessage (state: NotificationState, msg: Msg): void {
    state.messages.push(msg)
  },
  setInstant (state: NotificationState, msg: Msg): void {
    state.instants.push(msg)
  },
  addToast (state: NotificationState, msg: Msg): void {
    state.toasts.push(msg)
  },
  shiftToast (state: NotificationState): void {
    state.toasts.shift()
  },
  setPage (state: NotificationState, page: boolean): void {
    state.page = page
  },
  refreshMessages (state: NotificationState): void {
    state.messages = []
  },
  refreshInstants (state: NotificationState): void {
    state.instants = []
  },
  setForceReset (state: NotificationState, force: boolean): void {
    state.forceReset = force
  },
}

export const actions: ActionTree<NotificationState, NotificationState> = {
  setToast (ctx: ActionContext<NotificationState, NotificationState>, msg: Msg) {
    ctx.commit('addToast', msg)
    setTimeout(() => {
      ctx.commit('shiftToast')
    }, 2000)
  },
}
