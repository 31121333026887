import { MutationTree, ActionTree, ActionContext } from 'vuex'
import { TourBankState } from '~/types/registers'
import { SellerBankAccountInput, BankBranch, Bank } from '~/types/eldamar'

export const state = (): TourBankState => ({
  bank: null,
  bankBranch: null,
  bankAccountInput: null,
})

export const mutations: MutationTree<TourBankState> = {
  setBank (state: TourBankState, bank: Bank | null): void {
    state.bank = bank
  },
  setBankBranch (state: TourBankState, bankBranch: BankBranch | null): void {
    state.bankBranch = bankBranch
  },
  setBankAccountInput (state: TourBankState, bankAccountInput: SellerBankAccountInput | null): void {
    state.bankAccountInput = bankAccountInput
  },
}

export const actions: ActionTree<TourBankState, TourBankState> = {
  reset (ctx: ActionContext<TourBankState, TourBankState>): void {
    ctx.commit('setBankAccountInput', null)
  },
}
