import Vue, { VueConstructor } from 'vue'
import ElfLink from '~/components/element/elf-link.vue'
import Btn from '~/components/element/btn.vue'
import PrimaryBtn from '~/components/element/primary-btn.vue'
import BasicBtnLg from '~/components/element/basic-btn-lg.vue'
import Alert from '~/components/element/alert.vue'

const components: {[key: string]: VueConstructor} = { ElfLink, Btn, Alert, PrimaryBtn, BasicBtnLg }

Object.keys(components).forEach((key) => {
  Vue.component(key, components[key])
})
