/* eslint-disable require-await */
// 本実装のときは取っ払う予定のeslint-disableくんです
import { Big } from 'big.js'
import { TransactionPerClaimUnitDetailInput } from '~/types/claimunit-input-interfaces'
import { ClaimUnit, TaxRoundingType } from '~/types/eldamar'
import { TransactionStoreInput, TransactionsTaxPerTaxRateTypeInput } from '~/types/registers'

// 0 roundDown, 1 roundHalfUp, 2 roundHalfEven, 3 roundUp
function getTaxRoundingModeForBigjs (taxRoundingType?: TaxRoundingType): 0 | 1 | 3 {
  switch (taxRoundingType) {
    case 'Floor':
      return 0
    case 'Round':
      return 1
    case 'Ceil':
    default:
      return 3
  }
}

export async function calculateDetail (unit: string, quantity: string, taxRoundingType?: TaxRoundingType): Promise<string> {
  try {
    const u = new Big(unit || 0)
    const q = new Big(quantity || 0)
    const target = u.mul(q)

    const mode = getTaxRoundingModeForBigjs(taxRoundingType)
    return target.round(4, mode).toString()
  } catch (e) {
    console.log(e)
    return '0'
  }
}

interface TransactionAmountBreakdown {
  amount: string
  taxRateTypeUnknown?: string
  taxRateTypeFree?: string
  taxRateType8: string
  taxRateType10: string
  taxRateTypeReduced8: string
  taxRateTypeTransitionalMeasures8: string
  taxRateTypeNotApplicable?: string
}

export async function calculateTransaction (details?: TransactionPerClaimUnitDetailInput[], taxRoundingType?: TaxRoundingType, taxIncluded?: boolean | null): Promise<TransactionAmountBreakdown> {
  const mode = getTaxRoundingModeForBigjs(taxRoundingType)

  let taxRateTypeUnknown = new Big(0)
  let taxRateTypeFree = new Big(0)
  let taxRateType8 = new Big(0)
  let taxRateType10 = new Big(0)
  let taxRateTypeReduced8 = new Big(0)
  let taxRateTypeTransitionalMeasures8 = new Big(0)
  let taxRateTypeNotApplicable = new Big(0)

  if (details) {
    for (const key in details) {
      const d = details[key]
      if (d.amount === '') {
        continue
      }

      switch (d.taxRateType.type) {
        case 'TaxRateTypeUnknown':
          taxRateTypeUnknown = taxRateTypeUnknown.add(d.amount)
          break
        case 'TaxRateTypeFree':
          taxRateTypeFree = taxRateTypeFree.add(d.amount)
          break
        case 'TaxRateType8':
          taxRateType8 = taxRateType8.add(d.amount)
          break
        case 'TaxRateType10':
          taxRateType10 = taxRateType10.add(d.amount)
          break
        case 'TaxRateTypeReduced8':
          taxRateTypeReduced8 = taxRateTypeReduced8.add(d.amount)
          break
        case 'TaxRateTypeTransitionalMeasures8':
          taxRateTypeTransitionalMeasures8 = taxRateTypeTransitionalMeasures8.add(d.amount)
          break
        case 'TaxRateTypeNotApplicable':
          taxRateTypeNotApplicable = taxRateTypeNotApplicable.add(d.amount)
          break
      }
    }
  }

  if (!taxIncluded) {
    taxRateType8 = taxRateType8.mul('1.08').round(0, mode)
    taxRateType10 = taxRateType10.mul('1.1').round(0, mode)
    taxRateTypeReduced8 = taxRateTypeReduced8.mul('1.08').round(0, mode)
    taxRateTypeTransitionalMeasures8 = taxRateTypeTransitionalMeasures8.mul('1.08').round(0, mode)
  } else {
    taxRateType8 = taxRateType8.round(0, mode)
    taxRateType10 = taxRateType10.round(0, mode)
    taxRateTypeReduced8 = taxRateTypeReduced8.round(0, mode)
    taxRateTypeTransitionalMeasures8 = taxRateTypeTransitionalMeasures8.round(0, mode)
  }

  taxRateTypeUnknown = taxRateTypeUnknown.round(0, mode)
  taxRateTypeNotApplicable = taxRateTypeNotApplicable.round(0, mode)
  taxRateTypeFree = taxRateTypeFree.round(0, mode)

  let amount = new Big(0)
  amount = amount.add(taxRateTypeUnknown)
  amount = amount.add(taxRateTypeFree)
  amount = amount.add(taxRateType8)
  amount = amount.add(taxRateType10)
  amount = amount.add(taxRateTypeReduced8)
  amount = amount.add(taxRateTypeTransitionalMeasures8)
  amount = amount.add(taxRateTypeNotApplicable)

  return {
    amount: amount.toString(),
    taxRateTypeUnknown: taxRateTypeUnknown.toString(),
    taxRateTypeFree: taxRateTypeFree.toString(),
    taxRateType8: taxRateType8.toString(),
    taxRateType10: taxRateType10.toString(),
    taxRateTypeReduced8: taxRateTypeReduced8.toString(),
    taxRateTypeTransitionalMeasures8: taxRateTypeTransitionalMeasures8.toString(),
    taxRateTypeNotApplicable: taxRateTypeNotApplicable.toString(),
  }
}

interface ClaimUnitAmountBreakdown {
  amount: string
  amountType8: string
  amountType10: string
  amountReduced8: string
  amountTransitionalMeasures8: string
  taxAmountType8: string
  taxAmountType10: string
  taxAmountReduced8: string
  taxAmountTransitionalMeasures8: string
}

export async function calculateClaimUnit (transactions: TransactionStoreInput[], taxRoundingType?: TaxRoundingType): Promise<ClaimUnitAmountBreakdown> {
  let taxAmountType8 = new Big(0)
  let amountType8 = new Big(0)
  let taxAmountType10 = new Big(0)
  let amountType10 = new Big(0)
  let taxAmountReduced8 = new Big(0)
  let amountReduced8 = new Big(0)
  let taxAmountTransitionalMeasures8 = new Big(0)
  let amountTransitionalMeasures8 = new Big(0)
  let amount = new Big(0)

  const mode = getTaxRoundingModeForBigjs(taxRoundingType)

  transactions.forEach((t) => {
    amountType8 = amountType8.add(t.amountsPerTaxRateType.taxRateType8 || 0)
    amountType10 = amountType10.add(t.amountsPerTaxRateType.taxRateType10 || 0)
    amountReduced8 = amountReduced8.add(t.amountsPerTaxRateType.taxRateTypeReduced8 || 0)
    amountTransitionalMeasures8 = amountTransitionalMeasures8.add(t.amountsPerTaxRateType.taxRateTypeTransitionalMeasures8 || 0)
    amount = amount.add(t.amount)
  })

  if (!amountType8.eq(0)) {
    taxAmountType8 = amountType8.minus(amountType8.div('1.08')).round(0, mode)
  }

  if (!amountReduced8.eq(0)) {
    taxAmountReduced8 = amountReduced8.minus(amountReduced8.div('1.08')).round(0, mode)
  }

  if (!amountTransitionalMeasures8.eq(0)) {
    taxAmountTransitionalMeasures8 = amountTransitionalMeasures8.minus(amountTransitionalMeasures8.div('1.08')).round(0, mode)
  }

  if (!amountType10.eq(0)) {
    taxAmountType10 = amountType10.minus(amountType10.div('1.1')).round(0, mode)
  }

  return {
    amount: amount.toString(),
    amountType8: amountType8.toString(),
    amountType10: amountType10.toString(),
    amountReduced8: amountReduced8.toString(),
    amountTransitionalMeasures8: amountTransitionalMeasures8.toString(),
    taxAmountType8: taxAmountType8.toString(),
    taxAmountType10: taxAmountType10.toString(),
    taxAmountReduced8: taxAmountReduced8.toString(),
    taxAmountTransitionalMeasures8: taxAmountTransitionalMeasures8.toString(),
  }
}

interface CalculateResult {
  transactions: TransactionStoreInput[]
  amount: string
  amountType8: string
  amountType10: string
  amountReduced8: string
  amountTransitionalMeasures8: string
  taxAmountType8: string
  taxAmountType10: string
  taxAmountReduced8: string
  taxAmountTransitionalMeasures8: string
  additionalAmount: string
}

export async function calculate (transactions: TransactionStoreInput[], taxRoundingType?: TaxRoundingType, taxIncluded?: boolean, existClaimUnit?: ClaimUnit): Promise<CalculateResult> {
  const ts = await Promise.all(transactions.map(async (t) => {
    const details = await Promise.all(t.details.map(async (d) => {
      return {
        name: d.name,
        unitPrice: d.unitPrice,
        quantity: d.quantity,
        amount: await calculateDetail(d.unitPrice, d.quantity, taxRoundingType),
        taxRateType: d.taxRateType,
        taxIncluded: d.taxIncluded,
      }
    }))
    const r = await calculateTransaction(details, taxRoundingType, taxIncluded)

    return {
      amount: Big(r.amount).toNumber(),
      amountsPerTaxRateType: {
        taxRateTypeUnknown: Big(r.taxRateTypeUnknown || 0).toNumber(),
        taxRateTypeFree: Big(r.taxRateTypeFree || 0).toNumber(),
        taxRateType8: Big(r.taxRateType8 || 0).toNumber(),
        taxRateType10: Big(r.taxRateType10 || 0).toNumber(),
        taxRateTypeReduced8: Big(r.taxRateTypeReduced8 || 0).toNumber(),
        taxRateTypeTransitionalMeasures8: Big(r.taxRateTypeTransitionalMeasures8 || 0).toNumber(),
        taxRateTypeNotApplicable: Big(r.taxRateTypeNotApplicable || 0).toNumber(),
      },
      date: t.date,
      destination: t.destination,
      destinationInput: t.destinationInput,
      dueDate: t.dueDate,
      deliveryMethod: t.deliveryMethod,
      issueDate: t.issueDate,
      number: t.number,
      details,
      taxDetails: {
        taxRateType10: t.taxDetails.taxRateType10,
        taxRateType8: t.taxDetails.taxRateType8,
        taxRateTypeReduced8: t.taxDetails.taxRateTypeReduced8,
        taxRateTypeTransitionalMeasures8: t.taxDetails.taxRateTypeTransitionalMeasures8,
      },
      authorization: t.authorization,
      isDuplicatedTransactionNumber: t.isDuplicatedTransactionNumber,
    }
  }))

  const res = await calculateClaimUnit(ts, taxRoundingType)
  let amount = res.amount
  let amountType8 = res.amountType8
  let amountType10 = res.amountType10
  let amountReduced8 = res.amountReduced8
  let amountTransitionalMeasures8 = res.amountTransitionalMeasures8
  let taxAmountType8 = res.taxAmountType8
  let taxAmountType10 = res.taxAmountType10
  let taxAmountReduced8 = res.taxAmountReduced8
  let taxAmountTransitionalMeasures8 = res.taxAmountTransitionalMeasures8
  const mode = getTaxRoundingModeForBigjs(taxRoundingType)

  if (existClaimUnit) {
    amount = Big(amount).add(existClaimUnit.amount).toString()
    const ta8 = existClaimUnit.claimUnitTaxAmounts?.find(ta => ta.taxRateType === 'TaxRateType8')
    if (ta8) {
      const base = Big(amountType8).add(ta8.amount)
      amountType8 = base.toString()
      taxAmountType8 = base.minus(base.div('1.08')).round(0, mode).toString()
    }
    const ta10 = existClaimUnit.claimUnitTaxAmounts?.find(ta => ta.taxRateType === 'TaxRateType10')
    if (ta10) {
      const base = Big(amountType10).add(ta10.amount)
      amountType10 = base.toString()
      taxAmountType10 = base.minus(base.div('1.1')).round(0, mode).toString()
    }
    const taR8 = existClaimUnit.claimUnitTaxAmounts?.find(ta => ta.taxRateType === 'TaxRateTypeReduced8')
    if (taR8) {
      const base = Big(amountReduced8).add(taR8.amount)
      amountReduced8 = base.toString()
      taxAmountReduced8 = base.minus(base.div('1.08')).round(0, mode).toString()
    }
    const taTM8 = existClaimUnit.claimUnitTaxAmounts?.find(ta => ta.taxRateType === 'TaxRateTypeTransitionalMeasures8')
    if (taTM8) {
      const base = Big(amountTransitionalMeasures8).add(taTM8.amount)
      amountTransitionalMeasures8 = base.toString()
      taxAmountTransitionalMeasures8 = base.minus(base.div('1.08')).round(0, mode).toString()
    }
  }
  return {
    transactions: ts,
    amount,
    amountType8,
    amountType10,
    amountReduced8,
    amountTransitionalMeasures8,
    taxAmountType8,
    taxAmountType10,
    taxAmountReduced8,
    taxAmountTransitionalMeasures8,
    additionalAmount: res.amount,
  }
}

export async function calculateTransactionTaxAmountOnTransaction (details?: TransactionPerClaimUnitDetailInput[], taxRoundingType?: TaxRoundingType, taxIncluded?: boolean | null): Promise<TransactionAmountBreakdown & {taxAmounts: TransactionsTaxPerTaxRateTypeInput}> {
  const mode = getTaxRoundingModeForBigjs(taxRoundingType)

  let taxRateTypeUnknown = new Big(0)
  let taxRateTypeFree = new Big(0)
  let taxRateType8 = new Big(0)
  let taxRateType10 = new Big(0)
  let taxRateTypeReduced8 = new Big(0)
  let taxRateTypeTransitionalMeasures8 = new Big(0)
  let taxRateTypeNotApplicable = new Big(0)

  if (details) {
    for (const key in details) {
      const d = details[key]
      if (d.amount === '') {
        continue
      }

      switch (d.taxRateType.type) {
        case 'TaxRateTypeUnknown':
          taxRateTypeUnknown = taxRateTypeUnknown.add(d.amount)
          break
        case 'TaxRateTypeFree':
          taxRateTypeFree = taxRateTypeFree.add(d.amount)
          break
        case 'TaxRateType8':
          taxRateType8 = taxRateType8.add(d.amount)
          break
        case 'TaxRateType10':
          taxRateType10 = taxRateType10.add(d.amount)
          break
        case 'TaxRateTypeReduced8':
          taxRateTypeReduced8 = taxRateTypeReduced8.add(d.amount)
          break
        case 'TaxRateTypeTransitionalMeasures8':
          taxRateTypeTransitionalMeasures8 = taxRateTypeTransitionalMeasures8.add(d.amount)
          break
        case 'TaxRateTypeNotApplicable':
          taxRateTypeNotApplicable = taxRateTypeNotApplicable.add(d.amount)
          break
      }
    }
  }

  if (!taxIncluded) {
    taxRateType8 = taxRateType8.mul('1.08')
    taxRateType10 = taxRateType10.mul('1.1')
    taxRateTypeReduced8 = taxRateTypeReduced8.mul('1.08')
    taxRateTypeTransitionalMeasures8 = taxRateTypeTransitionalMeasures8.mul('1.08')
  }

  const taxAmounts: TransactionsTaxPerTaxRateTypeInput = {
    taxRateType8: '',
    taxRateType10: '',
    taxRateTypeReduced8: '',
    taxRateTypeTransitionalMeasures8: '',
  }

  taxAmounts.taxRateType8 = taxRateType8.div(108).mul(8).round(0, mode).toString()
  taxAmounts.taxRateType10 = taxRateType10.div(110).mul(10).round(0, mode).toString()
  taxAmounts.taxRateTypeReduced8 = taxRateTypeReduced8.div(108).mul(8).round(0, mode).toString()
  taxAmounts.taxRateTypeTransitionalMeasures8 = taxRateTypeTransitionalMeasures8.div(108).mul(8).round(0, mode).toString()

  taxRateType8 = taxRateType8.round(0, mode)
  taxRateType10 = taxRateType10.round(0, mode)
  taxRateTypeReduced8 = taxRateTypeReduced8.round(0, mode)
  taxRateTypeTransitionalMeasures8 = taxRateTypeTransitionalMeasures8.round(0, mode)
  taxRateTypeUnknown = taxRateTypeUnknown.round(0, mode)
  taxRateTypeNotApplicable = taxRateTypeNotApplicable.round(0, mode)
  taxRateTypeFree = taxRateTypeFree.round(0, mode)

  let amount = new Big(0)
  amount = amount.add(taxRateTypeUnknown)
  amount = amount.add(taxRateTypeFree)
  amount = amount.add(taxRateType8)
  amount = amount.add(taxRateType10)
  amount = amount.add(taxRateTypeReduced8)
  amount = amount.add(taxRateTypeTransitionalMeasures8)
  amount = amount.add(taxRateTypeNotApplicable)

  return {
    amount: amount.toString(),
    taxRateTypeUnknown: taxRateTypeUnknown.toString(),
    taxRateTypeFree: taxRateTypeFree.toString(),
    taxRateType8: taxRateType8.toString(),
    taxRateType10: taxRateType10.toString(),
    taxRateTypeReduced8: taxRateTypeReduced8.toString(),
    taxRateTypeTransitionalMeasures8: taxRateTypeTransitionalMeasures8.toString(),
    taxRateTypeNotApplicable: taxRateTypeNotApplicable.toString(),
    taxAmounts,
  }
}

export async function calculateClaimUnitOnTransaction (transactions: TransactionStoreInput[]): Promise<ClaimUnitAmountBreakdown> {
  let taxAmountType8 = new Big(0)
  let amountType8 = new Big(0)
  let taxAmountType10 = new Big(0)
  let amountType10 = new Big(0)
  let taxAmountReduced8 = new Big(0)
  let amountReduced8 = new Big(0)
  let taxAmountTransitionalMeasures8 = new Big(0)
  let amountTransitionalMeasures8 = new Big(0)
  let amount = new Big(0)

  transactions.forEach((t) => {
    amountType8 = amountType8.add(t.amountsPerTaxRateType.taxRateType8 || 0)
    amountType10 = amountType10.add(t.amountsPerTaxRateType.taxRateType10 || 0)
    amountReduced8 = amountReduced8.add(t.amountsPerTaxRateType.taxRateTypeReduced8 || 0)
    amountTransitionalMeasures8 = amountTransitionalMeasures8.add(t.amountsPerTaxRateType.taxRateTypeTransitionalMeasures8 || 0)
    amount = amount.add(t.amount)
    if (t.taxDetails) {
      taxAmountType8 = taxAmountType8.add(t.taxDetails.taxRateType8)
      taxAmountType10 = taxAmountType10.add(t.taxDetails.taxRateType10)
      taxAmountReduced8 = taxAmountReduced8.add(t.taxDetails.taxRateTypeReduced8)
      taxAmountTransitionalMeasures8 = taxAmountTransitionalMeasures8.add(t.taxDetails.taxRateTypeTransitionalMeasures8)
    }
  })

  return {
    amount: amount.toString(),
    amountType8: amountType8.toString(),
    amountType10: amountType10.toString(),
    amountReduced8: amountReduced8.toString(),
    amountTransitionalMeasures8: amountTransitionalMeasures8.toString(),
    taxAmountType8: taxAmountType8.toString(),
    taxAmountType10: taxAmountType10.toString(),
    taxAmountReduced8: taxAmountReduced8.toString(),
    taxAmountTransitionalMeasures8: taxAmountTransitionalMeasures8.toString(),
  }
}

export async function calculateOnTransacstion (transactions: TransactionStoreInput[], taxRoundingType?: TaxRoundingType, taxIncluded?: boolean, existClaimUnit?: ClaimUnit): Promise<CalculateResult> {
  const ts = await Promise.all(transactions.map(async (t) => {
    const details = await Promise.all(t.details.map(async (d) => {
      return {
        name: d.name,
        unitPrice: d.unitPrice,
        quantity: d.quantity,
        amount: await calculateDetail(d.unitPrice, d.quantity, taxRoundingType),
        taxRateType: d.taxRateType,
        taxIncluded: d.taxIncluded,
      }
    }))
    const r = await calculateTransactionTaxAmountOnTransaction(details, taxRoundingType, taxIncluded)

    return {
      amount: Big(r.amount).toNumber(),
      amountsPerTaxRateType: {
        taxRateTypeUnknown: Big(r.taxRateTypeUnknown || 0).toNumber(),
        taxRateTypeFree: Big(r.taxRateTypeFree || 0).toNumber(),
        taxRateType8: Big(r.taxRateType8 || 0).toNumber(),
        taxRateType10: Big(r.taxRateType10 || 0).toNumber(),
        taxRateTypeReduced8: Big(r.taxRateTypeReduced8 || 0).toNumber(),
        taxRateTypeTransitionalMeasures8: Big(r.taxRateTypeTransitionalMeasures8 || 0).toNumber(),
        taxRateTypeNotApplicable: Big(r.taxRateTypeNotApplicable || 0).toNumber(),
      },
      date: t.date,
      destination: t.destination,
      destinationInput: t.destinationInput,
      dueDate: t.dueDate,
      deliveryMethod: t.deliveryMethod,
      issueDate: t.issueDate,
      number: t.number,
      details,
      taxDetails: r.taxAmounts,
      authorization: t.authorization,
      isDuplicatedTransactionNumber: t.isDuplicatedTransactionNumber,
    }
  }))

  const res = await calculateClaimUnitOnTransaction(ts)
  let amount = res.amount
  let amountType8 = res.amountType8
  let amountType10 = res.amountType10
  let amountReduced8 = res.amountReduced8
  let amountTransitionalMeasures8 = res.amountTransitionalMeasures8
  let taxAmountType8 = res.taxAmountType8
  let taxAmountType10 = res.taxAmountType10
  let taxAmountReduced8 = res.taxAmountReduced8
  let taxAmountTransitionalMeasures8 = res.taxAmountTransitionalMeasures8

  if (existClaimUnit) {
    amount = Big(amount).add(existClaimUnit.amount).toString()
    const ta8 = existClaimUnit.claimUnitTaxAmounts?.find(ta => ta.taxRateType === 'TaxRateType8')
    if (ta8) {
      amountType8 = Big(amountType8).add(ta8.amount).toString()
      taxAmountType8 = Big(taxAmountType8).add(ta8.taxAmount).toString()
    }
    const ta10 = existClaimUnit.claimUnitTaxAmounts?.find(ta => ta.taxRateType === 'TaxRateType10')
    if (ta10) {
      amountType10 = Big(amountType10).add(ta10.amount).toString()
      taxAmountType10 = Big(taxAmountType10).add(ta10.taxAmount).toString()
    }
    const taR8 = existClaimUnit.claimUnitTaxAmounts?.find(ta => ta.taxRateType === 'TaxRateTypeReduced8')
    if (taR8) {
      amountReduced8 = Big(amountReduced8).add(taR8.amount).toString()
      taxAmountReduced8 = Big(taxAmountReduced8).add(taR8.taxAmount).toString()
    }
    const taTM8 = existClaimUnit.claimUnitTaxAmounts?.find(ta => ta.taxRateType === 'TaxRateTypeTransitionalMeasures8')
    if (taTM8) {
      amountTransitionalMeasures8 = Big(amountTransitionalMeasures8).add(taTM8.amount).toString()
      taxAmountTransitionalMeasures8 = Big(taxAmountTransitionalMeasures8).add(taTM8.taxAmount).toString()
    }
  }
  return {
    transactions: ts,
    amount,
    amountType8,
    amountType10,
    amountReduced8,
    amountTransitionalMeasures8,
    taxAmountType8,
    taxAmountType10,
    taxAmountReduced8,
    taxAmountTransitionalMeasures8,
    additionalAmount: res.amount,
  }
}

/* eslint-enable require-await */
