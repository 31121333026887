var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('p',{staticClass:"rounded shadow flex items-stretch items-center md:shadow-md",class:{'error-component': _vm.isError}},[_c('span',{staticClass:"rounded-l inline-block text-center w-9 md:w-10 min-h-12 flex-grow-0 flex-shrink-0 py-4",class:{
      'text-white bg-warning': _vm.isError,
      'text-gray-4 bg-gray-2': _vm.isInfo,
      'text-white bg-success': _vm.isSuccess
    }},[_c('fa-icon',{attrs:{"icon":_vm.icon}})],1),_vm._v(" "),_c('span',{staticClass:"w-full bg-white rounded-r py-4 px-3 md:px-5",class:{
      'text-warning': _vm.isError,
      'text-gray-4': _vm.isInfo,
      'text-success': _vm.isSuccess
    }},[_vm._t("default")],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }