import { Plugin } from '@nuxt/types'
import { API } from '@aws-amplify/api'
import Amplify from '@aws-amplify/core'

const MFKCompanySearchInternalPlugin: Plugin = (ctx, inject) => {
  const app = ctx.app

  Amplify.configure({
    aws_project_region: 'ap-northeast-1',
    aws_cognito_identity_pool_id: app.$config.awsCofnitoIdentityPoolId,
    aws_cognito_region: 'ap-northeast-1',
    aws_user_pools_id: app.$config.awsUserPoolsId,
    aws_user_pools_web_client_id: app.$config.awsUserPoolsWebClientId,
    oauth: {},
    aws_appsync_graphqlEndpoint: app.$config.awsAppSyncGraphEndPoint,
    aws_appsync_region: 'ap-northeast-1',
    aws_appsync_authenticationType: 'AWS_IAM',
  })

  inject('companySearchInternal', API)
}

export default MFKCompanySearchInternalPlugin
