
import { Component, namespace, Vue } from 'nuxt-property-decorator'
import HoverMenu from '~/components/layout/menu/hover-menu.vue'
import SettingIcon from '~/components/element/icon/setting-icon.vue'

const sellerStore = namespace('seller')

@Component({
  components: {
    SettingIcon,
    HoverMenu,
  },
})
export default class extends Vue {
  @sellerStore.State('assignment')
  private readonly isAssignment!: boolean

  private reset (): void {
    this.$emit('reset')
  }
}
