<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="fill-current"
  >
    <path fill-rule="evenodd" clip-rule="evenodd" d="M9.99203 6.66634V8.66634L7.33203 5.99967L9.99203 3.33301V5.33301H14.6654V6.66634H9.99203ZM1.33203 9.33301H6.00536V7.33301L8.66536 9.99967L6.00536 12.6663V10.6663H1.33203V9.33301Z" />
  </svg>
</template>

<script>
export default {
}
</script>
