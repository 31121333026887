import { Component, Vue, State } from 'nuxt-property-decorator'
import { MetaInfo } from 'vue-meta'

@Component
export default class Common extends Vue {
  @State
  private readonly subdomain?: string

  @State
  private readonly favicon?: string

  @State
  private readonly css?: string

  @State
  private readonly serviceName!: string

  @State
  private readonly serviceNameWithKana!: string

  head (): MetaInfo {
    // TODO: og:imageを設定
    const head: MetaInfo = {
      meta: [
        { name: 'description', content: '顧客の与信状況、取引明細、請求書発行、振込など最新状況の確認はこちらから' },
        { name: 'keywords', content: this.serviceName },
        { property: 'og:title', content: `${this.serviceNameWithKana}にログイン` },
        { property: 'og:type', content: 'website' },
        { property: 'og:site_name', content: `${this.serviceNameWithKana}｜企業間後払い決済・請求代行サービス` },
        { property: 'og:description', content: '顧客の与信状況、取引明細、請求書発行、振込など最新状況の確認はこちらから' },
        { property: 'fb:app_id', content: '2432515776987034' },
      ],
      link: [],
      title: this.serviceName,
    }

    if (!head.meta || !head.link) {
      return head
    }
    head.meta.push({ property: 'og:url', content: `https://${this.$store.state.domain}/` })

    if (this.favicon) {
      head.link.push({ rel: 'icon', type: 'image/x-icon', href: this.favicon })
    } else {
      head.link.push({ rel: 'icon', type: 'image/x-icon', href: '/favicon.ico' })
    }

    if (this.css) {
      head.link.push({ rel: 'stylesheet', href: this.css, pbody: true })
    }

    if (this.isSandbox) {
      head.bodyAttrs = {
        class: 'is-sandbox',
      }
    }

    return head
  }

  get isSandbox (): boolean {
    let host = ''
    if (process.server) {
      host = this.$store.state.domain
    } else {
      host = location.host
    }
    return !!host.match(/sandbox-s.(mfk|bfw).jp/)
  }
}
