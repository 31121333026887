<template>
  <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg" class="fill-current">
    <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8h5z" />
  </svg>
</template>

<script>
export default {
}
</script>
