
import { Component, Prop, Vue } from 'nuxt-property-decorator'

@Component
export default class extends Vue {
  @Prop({ type: String, required: true })
  private readonly type!: string

  get isError (): boolean {
    return this.type === 'error'
  }

  get isInfo (): boolean {
    return this.type === 'info'
  }

  get isSuccess (): boolean {
    return this.type === 'success'
  }

  get icon (): string {
    switch (this.type) {
      case 'error':
      case 'info':
        return 'exclamation'
      case 'success':
      default:
        return 'check'
    }
  }
}
