<template>
  <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg" class="fill-current">
    <path d="M19.385 11.352v1.286c0 .354-.11.66-.331.914a1.062 1.062 0 01-.844.371H11.14l2.943 2.953c.254.241.381.543.381.904 0 .362-.127.663-.381.904l-.754.764a1.23 1.23 0 01-.904.372c-.348 0-.652-.124-.914-.372l-6.539-6.55a1.23 1.23 0 01-.371-.903c0-.348.123-.653.371-.914l6.54-6.53c.254-.254.559-.381.913-.381.349 0 .65.127.904.382l.754.743c.254.254.381.56.381.914 0 .355-.127.66-.381.914l-2.943 2.943h7.071c.348 0 .63.127.844.382.22.248.331.549.331.904z" />
  </svg>
</template>

<script>
export default {
}
</script>
