
import { Component, Prop, Vue } from 'nuxt-property-decorator'
import { createPopper, Instance } from '@popperjs/core'
import { isHTMLObject, isVueObject } from '~/utils/vue-helper'
import Modal from '~/components/layout/modal.vue'
import NotificationIcon from '~/components/element/icon/notification.vue'
import Oshirase from '~/components/oshirase/oshirase.vue'

@Component({
  components: {
    Modal,
    NotificationIcon,
    Oshirase,
  },
})
export default class OshiraseMenu extends Vue {
  @Prop({ default: false })
  private readonly collapse!: boolean

  private isActive = false
  private instance: Instance | null = null
  private hasNew = false

  private openPopper (): void {
    if (!this.collapse) {
      return
    }

    this.isActive = true
    this.$nextTick(() => {
      const refs = this.$refs['oshirase-target']
      const ref = Array.isArray(refs) ? refs[0] : refs
      const pops = this.$refs['oshirase-popover']
      const pop = Array.isArray(pops) ? pops[0] : pops
      if (ref && isHTMLObject(ref) && pop && isHTMLObject(pop)) {
        this.instance = createPopper(ref, pop, {
          placement: 'right',
        })
      }
    })
  }

  private closePopper (): void {
    this.isActive = false
    if (this.instance) {
      this.instance.destroy()
      this.instance = null
    }
  }

  private callModal (): void {
    const modal = this.$refs.modal
    if (isVueObject(modal)) {
      modal.$emit('toggle')
    }
  }

  private closeModal (): void {
    const modal = this.$refs.modal
    if (isVueObject(modal)) {
      modal.$emit('toggle')
    }
  }

  private onClick (): void {
    this.callModal()
    const oshirase = this.$refs.oshirase
    if (isVueObject(oshirase)) {
      oshirase.$emit('open')
    }
  }

  private onArrival (hasNew: boolean): void {
    this.hasNew = hasNew
  }
}
