
import { Component, mixins, Action, State } from 'nuxt-property-decorator'
import Common from './common'
import Notifications from '~/components/layout/notifications.vue'
import AppHeader from '~/components/layout/header/app-header.vue'
import BackArrowIcon from '~/components/element/icon/back-arrow-icon.vue'
import DeveloperIcon from '~/components/element/icon/developer-icon.vue'

@Component({
  components: {
    Notifications,
    AppHeader,
    BackArrowIcon,
    DeveloperIcon,
  },
  middleware: ['check-auth', 'check-bank-account'],
})
export default class extends mixins(Common) {
  @State
  private readonly isMenuOpened!: boolean

  @Action
  private readonly closeMenu!: () => void

  private close (): void {
    this.closeMenu()
  }
}
