const middleware = {}

middleware['authenticated-signin'] = require('../middleware/authenticated-signin.ts')
middleware['authenticated-signin'] = middleware['authenticated-signin'].default || middleware['authenticated-signin']

middleware['check-auth-signup'] = require('../middleware/check-auth-signup.ts')
middleware['check-auth-signup'] = middleware['check-auth-signup'].default || middleware['check-auth-signup']

middleware['check-auth'] = require('../middleware/check-auth.ts')
middleware['check-auth'] = middleware['check-auth'].default || middleware['check-auth']

middleware['check-bank-account'] = require('../middleware/check-bank-account.ts')
middleware['check-bank-account'] = middleware['check-bank-account'].default || middleware['check-bank-account']

middleware['logger'] = require('../middleware/logger.ts')
middleware['logger'] = middleware['logger'].default || middleware['logger']

middleware['root-redirect'] = require('../middleware/root-redirect.ts')
middleware['root-redirect'] = middleware['root-redirect'].default || middleware['root-redirect']

export default middleware
