
export interface IntrospectionResultData {
        __schema: {
          types: {
            kind: string;
            name: string;
            possibleTypes: {
              name: string;
            }[];
          }[];
        };
      }
const result: IntrospectionResultData = {
  __schema: {
    types: [
      {
        kind: 'UNION',
        name: 'ClaimUnitHistory',
        possibleTypes: [
          {
            name: 'ClaimUnitIssueHistory',
          },
          {
            name: 'ClaimUnitTransactionHistory',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'MemberOrInvitation',
        possibleTypes: [
          {
            name: 'Invitation',
          },
          {
            name: 'SellerUser',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'ScheduledOrFixedBankTransfer',
        possibleTypes: [
          {
            name: 'BankTransfer',
          },
          {
            name: 'EarlyTransfer',
          },
          {
            name: 'ScheduledBankTransfer',
          },
        ],
      },
    ],
  },
}
export default result
