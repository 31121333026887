import { Plugin } from '@nuxt/types'

const p: Plugin = function (ctx) {
  ctx.app.router?.beforeEach((_to, _from, next) => { // eslint-disable-line no-unused-expressions
    ctx.store.commit('notifications/refreshInstants')
    ctx.store.commit('notifications/setPage', true)
    next()
  })
}

export default p
