
import { Component, Prop, Vue, Watch } from 'nuxt-property-decorator'

@Component({
  props: {
    title: {
      type: String,
      required: true,
      default: 'Simple Modal',
    },
  },
})
export default class extends Vue {
  private isOpen = false

  @Prop({ default: false, type: Boolean })
  private readonly processing!: boolean

  protected mounted (): void {
    this.$on('toggle', () => {
      this.isOpen = !this.isOpen
    })
  }

  private onClose (): void {
    if (this.processing) {
      return
    }
    this.isOpen = false
  }

  @Watch('isOpen')
  private changeStatus (): void {
    if (this.isOpen) {
      this.$emit('open')
    } else {
      this.$emit('close')
    }
  }
}
