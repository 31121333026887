
import { Component, mixins, namespace } from 'nuxt-property-decorator'
import Common from './common'
import Logo from '~/components/element/logo.vue'
import Step from '~/components/element/step.vue'
import Card from '~/components/layout/card/card.vue'

const signupProgressStore = namespace('pages/tour/signup-progress')

@Component({
  components: {
    Logo,
    Step,
    Card,
  },
})
export default class extends mixins(Common) {
  @signupProgressStore.State
  private readonly bank!: boolean

  @signupProgressStore.State
  private readonly logo!: boolean

  get hasOneDone (): boolean {
    return !this.$route.path.includes('signup')
  }

  get hasTwoDone (): boolean {
    return this.$route.path.startsWith('/tour') && this.$route.path.includes('logo')
  }

  get isComplete (): boolean {
    // tour中の画面でなければ完了とみなす
    const path = this.$route.path
    if (!path.includes('signup') && !path.includes('/tour/bank') && !path.includes('/tour/logo')) {
      return true
    }
    // すべての設定が完了していたら完了
    return this.bank && this.logo
  }
}
