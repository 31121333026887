
import { Component, Emit, Prop, Vue } from 'nuxt-property-decorator'

@Component({
  props: {
    type: {
      type: String,
      default: 'submit',
    },
  },
})
export default class extends Vue {
  @Prop({ default: false })
  private readonly disabled!: boolean

  @Prop({ default: false })
  private readonly loading!: boolean

  @Prop({ default: '' })
  private readonly bgColor!: string

  @Prop({ default: 'border-solid border' })
  private readonly borderStyle!: string

  @Prop({ default: 'border-gray-3' })
  private readonly borderColor!: string

  @Prop({ default: 'text-text' })
  private readonly textColor!: string

  @Prop({ default: '' })
  private readonly textStyle!: string

  @Prop({ default: '' })
  private readonly styles!: string

  get classes (): string[] {
    const cs: string[] = [this.bgColor, this.textColor, this.textStyle, this.borderStyle, this.borderColor, this.styles]
    if (this.disabled) {
      cs.push('opacity-50')
      cs.push('cursor-default')
    } else {
      cs.push('cursor-pointer')
    }

    if (this.bgColor) {
      cs.push(this.bgColor)
    } else {
      cs.push('bg-gray-1')
      !this.disabled && cs.push('hover:bg-gray-2')
    }

    if (this.loading) {
      cs.push('is-loading')
    }

    return cs
  }

  @Emit('click')
  private onClick (e: Event): Event {
    return e
  }
}
