import { Big } from 'big.js'
import { MutationTree, ActionTree, GetterTree, ActionContext } from 'vuex'
import { AwaitType, RootState } from '~/types'
import { ClaimUnit, TaxRateType, TransactionDetail } from '~/types/eldamar'
import { ClaimUnitInput, ClaimUnitPerTaxRate, ClaimUnitRegisterState, TransactionStoreInput, DuplicatingTransaction, TaxRateTypeOption, InputDetail } from '~/types/registers'
import { calculate, calculateDetail, calculateOnTransacstion, calculateTransaction } from '~/utils/billing-calculator'
import { TaxRateTypes } from '~/utils/defines'

export const state = (): ClaimUnitRegisterState => ({
  transactions: [],
  amount: '',
  amountsPerTaxRate: null,
  additionalAmount: '',
})

export const strict = true

export const mutations: MutationTree<ClaimUnitRegisterState> = {
  setClaimUnitInput (state: ClaimUnitRegisterState, input?: ClaimUnitInput): void {
    state.input = input
  },
  setTransactionInput (state: ClaimUnitRegisterState, input:TransactionStoreInput): void {
    state.transactions.push(input)
  },
  removeTransactionInputByIndex (state: ClaimUnitRegisterState, index: number): void {
    state.transactions.splice(index, 1)
  },
  setDetailByTransaction (state: ClaimUnitRegisterState, { input, index }: {input: InputDetail, index: number}): void {
    state.transactions[index].details.push(input)
  },
  removeDetailByTransaction (state: ClaimUnitRegisterState, { transactionIndex, detailIndex }: {transactionIndex: number, detailIndex: number}): void {
    state.transactions[transactionIndex].details.splice(detailIndex, 1)
  },
  resetTransactionInput (state: ClaimUnitRegisterState): void {
    state.transactions = []
  },
  setAmount (state: ClaimUnitRegisterState, amount: string): void {
    state.amount = amount
  },
  setAmountsPerTaxRate (state: ClaimUnitRegisterState, a: ClaimUnitPerTaxRate | null): void {
    state.amountsPerTaxRate = a
  },
  setRegisteredClaimUnitId (state: ClaimUnitRegisterState, id?: string): void {
    state.registeredClaimUnitId = id
  },
  setExistClaimUnit (state: ClaimUnitRegisterState, cu?: ClaimUnit): void {
    state.existClaimUnit = cu
  },
  setAdditionalAmount (state: ClaimUnitRegisterState, aa: string): void {
    state.additionalAmount = aa
  },
  setDuplicatingTransaction (state: ClaimUnitRegisterState, t?: DuplicatingTransaction): void {
    state.duplicatingTransaction = t
  },
  setTransactionNumberByIndex (state: ClaimUnitRegisterState, { n, i }: {n: string, i: number}): void {
    state.transactions[i].number = n
  },
  setTransactionNumberDupelicateStatusByIndex (state: ClaimUnitRegisterState, { s, i }: {s: boolean, i: number}): void {
    state.transactions[i].isDuplicatedTransactionNumber = s
  },
  setTransactionDateByIndex (state: ClaimUnitRegisterState, { d, i }: {d: string, i: number}): void {
    state.transactions[i].date = d
  },
  setTransactionAmountByIndex (state: ClaimUnitRegisterState, { a, i }: {a: number, i: number}): void {
    state.transactions[i].amount = a
  },
  setDetailNameByIndex (state: ClaimUnitRegisterState, { n, transactionIndex, detailIndex }: {n: string, transactionIndex: number, detailIndex: number}): void {
    state.transactions[transactionIndex].details[detailIndex].name = n
  },
  setDetailUnitPriceByIndex (state: ClaimUnitRegisterState, { u, transactionIndex, detailIndex }: {u: string, transactionIndex: number, detailIndex: number}): void {
    state.transactions[transactionIndex].details[detailIndex].unitPrice = u
  },
  setDetailQuantityByIndex (state: ClaimUnitRegisterState, { q, transactionIndex, detailIndex }: {q: string, transactionIndex: number, detailIndex: number}): void {
    state.transactions[transactionIndex].details[detailIndex].quantity = q
  },
  setDetailTaxIncludedByIndex (state: ClaimUnitRegisterState, { t, transactionIndex, detailIndex }: {t: boolean, transactionIndex: number, detailIndex: number}): void {
    state.transactions[transactionIndex].details[detailIndex].taxIncluded = t
  },
  setDetailTaxRateTypeByIndex (state: ClaimUnitRegisterState, { t, transactionIndex, detailIndex }: {t: TaxRateTypeOption, transactionIndex: number, detailIndex: number}): void {
    state.transactions[transactionIndex].details[detailIndex].taxRateType = t
  },
  setDetailAmountByIndex (state: ClaimUnitRegisterState, { a, transactionIndex, detailIndex }: {a: string, transactionIndex: number, detailIndex: number}): void {
    state.transactions[transactionIndex].details[detailIndex].amount = a
  },
}

export const getters: GetterTree<ClaimUnitRegisterState, RootState> = {
  transactions (state: ClaimUnitRegisterState): TransactionStoreInput[] {
    return state.transactions
  },
  taxAmountType8 (state: ClaimUnitRegisterState): string {
    return state.amountsPerTaxRate?.taxAmountType8 || ''
  },
  amountType8 (state: ClaimUnitRegisterState): string {
    return state.amountsPerTaxRate?.amountType8 || ''
  },
  taxAmountType10 (state: ClaimUnitRegisterState): string {
    return state.amountsPerTaxRate?.taxAmountType10 || ''
  },
  amountType10 (state: ClaimUnitRegisterState): string {
    return state.amountsPerTaxRate?.amountType10 || ''
  },
  taxAmountReduced8 (state: ClaimUnitRegisterState): string {
    return state.amountsPerTaxRate?.taxAmountReduced8 || ''
  },
  amountReduced8 (state: ClaimUnitRegisterState): string {
    return state.amountsPerTaxRate?.amountReduced8 || ''
  },
  taxAmountTransitionalMeasures8 (state: ClaimUnitRegisterState): string {
    return state.amountsPerTaxRate?.taxAmountTransitionalMeasures8 || ''
  },
  amountTransitionalMeasures8 (state: ClaimUnitRegisterState): string {
    return state.amountsPerTaxRate?.amountTransitionalMeasures8 || ''
  },
}

export const actions: ActionTree<ClaimUnitRegisterState, RootState> = {
  reset (ctx: ActionContext<ClaimUnitRegisterState, RootState>): void {
    ctx.commit('setClaimUnitInput', undefined)
    ctx.commit('resetTransactionInput')
    ctx.commit('setAmount', '')
    ctx.commit('setAmountsPerTaxRate', null)
    ctx.commit('setRegisteredClaimUnitId', '')
    ctx.commit('setExistClaimUnit', undefined)
    ctx.commit('setDuplicatingTransaction', undefined)
  },
  addTransaction (ctx: ActionContext<ClaimUnitRegisterState, RootState>): void {
    ctx.commit('setTransactionInput', {
      amount: 0,
      amountsPerTaxRateType: {
        taxRateTypeUnknown: 0,
        taxRateTypeFree: 0,
        taxRateType10: 0,
        taxRateType8: 0,
        taxRateTypeReduced8: 0,
        taxRateTypeTransitionalMeasures8: 0,
        taxRateTypeNotApplicable: 0,
      },
      date: '',
      dueDate: ctx.state.input?.dueDate,
      deliveryMethod: ctx.state.input?.deliveryMethod,
      issueDate: ctx.state.input?.issueDate,
      number: '',
      details: [{
        name: '',
        unitPrice: '',
        quantity: '',
        taxRateType: TaxRateTypes[0],
        amount: '',
        taxIncluded: ctx.rootState.seller?.transactionDetailAmountTaxIncluded || false,
      }],
      taxDetails: {
        taxRateType10: '',
        taxRateType8: '',
        taxRateTypeReduced8: '',
        taxRateTypeTransitionalMeasures8: '',
      },
      authorization: null,
    } as TransactionStoreInput)
    ctx.dispatch('calculateClaimUnit')
  },
  addDetailByIndex (ctx: ActionContext<ClaimUnitRegisterState, RootState>, index: number) {
    ctx.commit('setDetailByTransaction', {
      input: {
        name: '',
        unitPrice: '',
        quantity: '',
        taxRateType: TaxRateTypes[0],
        amount: '0',
        taxIncluded: ctx.rootState.seller?.transactionDetailAmountTaxIncluded || false,
      },
      index,
    })
    ctx.dispatch('calculateClaimUnit')
  },
  async calculateClaimUnit (ctx: ActionContext<ClaimUnitRegisterState, RootState>): Promise<void> {
    if (ctx.rootGetters['seller/isOnClaimUnit']) {
      const taxRoundingType = ctx.rootState.seller?.taxRoundingType
      const details = []
      for await (const [ti, t] of ctx.state.transactions.entries()) {
        for await (const [di, d] of t.details.entries()) {
          const result = await calculateDetail(d.unitPrice, d.quantity, taxRoundingType)
          if (d.unitPrice === '' || d.quantity === '') {
            continue
          }
          ctx.commit('setDetailAmountByIndex', { a: result, transactionIndex: ti, detailIndex: di })
          details.push({
            description: d.name,
            unitPrice: d.unitPrice,
            quantity: d.quantity,
            amount: d.amount,
            taxRateType: d.taxRateType.type,
            taxIncluded: d.taxIncluded,
          })
        }
        const result = await calculateTransaction(t.details, taxRoundingType, !!ctx.rootState.seller?.transactionDetailAmountTaxIncluded)
        ctx.commit('setTransactionAmountByIndex', { a: new Big(result.amount).toNumber(), i: ti })
      }

      if (ctx.state.existClaimUnit) {
        details.push(ctx.state.existClaimUnit.transactions?.map<TransactionDetail[]>(t => t.details || []).flat())
        details.push(ctx.state.existClaimUnit.unexaminedTransactions?.map<TransactionDetail[]>(t => t.details || []).flat())
      }

      try {
        const resp = await this.$axios.post('/rest/claimunits/calculateClaimUnitAmount', { details: details.flat(), taxRoundingType })
        ctx.commit('setAmount', resp.data.amount)
        ctx.commit('setAdditionalAmount', resp.data.amount)
        const taxAmounts: ClaimUnitPerTaxRate = {
          taxAmountType8: '0',
          amountType8: '0',
          taxAmountType10: '0',
          amountType10: '0',
          taxAmountReduced8: '0',
          amountReduced8: '0',
          taxAmountTransitionalMeasures8: '0',
          amountTransitionalMeasures8: '0',
        }
        for await (const amount of resp.data.taxAmountsPerTaxRate) {
          const type: TaxRateType = amount.taxRateType
          switch (type) {
            case 'TaxRateType10':
              taxAmounts.amountType10 = amount.amount
              taxAmounts.taxAmountType10 = amount.taxAmount
              break
            case 'TaxRateType8':
              taxAmounts.amountType8 = amount.amount
              taxAmounts.taxAmountType8 = amount.taxAmount
              break
            case 'TaxRateTypeReduced8':
              taxAmounts.amountReduced8 = amount.amount
              taxAmounts.taxAmountReduced8 = amount.taxAmount
              break
            case 'TaxRateTypeTransitionalMeasures8':
              taxAmounts.amountTransitionalMeasures8 = amount.amount
              taxAmounts.taxAmountTransitionalMeasures8 = amount.taxAmount
              break
          }
        }
        ctx.commit('setAmountsPerTaxRate', taxAmounts)
      } catch (e) {
        console.error(e)
      }

      return
    }
    let res: AwaitType<typeof calculate>
    if (ctx.rootGetters['seller/isOnTransaction']) {
      res = await calculateOnTransacstion(ctx.state.transactions, ctx.rootState.seller?.taxRoundingType, !!ctx.rootState.seller?.transactionDetailAmountTaxIncluded, ctx.state.existClaimUnit)
    } else {
      res = await calculate(ctx.state.transactions, ctx.rootState.seller?.taxRoundingType, !!ctx.rootState.seller?.transactionDetailAmountTaxIncluded, ctx.state.existClaimUnit)
    }
    ctx.commit('resetTransactionInput')
    res.transactions.forEach((t) => {
      ctx.commit('setTransactionInput', t)
    })
    ctx.commit('setAmount', res.amount)
    ctx.commit('setAdditionalAmount', res.additionalAmount)
    ctx.commit('setAmountsPerTaxRate', {
      taxAmountType8: res.taxAmountType8,
      amountType8: res.amountType8,
      taxAmountType10: res.taxAmountType10,
      amountType10: res.amountType10,
      taxAmountReduced8: res.taxAmountReduced8,
      amountReduced8: res.amountReduced8,
      taxAmountTransitionalMeasures8: res.taxAmountTransitionalMeasures8,
      amountTransitionalMeasures8: res.amountTransitionalMeasures8,
    } as ClaimUnitPerTaxRate)
  },
}
