import { MutationTree, ActionTree, ActionContext } from 'vuex'
import { CustomerState } from '~/types'
import { Customer } from '~/types/eldamar'

export const state = (): CustomerState => ({
  customer: null,
})

export const mutations: MutationTree<CustomerState> = {
  setCustomer (state: CustomerState, customer: Customer | null): void {
    state.customer = customer
  },
}

export const actions: ActionTree<CustomerState, CustomerState> = {
  reset (ctx: ActionContext<CustomerState, CustomerState>): void {
    ctx.commit('setCustomer', null)
  },
}
