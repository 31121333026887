<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="fill-current"
  >
    <path d="M14 9.71108C14 10.4521 13.7165 11.4588 13.1496 12.7312C13.1362 12.7624 13.1116 12.816 13.0759 12.8919C13.0446 12.9678 13.0156 13.0347 12.9888 13.0928C12.9621 13.1508 12.933 13.1999 12.9018 13.2401C12.8482 13.316 12.7857 13.3539 12.7143 13.3539C12.6473 13.3539 12.5938 13.3316 12.5536 13.287C12.5179 13.2423 12.5 13.1865 12.5 13.1196C12.5 13.0794 12.5045 13.0191 12.5134 12.9388C12.5268 12.8629 12.5335 12.8115 12.5335 12.7847C12.5558 12.4812 12.567 12.2066 12.567 11.9611C12.567 11.5102 12.5268 11.1062 12.4464 10.749C12.3705 10.3919 12.2634 10.0838 12.125 9.82492C11.9866 9.56152 11.808 9.33608 11.5893 9.14858C11.3705 8.95661 11.1339 8.80036 10.8795 8.67983C10.6295 8.55929 10.3326 8.46554 9.98884 8.39858C9.64955 8.32715 9.3058 8.27804 8.95759 8.25125C8.61384 8.22447 8.22321 8.21108 7.78571 8.21108H6.28571V9.92536C6.28571 10.0414 6.2433 10.1419 6.15848 10.2267C6.07366 10.3115 5.97321 10.3539 5.85714 10.3539C5.74107 10.3539 5.64063 10.3115 5.5558 10.2267L2.12723 6.79813C2.04241 6.71331 2 6.61286 2 6.49679C2 6.38072 2.04241 6.28027 2.12723 6.19545L5.5558 2.76688C5.64063 2.68206 5.74107 2.63965 5.85714 2.63965C5.97321 2.63965 6.07366 2.68206 6.15848 2.76688C6.2433 2.8517 6.28571 2.95215 6.28571 3.06822V4.78251H7.78571C10.9688 4.78251 12.9219 5.68206 13.6451 7.48117C13.8817 8.07938 14 8.82268 14 9.71108Z" />
  </svg>
</template>

<script>
export default {
}
</script>
