import { MutationTree, ActionTree, ActionContext } from 'vuex'
import { CustomerRegisterState, Cc, SubContact } from '~/types/registers'
import { BusinessType } from '~/types/eldamar'

export const state = (): CustomerRegisterState => ({
  name: '',
  userDefinedId: '',
  zipcode: '',
  prefecture: '',
  address1: '',
  tel: '',
  subContacts: [{ name: '', tel: '' }],
  email: '',
  ccs: [{ cc: '' }],
  applyAccountTransferApplication: false,
  customerId: '',
})

export const mutations: MutationTree<CustomerRegisterState> = {
  setName (state: CustomerRegisterState, name: string): void {
    state.name = name
  },
  setUserDefinedId (state: CustomerRegisterState, userDefinedId: string): void {
    state.userDefinedId = userDefinedId
  },
  setZipCode (state: CustomerRegisterState, zipcode: string): void {
    state.zipcode = zipcode
  },
  setPrefecture (state: CustomerRegisterState, prefecture: string): void {
    state.prefecture = prefecture
  },
  setAddress1 (state: CustomerRegisterState, address1: string): void {
    state.address1 = address1
  },
  setAddress2 (state: CustomerRegisterState, address2: string): void {
    state.address2 = address2
  },
  setTel (state: CustomerRegisterState, tel: string): void {
    state.tel = tel
  },
  setSubContacts (state: CustomerRegisterState, scs: SubContact[]): void {
    // NOTE:
    // そのままsetしてしまうとscsに入れたobjectを更新できなくなるため詰め直している（更新するとmutation外からのstate操作で怒られる）
    // filed数が少ないのでJSON.pars,stringifyでDeepCopyしていない
    state.subContacts = scs.map((sc) => { return { name: sc.name, tel: sc.tel } })
  },
  setDepartment (state: CustomerRegisterState, department: string): void {
    state.department = department
  },
  setContactName (state: CustomerRegisterState, contactName: string): void {
    state.contactName = contactName
  },
  setContactNameKana (state: CustomerRegisterState, contactNameKana: string): void {
    state.contactNameKana = contactNameKana
  },
  setTttle (state: CustomerRegisterState, title: string): void {
    state.title = title
  },
  setEmail (state: CustomerRegisterState, email: string): void {
    state.email = email
  },
  setCc (state: CustomerRegisterState, cc: Cc): void {
    state.ccs.push(cc)
  },
  setCcs (state: CustomerRegisterState, ccs: Cc[]): void {
    // NOTE:
    // そのままsetしてしまうとccsに入れたobjectを更新できなくなるため詰め直している（更新するとmutation外からのstate操作で怒られる）
    // filed数が少ないのでJSON.pars,stringifyでDeepCopyしていない
    state.ccs = ccs.sort((a, b) => {
      const ccA = a.cc.toUpperCase()
      const ccB = b.cc.toUpperCase()
      if (ccA < ccB) {
        return -1
      }
      if (ccA > ccB) {
        return 1
      }

      return 0
    }).map((c) => { return { cc: c.cc } })
  },
  setApplyAccountTransferApplication (state: CustomerRegisterState, apply: boolean): void {
    state.applyAccountTransferApplication = apply
  },
  setBusinessType (state: CustomerRegisterState, businessType?: BusinessType | ''): void {
    state.businessType = businessType
  },
  setCorporateNumber (state: CustomerRegisterState, corporateNumber: string): void {
    state.corporateNumber = corporateNumber
  },
  setRepresentativeName (state: CustomerRegisterState, representativeName: string): void {
    state.representativeName = representativeName
  },
  setBusinessDescription (state: CustomerRegisterState, businessDescription: string): void {
    state.businessDescription = businessDescription
  },
  setUrl (state: CustomerRegisterState, url: string): void {
    state.url = url
  },
  setRemark (state: CustomerRegisterState, remark: string): void {
    state.remark = remark
  },
  setExpectedAmount (state: CustomerRegisterState, expectedAmount?: number): void {
    state.expectedAmount = expectedAmount
  },
  setInitialExpectedAmount (state: CustomerRegisterState, initialExpectedAmount?: number): void {
    state.initialExpectedAmount = initialExpectedAmount
  },
  setDeadLine (state: CustomerRegisterState, deadline: string): void {
    state.deadline = deadline
  },
  setCustomerId (state: CustomerRegisterState, customerId: string): void {
    state.customerId = customerId
  },
  setReferrerUrl (state: CustomerRegisterState, url?: string): void {
    state.referrerUrl = url
  },
}

export const actions: ActionTree<CustomerRegisterState, CustomerRegisterState> = {
  reset (ctx: ActionContext<CustomerRegisterState, CustomerRegisterState>): void {
    ctx.commit('setName', '')
    ctx.commit('setUserDefinedId', '')
    ctx.commit('setZipCode', '')
    ctx.commit('setPrefecture', '')
    ctx.commit('setAddress1', '')
    ctx.commit('setAddress2', '')
    ctx.commit('setTel', '')
    ctx.commit('setSubContacts', [{ name: '', tel: '' }])
    ctx.commit('setDepartment', '')
    ctx.commit('setContactName', '')
    ctx.commit('setContactNameKana', '')
    ctx.commit('setTttle', '')
    ctx.commit('setEmail', '')
    ctx.commit('setCcs', [{ cc: '' }])
    ctx.commit('setApplyAccountTransferApplication', false)
    ctx.commit('setBusinessType', undefined)
    ctx.commit('setCorporateNumber', '')
    ctx.commit('setRepresentativeName', '')
    ctx.commit('setBusinessDescription', '')
    ctx.commit('setUrl', '')
    ctx.commit('setRemark', '')
    ctx.commit('setExpectedAmount', undefined)
    ctx.commit('setInitialExpectedAmount', undefined)
    ctx.commit('setDeadLine', '')
    ctx.commit('setCustomerId', '')
    ctx.commit('setReferrerUrl', undefined)
  },
}
