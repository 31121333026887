import { MutationTree, ActionTree, ActionContext, GetterTree } from 'vuex'
import { SellerState, RootState } from '~/types'
import { Acl, SellerBasicCreditFacility, SellerQuery, TaxRoundingType, SellerInvoiceModeSettingCalculateType } from '~/types/eldamar'
import query from '~/queries/seller.gql'

export const state = (): SellerState => ({
  sellerId: '',
  sellerName: '',
  teamName: '',
  teamColor: 0,
  transactionDetailAmountTaxIncluded: null,
  postageUnitPrice: 0,
  logoUploaded: true,
  assignment: true,
  memberManagement: false,
  acl: null,
  isInvoiceMode: false,
  invoiceModeCalculateType: 'BySeller',
})

export const getters: GetterTree<SellerState, RootState> = {
  isOnClaimUnit (state: SellerState): boolean {
    return state.invoiceModeCalculateType === 'OnClaimUnit'
  },
  isOnTransaction (state: SellerState): boolean {
    return state.invoiceModeCalculateType === 'OnTransaction'
  },
  isInvoiceMode (state: SellerState): boolean {
    return state.invoiceModeCalculateType === 'OnClaimUnit' || state.invoiceModeCalculateType === 'OnTransaction'
  },
}

export const mutations: MutationTree<SellerState> = {
  setSellerId (state: SellerState, id: string): void {
    state.sellerId = id
  },
  setSellerName (state: SellerState, name: string): void {
    state.sellerName = name
  },
  setTeamName (state: SellerState, name: string): void {
    state.teamName = name
  },
  setTeamColor (state: SellerState, color: number): void {
    state.teamColor = color
  },
  setTransactionDetailAmountTaxIncluded (state: SellerState, included: boolean): void {
    state.transactionDetailAmountTaxIncluded = included
  },
  setTaxRoundingType (state: SellerState, roundingType?: TaxRoundingType): void {
    state.taxRoundingType = roundingType
  },
  setBasicCreditFacility (state: SellerState, basicCreditFacility?: SellerBasicCreditFacility): void {
    state.basicCreditFacility = basicCreditFacility
  },
  setAvailableExpectedMonths (state: SellerState, availableExpectedMonths?: string[]): void {
    state.availableExpectedMonths = availableExpectedMonths
  },
  setPostageUnitPrice (state: SellerState, postageUnitPrice: number): void {
    state.postageUnitPrice = postageUnitPrice
  },
  setAssignment (state: SellerState, assignment: boolean): void {
    state.assignment = assignment
  },
  setLogoUploaded (state: SellerState, logoUploaded: boolean): void {
    state.logoUploaded = logoUploaded
  },
  setMemberManagement (state: SellerState, memberManagement: boolean): void {
    state.memberManagement = memberManagement
  },
  setAcl (state: SellerState, acl: Acl): void {
    state.acl = acl
  },
  setIsInvoiceMode (state: SellerState, isInvoiceMode: boolean): void {
    state.isInvoiceMode = isInvoiceMode
  },
  setInvoiceModeCalculateType (state: SellerState, t: SellerInvoiceModeSettingCalculateType): void {
    state.invoiceModeCalculateType = t
  },
}

export const actions: ActionTree<SellerState, SellerState> = {
  async set (ctx: ActionContext<SellerState, SellerState>): Promise<boolean> {
    const result = await this.app.apolloProvider?.defaultClient?.query<SellerQuery>({
      query,
    })
    if (!result?.data) {
      return false
    }
    const { seller, team, acl } = result.data
    if (!seller) {
      return false
    }
    ctx.commit('setSellerId', seller.id)
    ctx.commit('setAssignment', seller.assignment)
    ctx.commit('setMemberManagement', seller.memberManagement)
    ctx.commit('setLogoUploaded', seller.logoUploaded)
    if (seller.office) {
      ctx.commit('setSellerName', seller.office.name)
    }
    ctx.commit('setTeamName', team?.name || '')
    ctx.commit('setTeamColor', team?.color || 0)
    if (acl) {
      ctx.commit('setAcl', acl)
    }
    if (seller.invoiceModeSetting) {
      ctx.commit(
        'setIsInvoiceMode',
        seller.invoiceModeSetting.calculateType === 'OnClaimUnit' || seller.invoiceModeSetting.calculateType === 'OnTransaction',
      )
      ctx.commit('setInvoiceModeCalculateType', seller.invoiceModeSetting.calculateType)
    }
    return true
  },
  getDefaultPage (ctx: ActionContext<SellerState, SellerState>): Promise<string> {
    if (ctx.state.assignment) {
      return Promise.resolve('/a/transactions')
    }
    return Promise.resolve('/a/claimunits')
  },
}
