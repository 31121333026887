/* eslint-env node */
/* globals RequestInfo, RequestInit */
import * as http from 'http'
import * as Sentry from '@sentry/browser'
import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory'
import { Context } from '@nuxt/types'
import { gzip } from 'pako'
import introspectionQueryResultData from '../introspection-result'

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData,
})

// リクエストボディがシンプルな文字列の場合はgzip圧縮する
const compress = (f: typeof fetch): typeof fetch => (uri: RequestInfo, options?: RequestInit): Promise<Response> => {
  if (typeof options?.body === 'string') {
    // headerの構造に合わせて'content-encoding'を設定
    if (!options.headers) {
      options.headers = { 'content-encoding': 'gzip' }
    } else if (Array.isArray(options.headers)) {
      options.headers.push(['content-encoding', 'gzip'])
    } else {
      (options.headers as Record<string, string>)['content-encoding'] = 'gzip'
    }
    options.body = gzip(options.body)
  }

  return f(uri, options)
}

// レスポンスステータスが5xxならsentryでエラー通知する
const sentry = (f: typeof fetch): typeof fetch => {
  return async (uri: RequestInfo, options?: RequestInit): Promise<Response> => {
    const res = await f(uri, options)
    if (res.status >= 400) {
      if (isCSVUpload()) {
        // ユーザには影響のないアップロード時のタイムアウトなのでsentryには通知しない
        return res
      }
      const res2 = res.clone()
      const text = await res2.text()
      Sentry.captureMessage(text, (scope) => {
        scope.setLevel('error')
        scope.setTags({
          ok: res2.ok.toString(),
          status: res2.status.toString(),
        })
        scope.setContext('apollo', {
          ok: res2.ok.toString(),
          status: res2.status.toString(),
          text,
        })
        return scope
      })
    }
    return res
  }
}

// リクエストにclientIPのヘッダを付加する
const clientIP = (f: typeof fetch, req: http.IncomingMessage): typeof fetch => (uri: RequestInfo, options?: RequestInit): Promise<Response> => {
  if (req && options) {
    const xForwardedFor = getHeaderString(req.headers['x-forwarded-for'])
    const cfConnectingIP = getHeaderString(req.headers['cf-connecting-ip'])

    if (!options.headers) {
      options.headers = {
        'x-forwarded-for': xForwardedFor,
        'cf-connecting-ip': cfConnectingIP,
      }
    } else if (Array.isArray(options.headers)) {
      options.headers.push(['x-forwarded-for', xForwardedFor])
      options.headers.push(['cf-connecting-ip', cfConnectingIP])
    } else {
      (options.headers as Record<string, string>)['x-forwarded-for'] = xForwardedFor;
      (options.headers as Record<string, string>)['cf-connecting-ip'] = cfConnectingIP
    }
  }

  return f(uri, options)
}

const getHeaderString = (header: string | string[] | undefined): string => {
  if (!header) {
    return ''
  }
  if (typeof header === 'string') {
    return header
  } else if (Array.isArray(header)) {
    return header.join(',')
  } else {
    return ''
  }
}

const isCSVUpload = (): boolean => {
  if (!location || !location.pathname) {
    return false
  }

  switch (location.pathname) {
    case '/a/transactions/csv/confirm':
    case '/a/transactions/csv/detail/confirm':
    case '/a/transactions/csv/mfcinvoice/confirm':
      return true
    default:
      return false
  }
}

export default ({
  req,
  nuxtState,
}: Context): { [key: string]: any } => { // eslint-disable-line @typescript-eslint/no-explicit-any
  // fetch関数をmiddleware的にラップする
  let fetchFunc = fetch
  fetchFunc = compress(fetchFunc)
  fetchFunc = sentry(fetchFunc)
  fetchFunc = clientIP(fetchFunc, req)

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { env } = req as any || nuxtState
  return {
    httpEndpoint: `http://${env.ELDAMAR_DOMAIN}/graphql`,
    browserHttpEndpoint: '/graphql',
    // optional
    // See https://www.apollographql.com/docs/link/links/http.html#options
    httpLinkOptions: { fetch: fetchFunc },
    cache: new InMemoryCache({ fragmentMatcher }),
    apollo: {
      defaultOptions: {
        watchQuery: {
          fetchPolicy: 'network-only',
        },
        query: {
          fetchPolicy: 'network-only',
        },
        mutate: {
          fetchPolicy: 'no-cache',
        },
      },
    },
  }
}
