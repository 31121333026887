import { MutationTree, ActionTree, ActionContext } from 'vuex'
import { TransactionsCSVRegisterState, ParseResult, ParseInvalidResponse, TransactionDetailCSVParseResult, TransactionDetailParseInvalidResponse } from '~/types/registers'

export const state = (): TransactionsCSVRegisterState => ({
  setting: null,
  transactionDetailError: [],
})

export const mutations: MutationTree<TransactionsCSVRegisterState> = {
  setSetting (state: TransactionsCSVRegisterState, setting: TransactionsCSVRegisterState['setting']): void {
    state.setting = setting
  },
  setParseResult (state: TransactionsCSVRegisterState, result?: ParseResult): void {
    state.parseResult = result
  },
  setParseValidationError (state: TransactionsCSVRegisterState, resp?: ParseInvalidResponse[]): void {
    state.parseValidationError = resp
  },
  setTransactionDetailParseResult (state: TransactionsCSVRegisterState, result?: TransactionDetailCSVParseResult): void {
    state.transactionDetailParseResult = result
  },
  setTransactionDetailError (state: TransactionsCSVRegisterState, resp: TransactionDetailParseInvalidResponse[]): void {
    state.transactionDetailError = resp
  },
}

export const actions: ActionTree<TransactionsCSVRegisterState, TransactionsCSVRegisterState> = {
  reset (ctx: ActionContext<TransactionsCSVRegisterState, TransactionsCSVRegisterState>): void {
    ctx.commit('setSetting', null)
    ctx.commit('setParseResult', undefined)
    ctx.commit('setParseValidationError', undefined)
    ctx.commit('setTransactionDetailParseResult', undefined)
    ctx.commit('setTransactionDetailError', [])
  },
}
