
import { Component, Emit, namespace, Vue } from 'nuxt-property-decorator'
import MiniBase from './mini-base.vue'
import { Acl } from '~/types/eldamar'

const sellerStore = namespace('seller')

@Component({
  components: { MiniBase },
  props: {
    prefix: String,
    primary: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    href: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
})
export default class SettingEditBtn extends Vue {
  @sellerStore.State
  private readonly acl!: Acl | null

  get isAccept (): boolean {
    return this.acl?.settingEdit ? this.acl.settingEdit : false
  }

  @Emit('click')
  private onClick (e: Event): Event {
    return e
  }
}
