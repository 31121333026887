import { MutationTree, ActionTree, ActionContext } from 'vuex'
import { BankTransferState } from '~/types'
import { ScheduledOrFixedBankTransfer } from '~/types/eldamar'

export const state = (): BankTransferState => ({
})

export const mutations: MutationTree<BankTransferState> = {
  setBankTransfer (state: BankTransferState, bankTransfer?: ScheduledOrFixedBankTransfer): void {
    state.bankTransfer = bankTransfer
  },
}

export const actions: ActionTree<BankTransferState, BankTransferState> = {
  reset (ctx: ActionContext<BankTransferState, BankTransferState>): void {
    ctx.commit('setBankTransfer', undefined)
  },
}
