
import { Component, Vue, namespace } from 'nuxt-property-decorator'
import TransactionIcon from '~/components/element/icon/transaction-icon.vue'

const sellerStore = namespace('seller')

@Component({
  props: {
    isMenuCollapsed: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    TransactionIcon,
  },
})
export default class extends Vue {
  @sellerStore.State('assignment')
  private readonly isAssignment!: boolean

  private close () {
    this.$emit('click')
  }
}
