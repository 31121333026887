import { MutationTree, ActionTree, ActionContext } from 'vuex'
import { ClaimUnitState } from '~/types'
import { ClaimUnit } from '~/types/eldamar'

export const state = (): ClaimUnitState => ({
  reconciliationUpdateDate: null,
})

export const mutations: MutationTree<ClaimUnitState> = {
  setClaimUnit (state: ClaimUnitState, claimUnit?: ClaimUnit): void {
    state.claimUnit = claimUnit
  },
  setReconciliationUpdateDate (state: ClaimUnitState, date: Date | null): void {
    state.reconciliationUpdateDate = date
  },
}

export const actions: ActionTree<ClaimUnitState, ClaimUnitState> = {
  reset (ctx: ActionContext<ClaimUnitState, ClaimUnitState>): void {
    ctx.commit('setClaimUnit', undefined)
  },
}
