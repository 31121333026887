import { Context } from '@nuxt/types'
import { emitError } from '~/utils/nuxt-helper'

export default async function (context: Context): Promise<void> {
  try {
    await context.store.dispatch('seller/set', null, { root: true })
  } catch (e) {
    emitError(context, e)
  }
}
