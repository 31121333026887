import { NuxtApp, Context } from '@nuxt/types/app'
import { isApolloError } from 'apollo-client'
import { GraphQLError } from 'graphql'
import { AxiosError } from 'axios'
import cookie, { serialize } from 'cookie'
import { Scope } from '@sentry/core'
import { FirebaseError } from 'firebase/app'
import { AuthErrorCodes, MultiFactorError } from 'firebase/auth'
import { NetworkError } from '~/types'
import { Msg } from '~/types/notifications'

const UNAUTHORIZED = 'unauthorized'

function isContext (ref: NuxtApp | Context): ref is Context {
  return 'app' in ref
}

function showErrorPage (app: NuxtApp | Context, statusCode: number): void {
  if (isContext(app)) {
    app.error({
      statusCode,
    })
    return
  }

  app.$nuxt.error({
    statusCode,
  })
}

const isUnauthorized = (errs: ReadonlyArray<GraphQLError>): boolean => {
  const s = errs.some((err) => {
    if (err.extensions) {
      return err.extensions.code === UNAUTHORIZED
    }
  })
  return s
}

const isSuspendedError = (errs: ReadonlyArray<GraphQLError>): boolean => {
  const s = errs.some((err) => {
    if (err.extensions) {
      return err.extensions.code === 'unprocessable_entity_sus'
    }
  })

  return s
}

export function setCookie (app: Context | NuxtApp, key: string, value: string): void {
  if (isContext(app) && process.server) {
    const cookieHeader = app.res.getHeader('Set-Cookie') || []
    if (Array.isArray(cookieHeader)) {
      cookieHeader.unshift(serialize(key, value))
      const t = cookieHeader
        .filter((v, i, arr) => arr.findIndex(val => val.startsWith(v.substr(0, v.indexOf('=')))) === i)
      app.res.setHeader('Set-Cookie', t.concat(['Secure', 'Path=/']).join('; '))
    }
    return
  }
  document.cookie = serialize(key, value) + ';Secure;Path=/'
}

function removeCookie (app: Context | NuxtApp, key: string): void {
  if (isContext(app)) {
    const cookieHeader = app.res.getHeader('Set-Cookie') || []
    if (Array.isArray(cookieHeader)) {
      cookieHeader.unshift(serialize(key, ''))
      app.res.setHeader('Set-Cookie', cookieHeader
        .filter((v, i, arr) => arr.findIndex(val => val.startsWith(v.substr(0, v.indexOf('=')))) === i) + ';max-age=0')
    }
    return
  }
  document.cookie = serialize(key, '') + ';max-age=0'
}

export function removeRedirectFrom (app: Context | NuxtApp): void {
  removeCookie(app, 'mfk_redirectFrom')
  if (isContext(app)) {
    app.store.commit('setRedirectFrom', '')
  } else {
    app.$store.commit('setRedirectFrom', '')
  }
}

export function getCookie (app: Context | NuxtApp, key: string): string {
  if (isContext(app) && app.req.headers.cookie) {
    return cookie.parse(app.req.headers.cookie)[key]
  } else if (document.cookie) {
    return cookie.parse(document.cookie)[key]
  }

  return ''
}

export function getRedirectFrom (app: NuxtApp | Context): string {
  let from = getCookie(app, 'mfk_redirectFrom')

  if (isContext(app)) {
    if (!from) { from = app.store.state.redirectFrom }
  } else if (!from) { from = app.$store.state.redirectFrom }

  if (isBlockList(from)) {
    return ''
  }

  return from
}

function isBlockList (path: string): boolean {
  return path.includes('confirm') && path.includes('new/')
}

function reloadOrRedirectToSignin (ctx: Context): void {
  if (process.client) {
    location.reload()
  } else {
    ctx.redirect('/signin')
  }
}

export function emitError (app: NuxtApp | Context, e: Error | unknown, silently?: boolean): void {
  const app1 = isContext(app) ? app.app : app

  try {
    const err = e as Error
    if (isApolloError(err)) {
    // TODO: 400系のエラーハンドリング

      // 認証エラー
      if (isUnauthorized(err.graphQLErrors) || isGraphQLForbiddenError(err)) {
        if (isContext(app)) {
          setCookie(app, 'mfk_redirectFrom', app.route.fullPath)
          reloadOrRedirectToSignin(app)
          return
        }
        setCookie(app, 'mfk_redirectFrom', app.$route.fullPath)
        reloadOrRedirectToSignin(app.context)
        return
      }

      if (isSuspendedError(err.graphQLErrors)) {
        app1.$store.commit('notifications/setMessage', {
          type: 'error',
          message: '登録に失敗しました。',
        } as Msg, { root: true })
        app1.$store.dispatch('seller/getDefaultPage', null, { root: true }).then((path: string) => {
          app1.$router.replace(path)
        })
        return
      }

      if (err.networkError) {
        showErrorPage(app, -1)
        return
      }
    }

    if (isAxiosError(err)) {
      if (err.response?.status === 401) {
        if (isContext(app)) {
          setCookie(app, 'mfk_redirectFrom', app.route.fullPath)
          reloadOrRedirectToSignin(app)
          return
        }
        setCookie(app, 'mfk_redirectFrom', app.$route.fullPath)
        reloadOrRedirectToSignin(app.context)
        return
      }
    }

    if (isFirebaseError(e)) {
      // firebase error 情報がうすすぎるのでなんとかして出力したいやつ
      const scope = new Scope()
      scope.setExtra('message-stringify', JSON.stringify(e))
      app1?.$sentry?.captureException && app1.$sentry.captureException(e, scope)
    } else if (app1?.$sentry?.captureException) {
      // Sentryに通知する
      app1.$sentry.captureException(e)
    }

    // silently = trueならエラーページに飛ばさない
    if (silently) {
      return
    }

    showErrorPage(app, 500)
  } catch (e1) {
    if (app1?.$sentry?.captureException) {
      const scope = new Scope()
      scope.setExtra('originalException', e)
      app1.$sentry.captureException(new Error(`emitError failed ${e1}`), scope)
    }
  }
}

export function isGraphQLNotFoundError (e: Error): boolean {
  if (isApolloError(e)) {
    return e.graphQLErrors.some((ge) => {
      return ge.extensions?.code === 'not_found'
    })
  }
  return false
}

export function isGraphQLForbiddenError (e: Error): boolean {
  if (isApolloError(e)) {
    return e.graphQLErrors.some((ge) => {
      return ge.extensions?.code === 'forbidden'
    })
  }
  return false
}

export function redirect (context: Context, path: string): void {
  let p = path
  if (context.route.fullPath.includes('?')) {
    p += context.route.fullPath.substr(context.route.fullPath.indexOf('?'))
  }
  context.redirect(p)
}

export function scrollToErrorComponent (app: NuxtApp, el?: Element): void {
  app.$nextTick(() => {
    const d = document.querySelector('.border-warning') || document.querySelector('.error-component')
    if (!d) {
      return
    }

    const domRect = d.getBoundingClientRect()
    let l = 0
    let t = 0
    let w: Element | undefined | null | Window = el || app.$root.$el.querySelector('[data-main-container]')
    if (!w) {
      w = window
      l = document.documentElement.scrollLeft
      t = document.documentElement.scrollTop
    } else {
      l = w.scrollLeft
      t = w.scrollTop
    }

    w.scrollTo(
      domRect.left + l,
      domRect.top + t - 96, // headerの高さ+1文字分
    )
  })
}

export function scrollToTop (app: NuxtApp, el?: Element): void {
  app.$nextTick(() => {
    let w: Element | undefined | null | Window = el || app.$root.$el.querySelector('[data-main-container]')
    if (!w) {
      w = window
    }

    w.scrollTo(0, 0)
  })
}

export function isAxiosError (e: Error): e is AxiosError {
  return !!(e as AxiosError).isAxiosError
}

export function isFirebaseError (e: unknown): e is FirebaseError {
  if (!e) { return false }
  return !!(e as FirebaseError).code
}

export function isFirebaseMultiFactorAuthError (e: unknown): e is MultiFactorError {
  if (!e) { return false }
  return (e as MultiFactorError).code === AuthErrorCodes.MFA_REQUIRED
}

export function isNetworkError (e?: Error | null): e is NetworkError {
  if (!e) { return false }
  return !!(e as NetworkError).statusCode
}

export function isTimeoutError (e?: Error | null): boolean {
  if (!isNetworkError(e)) {
    return false
  }

  return e.statusCode === 502 || e.statusCode === 503 || e.statusCode === 504
}

export function getQuery (target: string | (string | null)[]): string | null {
  if (Array.isArray(target)) {
    return target[0]
  }

  return target
}
