import { Context } from '@nuxt/types'
import { AxiosError } from 'axios'
import authQuery from '~/queries/auth.gql'
import { AuthQuery } from '~/types/eldamar'

export default async function ({ route, app, store }: Context): Promise<void> {
  if (app.$env.REQUEST_LOGGING !== 'true') {
    return
  }

  // クライアントサイドの画面遷移時は/loggerエンドポイントにログデータ送ることでリクエストログを残す
  if (process.client) {
    let sellerId = store.state.seller.sellerId
    if (!sellerId) {
      try {
        const c = app.apolloProvider?.defaultClient
        if (c) {
          const res = await c.query<AuthQuery>({
            query: authQuery,
          })
          sellerId = res.data.seller?.id
          if (sellerId) { store.commit('seller/setSellerId', sellerId) }
        }
      } catch (e) { /* 握りつぶし */ }
    }
    const logData = {
      url: route.fullPath,
      sellerId,
    }
    app.$axios.post('/logger', logData).catch((e: AxiosError) => {
      console.log(`error: ${e}`)
    })
  }

  // SSR時はservermiddlewareで対処します
  // if (process.server) {
  // }
}
