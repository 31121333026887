import { MutationTree, ActionTree, ActionContext } from 'vuex'
import { RegisterCustomerNameUpdateRequestReason } from '~/types/eldamar'

interface CustomerNameUpdateState {
  currentName: string
  newName: string
  reason: RegisterCustomerNameUpdateRequestReason
  otherReason: string
  url: string
  file: File | null
}

export const state = (): CustomerNameUpdateState => ({
  currentName: '',
  newName: '',
  reason: 'CompanyNameChange',
  otherReason: '',
  url: '',
  file: null,
})

export const mutations: MutationTree<CustomerNameUpdateState> = {
  setCurrentName (state: CustomerNameUpdateState, currentName: string): void {
    state.currentName = currentName
  },
  setNewName (state: CustomerNameUpdateState, newName: string): void {
    state.newName = newName
  },
  setReason (state: CustomerNameUpdateState, reason: RegisterCustomerNameUpdateRequestReason): void {
    state.reason = reason
  },
  setOtherReason (state: CustomerNameUpdateState, otherReason: string): void {
    state.otherReason = otherReason
  },
  setUrl (state: CustomerNameUpdateState, url: string): void {
    state.url = url
  },
  setFile (state: CustomerNameUpdateState, file: File | null): void {
    state.file = file
  },
}

export const actions: ActionTree<CustomerNameUpdateState, CustomerNameUpdateState> = {
  reset (ctx: ActionContext<CustomerNameUpdateState, CustomerNameUpdateState>): void {
    ctx.commit('setCurrentName', '')
    ctx.commit('setNewName', '')
    ctx.commit('setOtherReason', '')
    ctx.commit('setUrl', '')
    ctx.commit('setReason', 'CompanyNameChange')
    ctx.commit('setFile', null)
  },
}
