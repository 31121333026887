import { MutationTree, ActionTree, ActionContext } from 'vuex'
import { TransactionRegisterState, TransactionStoreInput } from '~/types/registers'
import { Customer } from '~/types/eldamar'

export const state = (): TransactionRegisterState => ({
  customerUserDefinedId: null,
})

export const mutations: MutationTree<TransactionRegisterState> = {
  setTransaction (state: TransactionRegisterState, t?: TransactionStoreInput): void {
    state.transaction = t
  },
  setCustomer (state: TransactionRegisterState, c?: Customer): void {
    state.customer = c
  },
  setCustomerUserDefinedId (state: TransactionRegisterState, cId: string | null): void {
    state.customerUserDefinedId = cId
  },
  setId (state: TransactionRegisterState, id?: string): void {
    state.id = id
  },
}

export const actions: ActionTree<TransactionRegisterState, TransactionRegisterState> = {
  reset (ctx: ActionContext<TransactionRegisterState, TransactionRegisterState>): void {
    ctx.commit('setTransaction', undefined)
    ctx.commit('setCustomer', undefined)
    ctx.commit('setId', undefined)
    ctx.commit('setCustomerUserDefinedId', null)
  },
}
