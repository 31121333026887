import { Plugin } from '@nuxt/types'
import { NuxtApp } from '@nuxt/types/app'
import { HelpLinkKey, links } from './constants'
import { ServiceProviderType } from '~/types'

const func: Plugin = (_ctx, inject) => {
  inject('supportLink', function (this: NuxtApp, key: HelpLinkKey) {
    const k: ServiceProviderType = this.$store.state.serviceProvider.type ? this.$store.state.serviceProvider.type : 'mfk'
    const linkSet = links[k]
    return this.$store.state.seller.assignment ? linkSet.assigned[key] : linkSet.unassigned[key]
  })
}

export default func
