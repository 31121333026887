import { Context } from '@nuxt/types'
import { GraphQLError } from 'graphql'
import { isApolloError } from 'apollo-client'
import authQuery from '~/queries/auth.gql'
import { AuthQuery } from '~/types/eldamar'
import { emitError, isGraphQLForbiddenError } from '~/utils/nuxt-helper'

const UNAUTHORIZED = 'unauthorized'

const isUnauthorized = (errs: ReadonlyArray<GraphQLError>): boolean => {
  const s = errs.some((err) => {
    if (err.extensions) {
      return err.extensions.code === UNAUTHORIZED
    }
  })
  return s
}

export default async function (context: Context): Promise<void> {
  if (process.client) {
    return
  }
  const c = context.app.apolloProvider?.defaultClient
  if (c) {
    try {
      await c.query<AuthQuery>({
        query: authQuery,
      })
    } catch (e) {
      if (!isApolloError(e)) {
        emitError(context, e)
        return
      }
      if (isUnauthorized(e.graphQLErrors) || isGraphQLForbiddenError(e)) {
        return
      }
      emitError(context, e)
      return
    }
    try {
      const defaultPage = await context.store.dispatch('seller/getDefaultPage', null, { root: true })
      context.redirect(defaultPage)
    } catch (e) {
      emitError(context, e)
    }
  }
}
