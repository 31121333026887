import { MutationTree, ActionTree, ActionContext } from 'vuex'
import { ClaimUnitsDestinationUpdateState } from '~/types/registers'
import { Destination } from '~/types/eldamar'

export const state = (): ClaimUnitsDestinationUpdateState => ({
})

export const mutations: MutationTree<ClaimUnitsDestinationUpdateState> = {
  setDestination (state: ClaimUnitsDestinationUpdateState, destination: Destination): void {
    state.destination = destination
  },
}

export const actions: ActionTree<ClaimUnitsDestinationUpdateState, ClaimUnitsDestinationUpdateState> = {
  reset (ctx: ActionContext<ClaimUnitsDestinationUpdateState, ClaimUnitsDestinationUpdateState>): void {
    ctx.commit('setDestination', undefined)
  },
}
