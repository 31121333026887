
import { Component, State, Vue } from 'nuxt-property-decorator'
import HoverMenu from '~/components/layout/menu/hover-menu.vue'
import AccountIcon from '~/components/element/icon/account.vue'

@Component({
  components: {
    AccountIcon,
    HoverMenu,
  },
})
export default class extends Vue {
  @State
  private readonly userEmail!: string | null

  private reset (): void {
    this.$emit('reset')
  }

  get email (): string {
    return this.userEmail || ''
  }
}
