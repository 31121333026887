
import { Component, Prop, Vue } from 'nuxt-property-decorator'
import { createPopper, Instance } from '@popperjs/core'
import { isHTMLObject } from '~/utils/vue-helper'

@Component
export default class extends Vue {
  @Prop({ default: '' })
  private readonly prefix!: string

  @Prop({ default: '' })
  private readonly rootPath!: string

  @Prop({ default: false })
  private readonly collapse!: boolean

  @Prop({ default: '' })
  private readonly contentWidth!: string

  private isActive = false
  private instance: Instance | null = null

  get contentWidthClass (): string {
    return this.contentWidth ? this.contentWidth : 'w-40'
  }

  private openPopper (): void {
    this.isActive = true
    this.$nextTick(() => {
      const refs = this.$refs[`${this.prefix}-target`]
      const ref = Array.isArray(refs) ? refs[0] : refs
      const pops = this.$refs[`${this.prefix}-popover`]
      const pop = Array.isArray(pops) ? pops[0] : pops
      if (ref && isHTMLObject(ref) && pop && isHTMLObject(pop)) {
        this.instance = createPopper(ref, pop, {
          placement: window.matchMedia('(min-width: 768px)').matches ? 'right-start' : 'bottom',
        })
      }
    })
  }

  private closePopper (): void {
    this.isActive = false
    if (this.instance) {
      this.instance.destroy()
      this.instance = null
    }
  }
}
