
import { Component, Prop, Vue } from 'nuxt-property-decorator'
import SectionTitle from './title.vue'

@Component({
  components: {
    SectionTitle,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
  },
})
export default class SectionBlock extends Vue {
  @Prop({ default: '' })
  private readonly position!: 'top' | 'middle' | 'bottom' | ''

  @Prop({ default: '' })
  private readonly title!: string

  get borderClass (): string {
    const res: string[] = []
    switch (this.position) {
      case 'top':
        res.push('rounded-t')
        break
      case 'middle':
        res.push('border-t-0')
        break
      case 'bottom':
        res.push('rounded-b')
        res.push('border-t-0')
        break
      default:
        res.push('rounded')
    }

    return res.join(' ')
  }

  get isTitleRounded (): boolean {
    return this.position === 'top' || this.position === ''
  }

  get hasTitle (): boolean {
    return !!this.title
  }
}
