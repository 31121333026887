import Vue from 'vue'
import VCalendar from 'v-calendar'

Vue.use(VCalendar, {
  locale: 'ja',
  firstDayOfWeek: 1,
  masks: {
    title: 'YYYY年 MMMM',
    weekdays: 'W',
    navMonths: 'MMM',
    input: ['YYYY/MM/DD'],
    dayPopover: 'YYYY年MM月DD日(W)',
    data: ['YYYY/MM/DD'],
  },
  datePicker: {
    popover: {
      visibility: 'focus',
    },
  },
})
// import { DatePicker, setupCalendar } from 'v-calendar'
// try {
//   setupCalendar({
//     locale: 'ja',
//     firstDayOfWeek: 1,
//     formats: {
//       title: 'YYYY MMMM',
//       weekdays: 'W',
//       navMonths: 'MMM',
//       input: ['YYYY/MM/DD'],
//       dayPopover: 'YYYY年MM月DD日(W)',
//       data: ['YYYY/MM/DD'],
//     },
//     datePickerTintColor: '#d0ae22',
//     popoverVisibility: 'focus',
//   })
// } catch (e) {/*握りつぶし*/}

// Vue.component('v-date-picker', DatePicker)
