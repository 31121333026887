
import { Component, Prop, Vue } from 'nuxt-property-decorator'

@Component
export default class extends Vue {
  @Prop({ required: true })
  private readonly num!: number

  @Prop()
  private readonly done?: boolean

  get hasDone (): boolean {
    return !!this.done
  }
}
