<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="fill-current"
  >
    <path fill-rule="evenodd" clip-rule="evenodd" d="M7.32699 5.33325C7.32699 6.43992 6.44033 7.33325 5.33366 7.33325C4.22699 7.33325 3.33366 6.43992 3.33366 5.33325C3.33366 4.22659 4.22699 3.33325 5.33366 3.33325C6.44033 3.33325 7.32699 4.22659 7.32699 5.33325ZM12.6603 5.33325C12.6603 6.43992 11.7737 7.33325 10.667 7.33325C9.56033 7.33325 8.66699 6.43992 8.66699 5.33325C8.66699 4.22659 9.56033 3.33325 10.667 3.33325C11.7737 3.33325 12.6603 4.22659 12.6603 5.33325ZM5.33366 8.66659C3.78033 8.66659 0.666992 9.44659 0.666992 10.9999V12.6666H10.0003V10.9999C10.0003 9.44659 6.88699 8.66659 5.33366 8.66659ZM10.0203 8.69992C10.2537 8.67992 10.4737 8.66659 10.667 8.66659C12.2203 8.66659 15.3337 9.44659 15.3337 10.9999V12.6666H11.3337V10.9999C11.3337 10.0133 10.7937 9.25992 10.0203 8.69992Z" />
  </svg>
</template>

<script>
export default {
}
</script>
