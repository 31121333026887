<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="fill-current"
  >
    <path fill-rule="evenodd" clip-rule="evenodd" d="M13.0018 7.99967C13.0018 8.22634 12.9818 8.43967 12.9552 8.65301L14.3618 9.75301C14.4885 9.85301 14.5218 10.033 14.4418 10.1797L13.1085 12.4863C13.0285 12.633 12.8552 12.693 12.7018 12.633L11.0418 11.9663C10.6952 12.2263 10.3218 12.453 9.91518 12.6197L9.66185 14.3863C9.64185 14.5463 9.50185 14.6663 9.33518 14.6663H6.66851C6.50185 14.6663 6.36185 14.5463 6.34185 14.3863L6.08851 12.6197C5.68185 12.453 5.30851 12.233 4.96185 11.9663L3.30185 12.633C3.15518 12.6863 2.97518 12.633 2.89518 12.4863L1.56185 10.1797C1.48185 10.033 1.51518 9.85301 1.64185 9.75301L3.04851 8.65301C3.02185 8.43967 3.00185 8.21967 3.00185 7.99967C3.00185 7.77967 3.02185 7.55967 3.04851 7.34634L1.64185 6.24634C1.51518 6.14634 1.47518 5.96634 1.56185 5.81967L2.89518 3.51301C2.97518 3.36634 3.14851 3.30634 3.30185 3.36634L4.96185 4.03301C5.30851 3.77301 5.68185 3.54634 6.08851 3.37967L6.34185 1.61301C6.36185 1.45301 6.50185 1.33301 6.66851 1.33301H9.33518C9.50185 1.33301 9.64185 1.45301 9.66185 1.61301L9.91518 3.37967C10.3218 3.54634 10.6952 3.76634 11.0418 4.03301L12.7018 3.36634C12.8485 3.31301 13.0285 3.36634 13.1085 3.51301L14.4418 5.81967C14.5218 5.96634 14.4885 6.14634 14.3618 6.24634L12.9552 7.34634C12.9818 7.55967 13.0018 7.77301 13.0018 7.99967ZM5.66851 7.99967C5.66851 9.28634 6.71518 10.333 8.00185 10.333C9.28851 10.333 10.3352 9.28634 10.3352 7.99967C10.3352 6.71301 9.28851 5.66634 8.00185 5.66634C6.71518 5.66634 5.66851 6.71301 5.66851 7.99967Z" />
  </svg>
</template>

<script>
export default {
}
</script>
