
import { Component, State, Vue } from 'nuxt-property-decorator'

@Component
export default class extends Vue {
  @State
  private readonly logo!: string

  @State
  private readonly serviceName!: string
}
