import { MutationTree, ActionTree, ActionContext } from 'vuex'
import { TourSignupProgressState } from '~/types/registers'
import { SignupProgress } from '~/types/eldamar'

export const state = (): TourSignupProgressState => ({
  bank: false,
  logo: false,
})

export const mutations: MutationTree<TourSignupProgressState> = {
  setSignupProgress (state: TourSignupProgressState, signupProgress: SignupProgress | null | undefined): void {
    state.bank = !!signupProgress?.bank
    state.logo = !!signupProgress?.logo
  },
}

export const actions: ActionTree<TourSignupProgressState, TourSignupProgressState> = {
  reset (ctx: ActionContext<TourSignupProgressState, TourSignupProgressState>): void {
    ctx.commit('setSignupProgress', {
      bank: false,
      logo: false,
    })
  },
}
