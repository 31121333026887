
import { Component, Vue } from 'nuxt-property-decorator'
import SectionBlock from '~/components/layout/section/block.vue'
import LinkIcon from '~/components/element/icon/link.vue'
import { ServiceProviderType } from '~/types'

@Component({
  components: {
    SectionBlock,
    LinkIcon,
  },
})
export default class Oshirase extends Vue {
  private readonly oshiraseId = 'oshirase-202402-20240206120000'
  private readonly mfkImageUrl = ''
  private readonly bfwImageUrl = ''
  private readonly mfkDetailUrl = 'https://support.mfkessai.co.jp/hc/ja/articles/28284021371801'
  private readonly bfwDetailUrl = 'https://support.bizforward.co.jp/hc/ja/articles/28284371031577'

  // リストのURLは固定
  private readonly mfkListUrl = 'https://support.mfkessai.co.jp/hc/ja/categories/360001145294-%E3%81%8A%E7%9F%A5%E3%82%89%E3%81%9B'
  private readonly bfwListUrl = 'https://support.bizforward.co.jp/hc/ja/categories/4407831440281-%E3%81%8A%E7%9F%A5%E3%82%89%E3%81%9B'

  get imageUrl (): string {
    return this.isMFK ? this.mfkImageUrl : this.bfwImageUrl
  }

  get detailUrl (): string {
    return this.isMFK ? this.mfkDetailUrl : this.bfwDetailUrl
  }

  get listUrl (): string {
    return this.isMFK ? this.mfkListUrl : this.bfwListUrl
  }

  get isMFK (): boolean {
    const k: ServiceProviderType = this.$store.state.serviceProvider.type ? this.$store.state.serviceProvider.type : 'mfk'
    return k === 'mfk'
  }

  mounted (): void {
    const id = window.localStorage.getItem('oshiraseId')
    if (id && id === this.oshiraseId) {
      this.$emit('arrival', false)
    } else {
      this.$emit('arrival', true)
    }

    this.$on('open', () => {
      this.onOpen()
    })
  }

  private onOpen (): void {
    window.localStorage.setItem('oshiraseId', this.oshiraseId)
    this.$emit('arrival', false)
  }

  private close (): void {
    this.$emit('close')
  }
}
