
import Vue from 'vue'

import { library, config } from '@fortawesome/fontawesome-svg-core'
import { faSignal, faSyncAlt, faQuestionCircle, faQuestion, faUniversity, faCog, faReply, faCodeBranch, faEllipsisH, faTimes, faExclamation, faChevronDown, faCheck, faCaretDown, faCaretUp, faSpinner, faSearch, faPaperPlane, faArrowLeft, faArrowRight, faEnvelope, faPhoneAlt, faFileUpload, faSort, faPlus, faYenSign, faDownload, faUserCircle, faCopy, faTimesCircle, faPlusCircle, faCaretRight } from '@fortawesome/free-solid-svg-icons'
import { faHandshake, faAddressBook, faMoneyBillAlt, faBuilding, faAddressCard, faCalendarAlt, faClock, faDizzy, faSadTear, faWindowMaximize, faEyeSlash, faEye, faFileAlt } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

config.autoAddCss = false

library.add(
  faSignal, faHandshake, faAddressBook,
  faUniversity, faMoneyBillAlt, faCog,
  faBuilding, faReply, faAddressCard,
  faCodeBranch, faEllipsisH, faTimes,
  faExclamation, faChevronDown, faCheck,
  faCaretDown, faCaretUp, faSpinner,
  faCalendarAlt, faSearch, faPaperPlane,
  faArrowLeft, faArrowRight, faEnvelope,
  faPhoneAlt, faFileUpload, faSort,
  faQuestionCircle, faQuestion, faPlus, faYenSign,
  faClock, faDizzy, faSadTear,
  faDownload, faUserCircle, faSyncAlt,
  faWindowMaximize, faCopy, faEyeSlash,
  faEye, faTimesCircle,
  faPlusCircle, faFileAlt, faCaretRight,
)

Vue.component('FaIcon', FontAwesomeIcon)
