
import { Component, Prop, Vue } from 'nuxt-property-decorator'
import CheckIcon from '~/components/element/icon/check-icon.vue'
import ExclamationIcon from '~/components/element/icon/exclamation-icon.vue'

@Component({
  components: {
    CheckIcon,
    ExclamationIcon,
  },
  props: {
    small: {
      type: Boolean,
      default: false,
    },
  },
})
export default class extends Vue {
  @Prop({ required: true, type: String })
  private readonly status!: 'success' | 'warning' | 'progress' | 'neutral'

  get isSuccess (): boolean {
    return this.status === 'success'
  }

  get isWarning (): boolean {
    return this.status === 'warning'
  }

  get isProgress (): boolean {
    return this.status === 'progress'
  }

  get isNeutral (): boolean {
    return this.status === 'neutral'
  }
}
